import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

// Styles  
import classes from "./NavbarHero.module.scss"

// Icons / Png
import { ReactComponent as Logo } from "../../../static/n_icons/Logo.svg"
import { ReactComponent as UserProfileIcon } from "../../../static/n_icons/UserEdit.svg"
import { ReactComponent as LogoutIcon } from "../../../static/n_icons/Go_out.svg"
import { ReactComponent as ErrorDiamondIcon } from "../../../static/n_icons/ErrorDiamond.svg"
import { ReactComponent as DarkThemeIcon } from "../../../static/n_icons/Moon.svg"
import { ReactComponent as BurgerOpenIcon } from "../../../static/n_icons/BurgerOpen.svg"
import { ReactComponent as BurgorCloseIcon } from "../../../static/n_icons/BurgerClose.svg"
// import { ReactComponent as IconCalendar } from "../../static/icons/Calendar-done.svg"

// Material Ui stuff
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
// import muStyles from "../../styles/mu-styles.module.scss"

import { ButtonPrimaryPremiumFill, ButtonPrimaryWhiteOutline, ToggleButton } from "../../../components/Elements/Buttons"

// App Context
import { useUserContext } from '../../../context/UserAuth';
import { useTheme } from '../../../context/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { DropdownContent, WrapperFlexRow } from '../../../components/Elements/Wrappers';


// Public files
import paths from "../../../data/paths.json"
import { setLocalStorageItem } from '../../../helper/local_storage';



// Description:
// This is navbar for hero / page section only!
// We have separate navbar for our inside app (panel has different menu)

function Navbar_Hero() {

    const path_to_avatars = paths.path_to_avatar_images;


    const { t } = useTranslation();


    const { toggleTheme, darkTheme } = useTheme()


    const [toggleBurger, setToggleBurger] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { logoutUser, isLogged, currentUser } = useUserContext();

    const navigate = useNavigate();

    // Change current page with "/route" 
    const handleNavigation = (route) => {
        navigate(route);
    };

    // Toggle menu when in mobile view
    function handleToggleBurger() {
        setToggleBurger(!toggleBurger);
    }


    // Handle dark mode toggle from menu 
    function handleToggleDarkMode() {
        setLocalStorageItem('dark_mode', !darkTheme)
        toggleTheme()
    }

    useEffect(() => {
        // Definicja funkcji sprawdzającej szerokość okna
        // 1024 bierze się z bigTablet mixin
        const handleResize = () => {
            if (window.innerWidth > 1024) {
                setToggleBurger(false);
            }
        };

        // Sprawdzenie początkowej szerokości przy montowaniu komponentu
        handleResize();

        // Dodanie event listenera do zmiany rozmiaru okna
        window.addEventListener('resize', handleResize);

        // Czyszczenie event listenera przy odmontowaniu komponentu
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Pusta lista zależności => uruchamia się tylko raz

    return (

        <div className={`${classes.Navbar}`}>
            <div className={`container is-max-desktop ${classes.NavbarWrapper}`}>

                <div className={classes.NavbarLeft}>
                    <Logo className={classes.NavbarLogo} />

                    {(isLogged && Object.keys(currentUser).length > 0) &&
                        <Link to={"/dashboards"} className={classes.NavbarLink}>
                            {t("HERO_Navbar.dashboards", "Dashboards")}
                        </Link>
                    }

                    <Link to={"/discover-annualty"} className={classes.NavbarLink}>
                        {t("HERO_Navbar.learn_annualty", "Discover Annualty")}
                    </Link>

                </div>


                <div className={classes.NavbarRight}>
                    {(isLogged && Object.keys(currentUser).length > 0)
                        ?
                        <WrapperFlexRow
                            style={{ position: "relative", width: "unset", padding: '0' }}
                            onMouseEnter={() => setIsDropdownOpen(true)}
                            onMouseLeave={() => setIsDropdownOpen(false)}
                            className={`${classes.UserProfileBox} ${isDropdownOpen ? classes.UserProfileBoxActive : ''} `}
                        >

                            <p className='DefaultText text-bold text-capitalize'> {currentUser.username} </p>
                            <img className={"UserImage"} src={path_to_avatars + currentUser.avatar} alt="" />


                            <DropdownContent className={`${isDropdownOpen ? 'show' : ''}`} stickTo='Right'>
                                <Link to={'/user-settings'} className={classes.NavbarMobileLink}>
                                    <UserProfileIcon className={"SVG_STROKE"} />
                                    <p className='DefaultText'> {t("HERO_Navbar.user_profile", "User profile")} </p>
                                </Link>


                                <WrapperFlexRow style={{ padding: '0', height: "unset", justifyContent: "space-between" }}>
                                    <div className={`${classes.NavbarMobileLinkNoHover}`}>
                                        <DarkThemeIcon className={"SVG_STROKE"} />
                                        <p className='DefaultText'> {t("HERO_Navbar.dark_mode", "Dark mode")} </p>
                                    </div>

                                    <ToggleButton onChange={handleToggleDarkMode} isChecked={darkTheme} />
                                </WrapperFlexRow>

                                <hr className='myHr' style={{ margin: "1rem" }} />

                                <Link to={'/report-a-bug'} className={`${classes.NavbarMobileLink}`}>
                                    <ErrorDiamondIcon className={'SVG_STROKE_ERROR'} />
                                    <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.report_a_bug", "Report a bug")}  </p>
                                </Link>

                                <Link onClick={logoutUser} className={`${classes.NavbarMobileLink}`}>
                                    <LogoutIcon className={'SVG_STROKE_ERROR'} />
                                    <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.logout", "Log out")}  </p>
                                </Link>
                            </DropdownContent>

                        </WrapperFlexRow>
                        :
                        <WrapperFlexRow className={'hideOnBigTablet'} style={{ position: "relative", width: "unset", padding: '0' }}>
                            <ButtonPrimaryWhiteOutline onClick={() => { handleNavigation("/login") }} text={t("HERO_Navbar.login", "Log in")} />
                            <ButtonPrimaryPremiumFill onClick={() => { handleNavigation("/signin") }} text={t("HERO_Navbar.sign_in", "Sign in")} />
                        </WrapperFlexRow>
                    }

                    <div className={classes.NavbarBurger} onClick={handleToggleBurger}>
                        {(toggleBurger) ? <BurgorCloseIcon className={'SVG_STROKE'} /> : <BurgerOpenIcon className={'SVG_STROKE'} />}
                    </div>
                </div>

            </div>

            <div className={`${classes.NavbarMobileExpanded} container is-max-desktop`} style={{ display: (toggleBurger ? 'flex' : 'none') }}>

                {(isLogged && Object.keys(currentUser).length > 0) &&
                    <>
                        <Link to={"/dashboards"} className={classes.NavbarMobileLink}>
                            {t("HERO_Navbar.dashboards", "Dashboards")}
                        </Link>
                    </>
                }

                <Link to={"/discover-annualty"} className={classes.NavbarMobileLink}>
                    {t("HERO_Navbar.learn_annualty", "Discover Annualty")}
                </Link>

                {(isLogged && Object.keys(currentUser).length > 0) ?
                    <>

                        <Link to={'/user-settings'} className={classes.NavbarMobileLink}>
                            <UserProfileIcon className={"SVG_STROKE"} />
                            <p> {t("HERO_Navbar.user_profile", "User profile")} </p>
                        </Link>


                        <WrapperFlexRow style={{ padding: '0', height: "unset", justifyContent: "space-between" }}>
                            <div className={`${classes.NavbarMobileLinkNoHover}`}>
                                <DarkThemeIcon className={"SVG_STROKE"} />
                                <p> {t("HERO_Navbar.dark_mode", "Dark mode")} </p>
                            </div>

                            <ToggleButton onChange={handleToggleDarkMode} isChecked={darkTheme} />
                        </WrapperFlexRow>

                        <hr className='myHr' style={{ margin: "1rem" }} />

                        <Link to={'/report-a-bug'} className={`${classes.NavbarMobileLink}`}>
                            <ErrorDiamondIcon className={'SVG_STROKE_ERROR'} />
                            <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.report_a_bug", "Report a bug")}  </p>
                        </Link>

                        <Link onClick={logoutUser} className={`${classes.NavbarMobileLink}`}>
                            <LogoutIcon className={'SVG_STROKE_ERROR'} />
                            <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.logout", "Log out")}  </p>
                        </Link>

                    </>
                    :
                    <WrapperFlexRow style={{ width: "unset", padding: "0.75rem", height: "min-content", gap: "1rem" }}>
                        <ButtonPrimaryWhiteOutline onClick={() => { handleNavigation("/login") }} text={t("HERO_Navbar.login", "Log in")} />
                        <ButtonPrimaryPremiumFill onClick={() => { handleNavigation("/signin") }} text={t("HERO_Navbar.sign_in", "Sign in")} />
                    </WrapperFlexRow>
                }



            </div>
        </div>
    )
}




export default Navbar_Hero