// React
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Styles
import classes from "./Sidebar.module.scss"


// Icons / SVG
import { ReactComponent as Calendar } from "../../../static/n_icons/Calendar.svg"
import { ReactComponent as Notes } from "../../../static/n_icons/Notes.svg"
import { ReactComponent as Clients } from "../../../static/n_icons/Users_v2.svg"
import { ReactComponent as Statistics } from "../../../static/n_icons/PieChart.svg"
import { ReactComponent as Settings } from "../../../static/n_icons/Setting.svg"
// import { ReactComponent as Exit } from "../../../static/n_icons/Go_out.svg"
import { ReactComponent as Home } from "../../../static/n_icons/Home.svg"
import { ReactComponent as Controll } from "../../../static/n_icons/ControlPanel.svg"
import { ReactComponent as PriceList } from "../../../static/n_icons/Book.svg"
import { ReactComponent as Activities } from "../../../static/n_icons/Activity.svg"
// import { ReactComponent as Learn } from "../../../static/n_icons/Learn.svg"
import { ReactComponent as ContactUs } from "../../../static/n_icons/NeedHelp.svg"

// Context
import { useUserContext } from '../../../context/UserAuth'
import { useDashboardContext } from '../../../context/DashboardContext'

// Layouts
import PopupLayout from "../Layout/PopupLayout"

// Public files
import paths from "../../../data/paths.json"

// React components
import { WrapperFlexCol, WrapperFlexRow } from "../../../components/Elements/Wrappers"
import { DangerousWarrningContainer } from '../../../components/Elements/FormMessageBoxes'
import { ButtonDelete, ButtonSave } from '../../../components/Elements/FormButtons'



// Stores path to avatar images in public folder 
// "/user_avatars/"
const path_to_avatars = paths.path_to_avatar_images;


function Sidebar({ showMenu, dashboardHash, closeMenu }) {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { currentUser } = useUserContext();
    const { moveToPathInsideDashboard, dashboardUnsavedChangesRef, updateDashboardUnsavedChanges, currentDashboard } = useDashboardContext()

    // Helper for understaing what page is currently on (component)
    // Holds for example dashboard/Mar-906282ea123164624-55/control-panel?filter=Dashboard_Guests
    const currentPathname = window.location.pathname;

    // This is for showing on and off popup that will tell us that we didnt save changes
    const [showNotSavedPopup, setShowNotSavedPopup] = useState(false);

    // This is needed because if popup will be on, we will lose track on what path user intended to go 
    // We need to store newPath somewhere so when popup will go away (user picks "Discard changes and go")
    // So we can use that variable to remeber that value and navigate user
    const [newPathHold, setNewPathHold] = useState("")


    function handleNavigation(newPath) {

        // Before any navigation withing dashboard, we check if we have any changes that we didnt save!
        if (dashboardUnsavedChangesRef.current === false) {

            // This is needed because we can spam one sidebar menu and continue to refresh page
            // Which is a bit useless right? We want to reload data only when user refreshes page or switched between sidebar menus 
            // Like if he went from calendar to notes its fine, but from notes to notes, no point of rerender 
            if (currentPathname !== newPath) {
                moveToPathInsideDashboard(newPath)
            }

            closeMenu()
        }
        else {
            setShowNotSavedPopup(true)
            setNewPathHold(newPath)

            // When sidebar is expanded we want to close it after user clicks it and still has a warining on 
            if (showMenu) {
                closeMenu()
            }
        }
    }

    function handleDiscardChanges() {
        setShowNotSavedPopup(false);
        updateDashboardUnsavedChanges(false);
        handleNavigation(newPathHold)
    }

    return (

        <>
            {(showNotSavedPopup) && <ChangesNotSavedPopup handleDiscardChanges={handleDiscardChanges} setShowNotSavedPopup={setShowNotSavedPopup} />}


            <div className={classes.Sidebar + " HideScrollbar " + ((showMenu) ? classes.SidebarExpaned : "")}>


                {/* Upper Sidebar  */}
                <div className={classes.SidebarTop}>


                    {/* Home  */}
                    <SidebarMenuItem urlPathName={'home'} MenuText={t("Sidebar.home", "Home")} IconComponent={Home} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Calendar  */}
                    <SidebarMenuItem urlPathName={'calendar'} MenuText={t("Sidebar.calendar", "Calendar")} IconComponent={Calendar} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Clients  */}
                    <SidebarMenuItem urlPathName={'clients'} MenuText={t("Sidebar.clients", "Clients")} additionalUrlPath={'client'} IconComponent={Clients} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Notes  */}
                    <SidebarMenuItem urlPathName={'notes'} MenuText={t("Sidebar.notes", "Notes")} IconComponent={Notes} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />

                    <hr className='myHr' style={{ margin: "0 1rem" }} />

                    {/* Price list  */}
                    <SidebarMenuItem urlPathName={'price-list'} MenuText={t("Sidebar.price_list", "Price list")} IconComponent={PriceList} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Analytics */}
                    <SidebarMenuItem urlPathName={'analytics'} MenuText={t("Sidebar.analytics", "Analytics")} IconComponent={Statistics} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Activities panel */}
                    <SidebarMenuItem urlPathName={'activities'} MenuText={t("Sidebar.activities", "Activities")} IconComponent={Activities} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Controll panel */}
                    <SidebarMenuItem urlPathName={'control-panel'} MenuText={t("Sidebar.users", "Users")} IconComponent={Controll} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                </div>



                {/* Sidebar bottom  */}
                <div className={classes.SidebarBottom}>


                    {/* User on mobile  */}
                    <div className={classes.SidebarActionWrapper + "  " + classes.noHover + " " + classes.ShowOnMobile}>


                        <div className={classes.SidebarActionIconWrapper}>
                            <img className="UserImage" src={path_to_avatars + currentUser.avatar} alt="" />
                        </div>


                        {(showMenu && currentDashboard && currentUser && currentDashboard.dashboard_owner && currentUser.id) &&
                            <p className='DefaultText text-capitalize' style={{ flex: "1", whiteSpace: "nowrap" }}> {currentUser.username} <br />
                                <span className='SmallText text-gray text-capitalize' style={{ display: "inline-block" }}>
                                    {(currentDashboard.dashboard_owner === currentUser.id) ? t("Common.as_owner", "as owner") : t("Common.as_guest", "as guest")}
                                </span>
                            </p>

                        }


                    </div>


                    {/* Learn Annualty  */}
                    {/* <SidebarMenuItem urlPathName={'learn-annualty'} MenuText={t("Sidebar.learn", "Learn about Annualty")} IconComponent={Learn} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} /> */}


                    {/* Contact Us  */}
                    <SidebarMenuItem urlPathName={'contact-us'} MenuText={t("Sidebar.contact", "Contact")} IconComponent={ContactUs} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Settings  */}
                    <SidebarMenuItem urlPathName={'settings'} MenuText={t("Sidebar.settings", "Settings")} IconComponent={Settings} currentPathname={currentPathname} dashboardHash={dashboardHash} handleNavigation={handleNavigation} showMenu={showMenu} />


                    {/* Exit on tablet */}
                    {/* <div className={classes.SidebarActionWrapper + " " + classes.ShowOnTablet} onClick={() => { navigate("/dashboards") }}>

                        <div className={classes.SidebarActionIconWrapper}>
                            <Exit className={classes.IconStroke} />
                        </div>

                        {(showMenu) &&
                            <p className="DefaultText"> {t("Sidebar.exit", "Exit")} </p>
                        }


                    </div> */}


                </div>

            </div>

        </>
    )
}

export function SidebarMenuItem({ currentPathname, dashboardHash, handleNavigation, showMenu, IconComponent, iconProps, MenuText, urlPathName, additionalUrlPath }) {


    // additionalUrlPath means that if we are on main path like /Clients or /Calendar, that we have path that is not included in sidebar 
    // mainly for /clients/<id> 
    // This works great for now, but maybe you will change that in the future 


    function removeLastSegment(url) {

        const parts = url.split('/');
        parts.pop(); // Remove the last element

        return parts.join('/'); // Join the remaining parts
    }


    return (
        <div
            className={classes.SidebarActionWrapper + ` ${(showMenu) ? classes.showMenu : ''} ` + ((currentPathname === `/dashboard/${dashboardHash}/${urlPathName}` || (additionalUrlPath && removeLastSegment(currentPathname) === `/dashboard/${dashboardHash}/${additionalUrlPath}`)) && classes.SidebarActionActive)}
            onClick={() => { handleNavigation(`/dashboard/${dashboardHash}/${urlPathName}`) }}
            data-label={MenuText} // Add this line
        >
            <div className={classes.SidebarActionIconWrapper}>
                {React.createElement(IconComponent, { className: classes.IconStroke, ...iconProps })}
            </div>

            {(showMenu) && <p className="SmallText text_no_wrap text_light_bold"> {MenuText} </p>}
        </div>
    )
}

export function ChangesNotSavedPopup({ setShowNotSavedPopup, handleDiscardChanges }) {

    const { t } = useTranslation();


    function handleClosePopup() {
        setShowNotSavedPopup(false)
    }

    return (
        <PopupLayout popupTitle={t("Sidebar.changes_not_saved", "Wait, changes are unsaved!")} closePopup={handleClosePopup}>


            <WrapperFlexCol style={{ padding: "0" }}>


                <DangerousWarrningContainer message={t("Sidebar.changes_not_saved_description", "You made some changes that haven't been saved!")} />

                {/* Actions like save and cancel  */}
                <WrapperFlexRow style={{ padding: 0, justifyContent: "flex-end" }}>

                    <ButtonSave label={t("Common.cancel", "Cancel")} onClick={handleClosePopup} />

                    <ButtonDelete label={t("Common.discard_changes_and_go", "Discard changes and go")} onClick={handleDiscardChanges} />

                </WrapperFlexRow>


            </WrapperFlexCol>


        </PopupLayout>
    )
}


export default Sidebar

