import React from 'react'
import { ButtonLoadingBars } from './LoadingBoxes';




// Our primary button with fill
export function ButtonPrimaryFill({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className, loading = false, onMouseEnter = null }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled || loading}
            onMouseEnter={onMouseEnter}
            className={`ButtonPrimaryFill ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : ""}`}
        >
            {loading ? <ButtonLoadingBars /> : text}
        </button>
    );
}

export function ButtonPrimaryPremiumFill({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled}
            className={`ButtonPrimaryPremiumFill ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : ""}`}
        >
            {text}
        </button>
    );
}

export function ButtonPrimaryWhiteOutline({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled}
            className={`ButtonPrimaryWhiteOutline ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : ""}`}
        >
            {text}
        </button>
    );
}

export function ButtonPrimaryErrorFill({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {

    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled}
            className={`ButtonPrimaryErrorFill ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : ""}`}
        >
            {text}
        </button>
    );
}

export function ButtonPrimaryOutlined({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled}
            className={`ButtonPrimaryOutlined ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : ""}`}
        >
            {text}
        </button>
    );
}

// Looks ok on default and red on hover 
// Used in clear input firstly
export function ButtonNeutralToDanger({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    let isTextOnly = false;

    const renderContent = () => {
        if (React.isValidElement(text)) {
            return React.cloneElement(text, {
                className: `${text.props.className || ''}`,
            });
        } else if (typeof text === 'string') {
            isTextOnly = true;
            return text;
        } else {
            return null;
        }
    };

    return (
        <button
            disabled={disabled}
            type={type}

            // Add gap if We have Icon + Text 
            style={(!isTextOnly) ? { ...style, gap: "0.25rem" } : { ...style }}
            onClick={onClick}
            className={`ButtonNeutralToDanger ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : "SVG_DEFAULT"}`}
        >
            {renderContent()}
        </button>
    );
}

export function ButtonNeutralToGray({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    const renderContent = () => {
        if (React.isValidElement(text)) {
            return React.cloneElement(text, {
                className: `SVG_FILL ${text.props.className || ''}`,
            });
        } else if (typeof text === 'string') {
            return text;
        } else {
            return null;
        }
    };

    return (
        <button
            disabled={disabled}
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonNeutralToGray ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : "SVG_DEFAULT"}`}
        >
            {renderContent()}
        </button>
    );
}


// Firstly used as "<- Back to clients" button with arrow icon
// It can detect if we pass text or text + icon
export function ButtonNeutral({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className }) {
    const renderContent = () => {
        if (React.isValidElement(text)) {
            return React.cloneElement(text, {
                className: `SVG_FILL ${text.props.className || ''}`,
            });
        }
    };

    return (
        <button
            disabled={disabled}
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonNeutral ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : "SVG_STROKE"}`}
        >
            {renderContent()}
            <span>{typeof text === 'string' ? text : null}</span>
        </button>
    );
}

// Used in calendar timespan 
export function ButtonNeutralToLightPrimary({ disabled = false, label_stroke = false, onClick, text, type = "button", style = {}, className, noMargin = false }) {
    const renderContent = () => {
        if (React.isValidElement(text)) {
            return React.cloneElement(text, {
                className: `SVG_FILL ${text.props.className || ''}`,
                style: noMargin ? { margin: 0 } : {}
            });
        }
    };

    return (
        <button
            disabled={disabled}
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonNeutralToLightPrimary ${(className !== undefined) ? className : ""} ${(label_stroke) ? "SVG_FILL" : "SVG_STROKE"}`}
        >
            {renderContent()}
            <span>{typeof text === 'string' ? text : null}</span>
        </button>
    );
}

// Used firstly in setting for toggling working day
export function ToggleButton({ onChange, isChecked, style = {}, id = {} }) {
    const handleToggle = (event) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <div className={`ToggleButton ${(isChecked) ? 'ToggleChecked' : 'ToggleUnChecked'}`} style={style}>
            <label className="switch">
                <input id={id} type="checkbox" checked={isChecked} onChange={handleToggle} />
                <div></div>
            </label>
        </div>
    );
}

