// React
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

// Styles 
import classes from "./SaveChangesPanel.module.scss";

// Icons 
import { ReactComponent as ArrowDown } from "../../../static/n_icons/ArrowDown.svg"
import { ReactComponent as IconSuccess } from "../../../static/icons/Success.svg"
import { ReactComponent as IconDanger } from "../../../static/icons/Danger.svg"

// Elements
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../../../components/Elements/Buttons';


// This is pretty common component that will show up when we change data somewhere 
// it will display save or reset buttons with error and success messages 

// Update:  Fuck 
// This is way to overpowered but looks nice Xx.. 

function SaveChangesPanel({ anyChangesFound, onSave, onReset, loading, errors = [], isSuccess = false, successMessage = "", setIsSuccess }) {

    const { t } = useTranslation()


    const [hidePanel, setHidePanel] = useState(false)

    async function handleSave() {
        setHidePanel(false)
        onSave()
    }

    return (

        <div className={`${classes.SaveChangesPanel} ${anyChangesFound ? classes.JumpInAnimation : ""}`}>

            <div className={`container is-max-desktop ${classes.SaveChangesPanelWrapper}`}>

                {anyChangesFound && <PanelControlls loading={loading} handleSave={handleSave} onReset={onReset} errors={errors} />}

                {(!hidePanel && isSuccess) && <PanelSuccessContainer setHidePanel={setHidePanel} successMessage={successMessage} />}

                {(!hidePanel && errors && errors.length !== 0) && <PanelErrorContainer setHidePanel={setHidePanel} errors={errors} />}

            </div>

        </div>

    )
}

function PanelControlls({ handleSave, onReset, errors, loading }) {

    const { t } = useTranslation()

    return (
        <div className={classes.PanelControlls}>

            <div className={classes.PanelControllsWrapper}>

                <ButtonNeutralToDanger
                    text={t("Common.button.undoChanges", "Undo changes")}
                    onClick={onReset}
                    disabled={loading}
                />

                <ButtonPrimaryFill
                    text={t("Common.save", "Save")}
                    onClick={handleSave}
                    disabled={(errors && errors.length !== 0) || loading}
                    loading={loading}
                />

            </div>

        </div>
    )
}

function PanelSuccessContainer({ setHidePanel, successMessage }) {

    const [animationFinished, setAnimationFinished] = useState(false)

    const hidePanelRef = useRef(null)

    const { t } = useTranslation()

    useEffect(() => {

        hidePanelRef.current = setTimeout(() => {
            setAnimationFinished(true)

            setHidePanel(true)
        }, 3000)

        return () => {
            clearTimeout(hidePanelRef.current);
            hidePanelRef.current = null;
        }

    }, [])


    return (
        <div className={classes.PanelContainer}>

            <div
                className={classes.PanelWrapper}
            // onMouseEnter={cancelHidePanel}
            >

                {/* Progress bar animation  */}
                <div className={`${classes.PanelHideBarAnimation} ${animationFinished ? classes.PanelAnimationFinished : ""}`}>
                    <div className={classes.PanelHideWrapper}>
                        <div className={classes.ProgressBar}></div>
                    </div>
                </div>

                <div className={classes.PanelContent}>

                    {/* // Success icon  */}
                    <div className={classes.PanelIconHolder}>
                        <IconSuccess className={"SVG_STROKE_SUCCESS"} />
                    </div>


                    {/* Text  */}
                    <div className={classes.PanelTextHolder}>
                        <p className='DefaultText text_success text-bold'> {t("FormMessageBoxes.success", "Success")} </p>
                        <p className='SmallerText text-gray'> {successMessage} </p>
                    </div>


                    {/* Close  */}
                    <div className={classes.PanelClose} onClick={() => setHidePanel(true)}>
                        <p className='SmallText text-gray'> {t("Common.close", "Close")} </p>
                    </div>
                </div>

            </div>

        </div>
    )
}


function PanelErrorContainer({ errors, setHidePanel }) {

    const { t } = useTranslation()

    const [showContent, setShowContent] = useState(true)
    const [animationFinished, setAnimationFinished] = useState(false)

    const hidePanelRef = useRef(null)


    useEffect(() => {

        hidePanelRef.current = setTimeout(() => {
            setShowContent(false)
            setAnimationFinished(true)
        }, 3000)

    }, [])

    //When user hover over our Panel, we want to finish loading and we wont hide it automatically
    function cancelHidePanel() {
        if (hidePanelRef.current) {
            clearTimeout(hidePanelRef.current);
            hidePanelRef.current = null;

            setAnimationFinished(true)
        }
    }


    return (
        <div className={`${classes.PanelContainer} ${classes.PanelError}`}>


            <div className={`${classes.PanelWrapperActions}`} onMouseEnter={cancelHidePanel}>

                <div className={`${classes.PanelWrapper} ${(showContent === false) ? classes.PanelHidden : ""}`}>

                    {/* Progress bar animation  */}
                    <div className={`${classes.PanelHideBarAnimation} ${animationFinished ? classes.PanelAnimationFinished : ""}`}>
                        <div className={classes.PanelHideWrapper}>
                            <div className={classes.ProgressBar}></div>
                        </div>
                    </div>

                    <div className={`${classes.PanelContent}  HideScrollbar`}>

                        {/* // Success icon  */}
                        <div className={classes.PanelIconHolder}>
                            <IconDanger className={"SVG_STROKE_ERROR"} />
                        </div>


                        {/* Text  */}
                        <div className={classes.PanelTextHolder} style={{ gap: "0.5rem" }}>
                            <p className='DefaultText text_error text-bold'> {t("FormMessageBoxes.error", "Something went wrong")}. </p>

                            {(errors) && errors.map((error, idx) => {
                                // Check if ele is an array and has at least one element
                                const capitalizedFirstElement = error.charAt(0).toUpperCase() + error.slice(1);
                                return <p key={`error-${idx}`} className='SmallText text_error'> - {capitalizedFirstElement} </p>;

                            })}
                        </div>


                        {/* Close  */}
                        <div className={classes.PanelClose} onClick={() => setHidePanel(true)}>
                            <p className='SmallText text-gray'> {t("Common.close", "Close")} </p>
                        </div>
                    </div>

                </div>



                <div className={`${classes.PanelToggle} ${(showContent === false) ? classes.PanelToggleHidden : ""}`} onClick={() => setShowContent(!showContent)}>
                    <div className={classes.PanelToggleButton}>
                        <p className='SmallText'> {!showContent ? t("Common.show", "Show") : t("Common.hide", "Hide")}  </p>
                        <ArrowDown className={"SVG_STROKE svg_medium"} style={{ transform: showContent ? "rotate(180deg)" : "" }} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default SaveChangesPanel