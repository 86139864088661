import React from 'react'

// Styles
import classes from "./PopupLayout.module.scss"

// Icons
import { ReactComponent as CloseIcon } from "../../../static/n_icons/Close.svg"

// Description: 
// Popup layout for all popups in dashboard

function PopupLayout({ children, closePopup, popupTitle, isHeroLayout = false, canBeOverflowed = false }) {

    // closePopup: A function to set the state to false, closing the popup.
    //             Invoked when we need to hide the popup.
    //
    // popupTitle: The title displayed in the popup.
    //
    // isHeroLayout: A boolean indicating whether the layout should be optimized for a hero section.
    //               Default value is false, meaning it's a regular popup for a dashboard.
    //               If true, adjusts styles to fit the hero section view instead.
    //
    // canBeOverflowed: Default is false. If true, reduces the z-index to allow potential overflow,
    //                  indicating that there might be actions triggering additional popups.


    const stopPropagation = (e) => {
        e.stopPropagation();
    };




    return (
        <div className={classes.PopupLayout + " " + ((isHeroLayout) ? classes.PopupForHero : "") + " " + ((canBeOverflowed) ? classes.canBeOverflowed : "")}>

            <div className={classes.PopupLayoutContentWrapper + ' ' + classes.fadeInSlideIn}>

                <div className={`${classes.PopupLayoutContent} `} onClick={stopPropagation}>


                    {/* Here we close the popup with the X button */}
                    <div className={classes.ClosePopupContainer}>
                        <h2 className={"DefaultText text-bold"}> {popupTitle} </h2>
                        <p className={classes.CloseIconContainer} onClick={closePopup}> <CloseIcon title={"Close popup"} className={"SVG_STROKE"} /> </p>
                    </div>


                    {/* Hr between title and body  */}
                    {/* <hr className={classes.TitleHr} /> */}


                    <div className={classes.PopupLayoutBody}>
                        {children}
                    </div>


                </div>

            </div>
        </div>
    );
}

export default PopupLayout