

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom';
import LayoutHero from '../LayoutHero/LayoutHero'
import classes from "./UserSettings_Layout.module.scss"
import { DataBox, WrapperFlexCol, WrapperFlexRow, WrapperGrid2 } from '../../../components/Elements/Wrappers'
import UserSettings from './UserSettings'
import { useUserContext } from '../../../context/UserAuth';
// Public files
import paths from "../../../data/paths.json"
import ChangePassword from './SubPages/ChangePassword';
import Invoices from './SubPages/Invoices';
import ChangePlan from './SubPages/ChangePlan';
// import CancelSubscription from './SubPages/CancelSubscription';
// import BillingInformation from './SubPages/BillingInformation';
import PaymentMethods from './SubPages/PaymentMethods';

// Description:
// This is our main layout for all user-settings stuff 


function RenderProperSubPage({ whichPage }) {

    // whichPage hold only latest name of the path for example 
    // for http://localhost:3000/user-settings/change-password
    // whichPage = change-password

    if (whichPage === "/" || whichPage === "user-settings" || whichPage === "") {
        return <UserSettings />
    }

    else if (whichPage === "change-password") {
        return <ChangePassword />
    }

    else if (whichPage === "invoices") {
        return <Invoices />
    }

    else if (whichPage === "payment-methods") {
        return <PaymentMethods />
    }

    else if (whichPage === "change-plan") {
        return <ChangePlan />
    }

    // else if (whichPage === "cancel-subscription") {
    //     return <CancelSubscription />
    // }

}

function UserSettingsLayout({ children }) {


    const { t } = useTranslation()

    const { currentUser, isLogged } = useUserContext()

    // Stores path to avatar images in public folder 
    // "/user_avatars/"
    const path_to_avatars = paths.path_to_avatar_images;

    const location = useLocation();
    const navigate = useNavigate()

    const fullPath = location.pathname;
    const fullPathSplit = fullPath.split('/');
    const whichPage = fullPathSplit[fullPathSplit.length - 1];


    function navigateToSubPage(path) {
        navigate(path)
    }

    // Here we compare whichPage with element so we will know which element is currently active
    function isCurrentPathActive(path) {
        if (path === whichPage) return true
        else return false
    }


    return (
        <LayoutHero>

            <WrapperFlexCol className={`container ${classes.Wrapper}`}>


                <WrapperGrid2 className={"LeftMenuTemplate onBigTablet"}>


                    <DataBox className={"onLayout HideScrollbar"} style={{ height: "min-content", minWidth: "250px" }}>

                        {/* Username + Avatar  */}
                        {(isLogged && Object.keys(currentUser).length > 0) &&
                            <WrapperFlexCol style={{ padding: "0", gap: "0rem" }}>

                                <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                                    <img style={{ maxWidth: "48px" }} src={path_to_avatars + currentUser.avatar} alt="" />

                                    <WrapperFlexCol style={{ padding: "0", gap: "0rem" }}>
                                        <p className='DefaultText text-bold text-capitalize'> {currentUser.username} </p>
                                        <p className='SmallerText text-gray'> {currentUser.email} </p>
                                    </WrapperFlexCol>

                                </WrapperFlexRow>

                                {/* {(currentUser.name && currentUser.lastname) &&
                                    <p className='SmallText text_gray_on_layout text-capitalize text-italic'> {currentUser.name} {currentUser.lastname}</p>
                                } */}
                            </WrapperFlexCol>
                        }


                        <hr className='myHr' />


                        <WrapperFlexCol style={{ padding: "0" }}>
                            <p className='DefaultText text_gray_on_layout text_light_bold'> {t("UserSettings.user_settings", "User Settings")}</p>

                            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", marginTop: "0.5rem" }}>


                                <p onClick={() => navigateToSubPage("/user-settings")} className={`${(isCurrentPathActive('user-settings') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.user_profile", "User profile")}
                                </p>


                                <p onClick={() => navigateToSubPage("/user-settings/change-password")} className={`${(isCurrentPathActive('change-password') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.change_password", "Change password")}
                                </p>


                            </WrapperFlexCol>
                        </WrapperFlexCol>



                        <WrapperFlexCol style={{ padding: "0" }}>
                            <p className='DefaultText text_gray_on_layout text_light_bold' >
                                {t("UserSettings.manage_subscriptions", "Manage Subscriptions")}
                            </p>

                            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", marginTop: "0.25rem" }}>


                                <p onClick={() => navigateToSubPage("/user-settings/invoices")} className={`${(isCurrentPathActive('invoices') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.invoices", "Invoices")}
                                </p>


                                <p onClick={() => navigateToSubPage("/user-settings/payment-methods")} className={`${(isCurrentPathActive('payment-methods') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.payment_methods", "Payment methods")}
                                </p>


                                <p onClick={() => navigateToSubPage("/user-settings/change-plan")} className={`${(isCurrentPathActive('change-plan') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.change_plan", "Change plan")}
                                </p>


                                {/* <p onClick={() => navigateToSubPage("/user-settings/cancel-subscription")} className={`${(isCurrentPathActive('cancel-subscription') ? "text_in_menu_active" : "")} text_clickable_in_menu DefaultText text_light_bold`}>
                                    {t("UserSettings.cancel_subscription", "Cancel subscription")}
                                </p> */}
                            </WrapperFlexCol>

                        </WrapperFlexCol>


                    </DataBox>

                    <DataBox className={"onLayout HideScrollbar"} style={{ height: "min-content" }}>
                        <RenderProperSubPage whichPage={whichPage} />
                    </DataBox>


                </WrapperGrid2>


            </WrapperFlexCol>

        </LayoutHero>
    )
}


export default UserSettingsLayout