// React
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

// Styles 
import classes from "./Pages_styles/Clients.module.scss"

// Apis 
import { API_CreateNewClientByDashboard, API_DeleteClient, API_ToggleFavouriteClient } from "../../../api/API_dashboard_clients";

// Helpers
import { getSearchCategories } from '../../../helper/_clients';
import { phoneFormatter, phoneReformatter } from "../../../helper/funcs";
import { isNameLastnameValid } from "../../../utils/data_validators/client_validator";

// Layouts
import PopupLayout from "../Layout/PopupLayout";

// Public Files

// Context
import { useSharedDashboardContext } from "../../../context/SharedDashboardDataContext";
import { useDashboardContext } from "../../../context/DashboardContext";
import { useUserContext } from "../../../context/UserAuth";

// Components
import { ButtonNeutralToDanger, ButtonNeutralToLightPrimary, ButtonPrimaryFill } from "../../../components/Elements/Buttons";
import { DataBox, DataBoxWrapper, LayoutOption, MySelect, SectionTabsContainer, SingleRowDisplay, SingleRowLeft, SingleRowRight, TabButton, TitleSectionIconWrapper, TwoActionButtonsDangerWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from "../../../components/Elements/Wrappers";
import { CurrencyInput, SearchInput, SingleInput, TextFieldInput } from "../../../components/Elements/FormInputs";
import { HeartToggle } from "../../../components/Elements/FormButtons";
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from "../../../components/Elements/FormMessageBoxes";
import { LabelLeftIconTwoBoldsTexts, LabelLeftSingleIcon } from "../../../components/Elements/FormLabels";

// Svg / Icons
import { ReactComponent as TrashIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as GoIcon } from "../../../static/n_icons/Client_in.svg"
import { ReactComponent as PhoneIcon } from "../../../static/n_icons/Phone.svg"
import { ReactComponent as SadIcon } from "../../../static/n_icons/Neutral.svg"
import { ReactComponent as AddressIcon } from "../../../static/n_icons/Location.svg"
import { ReactComponent as ClientDescriptionIcon } from "../../../static/n_icons/Settings_Description.svg"
import { ReactComponent as MoneyBag } from "../../../static/n_icons/Wallet.svg"
import { ReactComponent as DBErrorIcon } from "../../../static/n_icons/Neutral.svg"
import { ReactComponent as UserDetailIcon } from "../../../static/n_icons/UserDetail.svg"
import { ReactComponent as DeleteIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as WalletIcon } from "../../../static/n_icons/Wallet.svg"
import { ReactComponent as NotesIcon } from "../../../static/n_icons/Settings_Description.svg"
import { ReactComponent as ClearInputIcon } from "../../../static/n_icons/ClearInput.svg"
import { ReactComponent as LayoutTableIcon } from "../../../static/n_icons/Table_View.svg"
import { ReactComponent as LayoutBoxesIcon } from "../../../static/n_icons/Box_View.svg"
// import { ReactComponent as SymbolIcon } from "../../../static/n_icons/Gem.svg"
import { ReactComponent as EmailIcon } from "../../../static/n_icons/Email.svg"



function Clients() {

    const { t } = useTranslation()
    const token = localStorage.getItem("access_token")

    const { dashboardHash, moveToPathInsideDashboard, updateDashboardUnsavedChanges } = useDashboardContext()
    const { sharedClients } = useSharedDashboardContext()

    const oneWeekAgo = new Date(); // This is our one week behind date for Newest users
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const [searchParams, setSearchParams] = useSearchParams()

    // This is helper so if user clicks on both heart icons we will block fav icon firstly clicked, and block other before our fetching is done
    const [favIconLocked, setFavIconLocked] = useState(false);

    const [showNewClientPopup, setShowNewClientPopup] = useState(false)

    const [showDeleteClientPopup, setShowDeleteClientPopup] = useState(false)
    const [clientToBeDeleted, setClientToBeDeleted] = useState(null)

    // We are using two variables for search
    // 'search' will hold Debounced (or Timedout) value of search, so we can search for clients with small delay 
    // it is very usefull when we have +1000 clients, so we can have fluent search expirience

    // 'searchDisplay' will be only used to display this text on the input box 
    const [search, setSearch] = useState("")

    const [filter, setFilter] = useState(searchParams.get("filter") || "All")
    const [filtersCount, setFiltersCount] = useState({
        countAllClients: sharedClients.length,
        countFavouriteClients: sharedClients.filter(client => client.isFavourite).length,
        countNewestClients: sharedClients.filter(client => new Date(client.client_creation_date) > oneWeekAgo).length,
    });

    const categories = getSearchCategories(t);

    const getCategoryLabel = (categoryValue) => {
        const category = categories.find((cat) => cat.value === categoryValue);
        return category ? category.label : "";
    };

    const [category, setCategory] = useState(searchParams.get("category") || categories[0].value)



    // This can hold two values
    // Boxes - Default layout
    // Table - Table layout
    const [currentLayout, setCurrentLayout] = useState(localStorage.getItem("dashboard_layout") || "Boxes")



    useEffect(() => {

        // Update filters count whenever sharedClients change
        setFiltersCount({
            countAllClients: sharedClients.length,
            countFavouriteClients: sharedClients.filter(client => client.isFavourite).length,
            countNewestClients: sharedClients.filter(client => new Date(client.client_creation_date) > oneWeekAgo).length,
        });


        // eslint-disable-next-line
    }, [sharedClients])



    function handleSearch(newSearch) {
        setSearch(newSearch)
    }


    function handleClearSearch() {
        setSearch("")
    }


    function handleCategoryChange(newCategory) {
        setCategory(newCategory)
        setSearchParams({ category: newCategory, filter: filter }, { replace: true })
    }


    function handleFilterChange(newFilter) {
        setFilter(newFilter)
        setSearchParams({ category: category, filter: newFilter }, { replace: true })
    }

    function handleCurrentLayoutChange(newLayout) {
        setCurrentLayout(newLayout)

        localStorage.setItem("dashboard_layout", String(newLayout))
    }


    function filterClientsByFilter() {
        switch (filter) {
            case "All":
                return sharedClients;

            case "Favourites":
                return sharedClients.filter(client => client.isFavourite);

            case "Newest":
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                return sharedClients.filter(client =>
                    new Date(client.client_creation_date) > oneWeekAgo
                );

            default:
                return sharedClients;
        }
    }


    // Client filtering and all 
    function groupClientsByFirstLetterOfName(filteredClients) {

        if (filteredClients) {
            return Object.entries(filteredClients.reduce((acc, client) => {
                const firstLetter = client.name.charAt(0).toUpperCase();

                if (!acc[firstLetter]) {
                    acc[firstLetter] = [client];
                } else {
                    acc[firstLetter].push(client);
                }

                return acc;
            }, {})).map(([letter, clients]) => (
                { letter, clients: clients.sort((a, b) => a.name.localeCompare(b.name)) })
            ).sort((a, b) => a.letter.localeCompare(b.letter));
        }

        return filteredClients

    }


    function searchController(clientsByFilter) {
        // Normalize searchValue to lowercase for case-insensitive matching
        const normalizedSearchValue = (search || "").toLowerCase();

        // console.log(normalizedSearchValue, search)

        switch (category) {
            case "search_by_name_and_lastname":
                return clientsByFilter.filter(client => {
                    const fullName = `${client.name || ""} ${client.lastname || ""}`.toLowerCase();
                    return fullName.includes(normalizedSearchValue);
                });

            case "search_by_phone":
                return clientsByFilter.filter(client =>
                    (client.phone || "").toLowerCase().includes(normalizedSearchValue)
                );

            case "search_by_symbol":
                return clientsByFilter.filter(client =>
                    (client.symbol || "").toLowerCase().includes(normalizedSearchValue)
                );

            default:
                // If the category is not recognized, return all clients
                return clientsByFilter;
        }
    }

    const clientsByFilter = filterClientsByFilter();

    // If we are on favourites, and we have 0 clients, then we move automatically to "All"
    if (clientsByFilter.length === 0 && filter === "Favourites") {
        setFilter("All")
    }

    const clientsBySearch = searchController(clientsByFilter);

    const sortedGroups = groupClientsByFirstLetterOfName(clientsBySearch)

    return (
        <div className={"container is-max-desktop"}>

            {showNewClientPopup && <NewClientPopUp closePopup={() => { setShowNewClientPopup(false) }} />}
            {showDeleteClientPopup && <DeleteClientPopUp updateDashboardUnsavedChanges={updateDashboardUnsavedChanges} client={clientToBeDeleted} closePopup={() => setShowDeleteClientPopup(false)} />}


            {/* Section title */}
            <WrapperFlexRow className={"WrapperTitle"}>

                <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                    <h2 className="SectionTitleText"> {t("Sidebar.clients", "Clients")} </h2>
                    <p className="DefaultText text_gray_on_layout"> {t("Clients.customers_base", "Your customer base")}  </p>

                </WrapperFlexCol>

                <ButtonPrimaryFill onClick={() => setShowNewClientPopup(true)} text={t("Clients.add_client", "New client")} />

            </WrapperFlexRow>



            <DataBox className={`${classes.SearchBarStickyTop} onLayout HideScrollbar asMainInput`} style={{ justifyContent: "flex-end" }}>
                <MySelect
                    className='SelectBox CategorySelect'
                    name={category}
                    value={category}
                    onChange={(e) => { handleCategoryChange(e.target.value); }}
                    options={categories}
                    disabled={false}
                />


                {/* Search Input  */}
                <SearchInput style={{ minWidth: "unset", width: "100%", maxWidth: "unset" }} value={search} onChange={(e) => handleSearch(e.target.value)} type="text" placeholder={t("Clients.search_client.placeholder", 'Search client..')} />


                {/* Clear Input  */}
                <ButtonNeutralToDanger className={"default_input_height "} stroke_fill={false} text={<ClearInputIcon />} onClick={handleClearSearch} />


            </DataBox>




            <WrapperFlexCol style={{ padding: "0" }}>


                {/* Here we have all filters elements (Tabs)  */}
                <SectionTabsContainer
                    handleFilter={handleFilterChange}
                    filter={filter}
                >
                    <TabButton filterType="All" label={`${t("Clients.filter_all", "All")} (${filtersCount.countAllClients})`} />
                    <TabButton filterType="Favourites" label={`${t("Clients.filter_favourites", "Favourites")} (${filtersCount.countFavouriteClients})`} isDisabled={filtersCount.countFavouriteClients === 0} isLocked={filtersCount.countFavouriteClients === 0} />
                    <TabButton filterType="Newest" label={`${t("Clients.filter_newest", "Newest")} (${filtersCount.countNewestClients})`} isDisabled={filtersCount.countNewestClients === 0} isLocked={filtersCount.countNewestClients === 0} />
                </SectionTabsContainer>
            </WrapperFlexCol>




            <WrapperFlexRow style={{ padding: "0", gap: "0", width: "100%", justifyContent: "space-between", marginBottom: "1rem" }}>

                <div style={{ width: "100%" }}>
                    {(search) &&
                        <p className="SmallText text-gray text_light_bold"> {t("Clients.search_found_clients", `Found clients: ${clientsBySearch.length}`, { clientsCount: clientsBySearch.length })}</p>
                    }
                </div>

                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", justifyContent: "flex-end" }}>
                    <p className='SmallText text-gray text_no_wrap text_light_bold'> {t("Clients.change_layout", "Change layout")} </p>
                    <LayoutOption
                        isActive={currentLayout === "Table"}
                        onClick={() => handleCurrentLayoutChange("Table")}
                    >
                        <LayoutTableIcon />
                    </LayoutOption>
                    <LayoutOption
                        isActive={currentLayout === "Boxes"}
                        onClick={() => handleCurrentLayoutChange("Boxes")}
                    >
                        <LayoutBoxesIcon />
                    </LayoutOption>

                </WrapperFlexRow>


            </WrapperFlexRow>



            {(sortedGroups && sortedGroups.length > 0) ?
                sortedGroups.map(({ letter, clients }) => (


                    <WrapperFlexCol key={letter} style={{ gap: "0", padding: "0" }}>
                        {/* Display the letter as a group header */}
                        <h2 className={`SectionTitleText text-bold text-gray`}>{letter}</h2>
                        {/* <hr className={classes.Hr} /> */}

                        <WrapperFlexRow style={{ padding: "0", gap: "1rem", flexWrap: "wrap", marginTop: "1rem", marginBottom: "2rem", alignItems: "flex-start" }}>
                            {clients.map((client, idx) => (
                                <ClientRow setShowDeleteClientPopup={setShowDeleteClientPopup} currentLayout={currentLayout} token={token} t={t} setClientToBeDeleted={setClientToBeDeleted} favIconLocked={favIconLocked} setFavIconLocked={setFavIconLocked} key={idx} client={client} oneWeekAgo={oneWeekAgo} dashboardHash={dashboardHash} moveToPathInsideDashboard={moveToPathInsideDashboard} />
                            ))}
                        </WrapperFlexRow>
                    </WrapperFlexCol>


                ))


                :


                (sortedGroups.length === 0 && (search === "" || !search.trim())) ?


                    <WrapperFlexCol className={'DashboardErrorContainer'} style={{ alignItems: "center", height: "100%" }}>
                        <TitleSectionIconWrapper stroke_fill={false} icon={<DBErrorIcon />} />
                        <p style={{ textAlign: "center" }} className='DefaultText text-gray text-light'>
                            {t("Clients.no_client_found", "Your dashboard doesn't have any clients yet.")}
                        </p>
                        <p style={{ textAlign: "center" }} className='SmallText text-gray text-light'>
                            {t("Clients.try_adding_one", "Start with someone")}
                        </p>
                        <button onClick={() => setShowNewClientPopup(true)} className="ButtonPrimaryFill">
                            {t("Clients.add_client", "New Client")}
                        </button>
                    </WrapperFlexCol>


                    :


                    <WrapperFlexCol className={'DashboardErrorContainer'} style={{ alignItems: "center", height: "100%" }}>
                        <TitleSectionIconWrapper stroke_fill={false} icon={<SadIcon />} />

                        <p style={{ textAlign: "center" }} className='DefaultText text-gray'>
                            {t("Clients.oops_not_found", "Oops, we could not find anyone")}
                            <br /><br />
                            {t("Clients.category_label", "Category")}: <b>{getCategoryLabel(category).toLowerCase()}</b>
                            <br />
                            {t("Clients.search_label", "Search")}: <b>&apos;{search}&apos;</b>
                            <br /><br />
                            <span className='SmallText text-gray'>{t("Clients.try_different_category", "Maybe you could try a different category or check for any typos")}</span>
                        </p>


                        <button onClick={() => {
                            setSearch("")
                            setFilter("All")
                        }} className="ButtonPrimaryFill"> {t("Clients.go_back_to_all_clients", "Go back to clients")} </button>
                    </WrapperFlexCol>


            }

        </div>
    )
}


function ClientRow({ currentLayout, client, oneWeekAgo, t, dashboardHash, moveToPathInsideDashboard, favIconLocked, setFavIconLocked, token, setClientToBeDeleted, setShowDeleteClientPopup }) {

    const creationDate = new Date(client.client_creation_date);
    const isNew = creationDate >= oneWeekAgo;

    const { setSharedClients, dashboardCurrency } = useSharedDashboardContext()


    // Our state about favourite, we toggle that so user can see immidietly that heart icon changed
    // It changes only when we did reqeuest correctly
    const [favState, setFavState] = useState(client.isFavourite)

    // When we click on heart icon we update value in server (but we dont refresh page, we update state with updated singleClient data)
    async function toggleFavouriteClient(client_id, setIsFav) {

        // We dont have much verification here, because it is only toggle,
        // Worst thing is that we wont find client ?
        if (token && client_id && !favIconLocked) {

            setFavIconLocked(true)

            const response = await API_ToggleFavouriteClient(token, client_id)

            // If we successfully toggled fav and also we fetched this client correctly
            if (response && response.isOk) {
                setFavState(!favState)
                setIsFav(!favState)

                // Update sharedClients
                setSharedClients(prevClients => {
                    const updatedClients = prevClients.map(client => {
                        if (client.id === client_id) {
                            return { ...client, isFavourite: !favState };
                        }
                        return client;
                    });
                    return updatedClients;
                });
            }
            else {
                console.log("[ClientBox > toggleFavouriteClient] Couldn't toggle client with id: ", client_id)
                console.log("[ClientBox > toggleFavouriteClient] Server might be off, or client does not exists!")
            }

            setFavIconLocked(false)
        }
    }

    return (
        (currentLayout === "Boxes")
            ?
            <ClientBox t={t} dashboardCurrency={dashboardCurrency} setShowDeleteClientPopup={setShowDeleteClientPopup} dashboardHash={dashboardHash} moveToPathInsideDashboard={moveToPathInsideDashboard} setClientToBeDeleted={setClientToBeDeleted} toggleFavouriteClient={toggleFavouriteClient} client={client} favState={favState} isNew={isNew} favIconLocked={favIconLocked} setFavIconLocked={setFavIconLocked} />
            :
            <ClientTable t={t} dashboardCurrency={dashboardCurrency} setShowDeleteClientPopup={setShowDeleteClientPopup} dashboardHash={dashboardHash} moveToPathInsideDashboard={moveToPathInsideDashboard} setClientToBeDeleted={setClientToBeDeleted} toggleFavouriteClient={toggleFavouriteClient} client={client} favState={favState} isNew={isNew} favIconLocked={favIconLocked} setFavIconLocked={setFavIconLocked} />
    )
}

export function ClientBox({ dashboardCurrency, hideActions = false, t, client, key, isNew, favState, favIconLocked, toggleFavouriteClient, moveToPathInsideDashboard, dashboardHash, setClientToBeDeleted, setShowDeleteClientPopup }) {

    return (


        <DataBoxWrapper key={key} className={`HideScrollbar DataBox_style ${classes.ClientBox} ${hideActions ? classes.FullWidth : ""}`} style={{ minHeight: "unset" }}>

            {/* Client name, lastname, phone, fav and tags  */}
            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between", alignItems: "flex-start", height: "unset" }}>

                {/* Name lastname & phone  */}
                <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", overflowX: "hidden", justifyContent: "center" }}>
                    <p className="DefaultText text-bold text_overflow_hidden"> {client.name} {client.lastname} </p>

                    {(client && client.phone) &&
                        <p className="SmallText text-gray"> {phoneFormatter(client.phone)} </p>
                    }
                </WrapperFlexCol>

                {/* Clients Fav  */}
                <WrapperFlexRow style={{ padding: "0", width: "unset", gap: "0.25rem" }}>

                    {/* Add to favourite and new icon  */}
                    {(hideActions === false) &&
                        <HeartToggle
                            style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
                            initialFavState={favState}
                            favIconLocked={favIconLocked}
                            toggleFavourite={toggleFavouriteClient}
                            clientId={client.id}
                        />
                    }

                </WrapperFlexRow>

            </WrapperFlexRow>


            {/* Symbol  */}
            {(client && client.symbol && client.symbol !== "") &&
                <div className={`${classes.TagsContainer}`}>
                    <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                        {(isNew) ? <p className={classes.Tag}> {t("Common.new", "New")} </p> : null}
                        {(client && client.symbol) ? <p className={`${classes.SymbolTag} ${classes.Tag}`}> {client.symbol} </p> : null}
                    </WrapperFlexRow>
                </div>
            }



            <WrapperFlexRow className={"HideScrollbar"} style={{ maxHeight: "172px", padding: "0", gap: "0", overflowY: "scroll", alignItems: "flex-start" }}>

                <WrapperFlexRow className={"FlexWrapOnTablet"} style={{ padding: "0", alignItems: "flex-start", height: "unset" }}>


                    {/* Client symbol, address and price  */}
                    <WrapperFlexCol style={{ padding: "0", alignItems: "flex-start", height: "unset", minWidth: "max-content" }}>



                        {/* Addres  */}
                        {(client && client.address && client.address !== "") &&
                            <WrapperFlexRow style={{ padding: "0", width: "unset", gap: "0.75rem" }}>
                                <div className={classes.ClientBoxLabelBox}><AddressIcon className={`SVG_STROKE`} /></div>
                                <WrapperFlexCol className={"HideScrollbar"} style={{ padding: "0", flex: "1", gap: "0rem", maxHeight: "56px", overflowY: "scroll" }}>
                                    <p className='SmallText text-gray text_no_wrap'> {t("Clients.address", "Address")}: </p>
                                    <p className='SmallText text-bold'> {client.address} </p>
                                </WrapperFlexCol>
                            </WrapperFlexRow>
                        }


                        {/* Email  */}
                        {(client && client.email && client.email !== "") &&
                            <WrapperFlexRow style={{ padding: "0", width: "unset", gap: "0.75rem" }}>
                                <div className={classes.ClientBoxLabelBox}><EmailIcon className={`SVG_STROKE`} /></div>
                                <WrapperFlexCol className={"HideScrollbar"} style={{ padding: "0", flex: "1", gap: "0rem", maxHeight: "56px", overflowY: "scroll" }}>
                                    <p className='SmallText text-gray text_no_wrap'> {t("Clients.email", "E-mail")}: </p>
                                    <p className='SmallText text-bold as_link' style={{ padding: "0" }}><a href={`mailto:${client.email}`}>{client.email} </a></p>
                                </WrapperFlexCol>
                            </WrapperFlexRow>
                        }


                        {/* LatestVisitPrice  */}
                        {(client && (client.price || client.price !== 0)) &&
                            <WrapperFlexRow style={{ padding: "0", width: "unset", gap: "0.75rem" }}>
                                <div className={classes.ClientBoxLabelBox}><MoneyBag className={`SVG_STROKE`} /></div>
                                <WrapperFlexCol style={{ padding: "0", flex: "1", gap: "0rem" }}>
                                    <p className='SmallText text-gray text_no_wrap'> {t("Clients.latest_visit_price", "Default visit price")}: </p>
                                    <p className='SmallText text-bold'> {client.latestVisitPrice} {(dashboardCurrency.Symbol) || ""} </p>
                                </WrapperFlexCol>
                            </WrapperFlexRow>
                        }

                    </WrapperFlexCol>

                    {/* Client description */}
                    {(client && client.description && client.description !== "") &&
                        <WrapperFlexRow className={classes.DescriptionBox} style={{ padding: "0", gap: "0.75rem", alignItems: "flex-start", height: "unset" }}>

                            <div className={classes.ClientBoxLabelBox}><ClientDescriptionIcon className={`SVG_STROKE`} /></div>
                            <WrapperFlexCol style={{ padding: "0", gap: "0rem", height: "unset", maxWidth: "min-content" }}>
                                <p className='SmallText text-gray text_no_wrap'>{t("Clients.client_description", "Client description")}</p>
                                <p style={{ maxHeight: "2.5rem", overflowY: "scroll" }} className='SmallText text-bold HideScrollbar text_overflow_hidden'> {client.description} </p>
                            </WrapperFlexCol>
                        </WrapperFlexRow>
                    }

                </WrapperFlexRow>

            </WrapperFlexRow>

            {(hideActions === false) &&
                <>
                    <hr className={"myHr"} />

                    {/* Client actions : Edit Delete etc  */}

                    <WrapperFlexRow style={{ padding: 0, height: "unset" }}>

                        {/* Delete client */}
                        <ButtonNeutralToDanger style={{ minWidth: "unset" }} text={<TrashIcon />} onClick={() => {
                            setClientToBeDeleted(client)
                            setShowDeleteClientPopup(true)
                        }}
                        />

                        <WrapperFlexRow style={{ padding: 0, justifyContent: "flex-end" }}>
                            {/* Phone  */}
                            {(client.phone) &&
                                <a href={`tel:${phoneReformatter(client.phone)}`}>
                                    <ButtonNeutralToLightPrimary noMargin={true} label_stroke={false} style={{ minWidth: "unset" }} text={<PhoneIcon />} />
                                </a>
                            }
                            <ButtonNeutralToLightPrimary className={"SVG_STROKE_HOVER"} noMargin={true} style={{ minWidth: "80px" }} onClick={() => { moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${client.id}`) }} text={<GoIcon />} />


                        </WrapperFlexRow>

                    </WrapperFlexRow>
                </>
            }

        </DataBoxWrapper>
    )
}

export function ClientTable({ dashboardCurrency, setShowDeleteClientPopup, dashboardHash, moveToPathInsideDashboard, isNew, setClientToBeDeleted, favState, toggleFavouriteClient, favIconLocked, client }) {

    const { t } = useTranslation();

    return (

        <SingleRowDisplay>

            <SingleRowLeft>

                <HeartToggle
                    initialFavState={favState}
                    favIconLocked={favIconLocked}
                    toggleFavourite={toggleFavouriteClient}
                    clientId={client.id}
                />

                {/* Client name lastname and price  */}
                <WrapperFlexCol style={{ padding: "0", gap: "0.1rem" }}>
                    <p className="DefaultText text-bold text_no_wrap"> {client.name} {client.lastname}</p>
                    {(client.phone) && <p className="SmallText text-gray text_no_wrap"> {phoneFormatter(client.phone)} </p>}

                </WrapperFlexCol>

                {/* Client price  */}
                {(client && (client.latestVisitPrice || client.latestVisitPrice === 0)) &&
                    <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }} className="HideScrollbar">
                        <p className='DefaultText text-bold text_no_wrap'> {client.latestVisitPrice} {(dashboardCurrency.Symbol) || ""} </p>
                    </WrapperFlexCol>
                }

                {/* Client tag  */}
                <div className={`${classes.TagsContainer}`}>
                    <WrapperFlexCol style={{ padding: "0", gap: "0.1rem" }}>
                        {(isNew) ? <p className={classes.Tag}> {t("Common.new", "New")} </p> : null}
                        {(client && client.symbol) ? <p className={`${classes.SymbolTag} ${classes.Tag}`}> {client.symbol} </p> : null}
                    </WrapperFlexCol>
                </div>




            </SingleRowLeft>

            <SingleRowRight>


                <ButtonNeutralToDanger className={"HideOnTablet"} style={{ minWidth: "unset", height: "100%" }} text={<DeleteIcon />} onClick={() => {
                    setClientToBeDeleted(client)
                    setShowDeleteClientPopup(true)
                }} />
                <ButtonNeutralToLightPrimary noMargin={true} className={"SVG_STROKE_HOVER"} style={{ height: "100%" }} text={<GoIcon />} onClick={() => { moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${client.id}`) }} />

            </SingleRowRight>

        </SingleRowDisplay>
    )
}




function NewClientPopUp({ closePopup }) {

    const { currentUser } = useUserContext()
    const { dashboardId } = useDashboardContext()
    const { sharedClients, setSharedClients, setLatestAddedClient } = useSharedDashboardContext()

    const [client, setClient] = useState({
        name: "",
        lastname: "",
        phone: "",
        latestVisitPrice: 0,
        description: "",
        dashboard_id: dashboardId,
        client_owner: currentUser.id
    })

    const { t } = useTranslation();

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);

    // Here we handling adding new client also checking for error
    async function handleSave(e) {

        e.preventDefault();

        let errors = [];
        setIsSuccess(false);


        if (!client.name || !client.lastname) {
            errors.push(t("validationErrors.enter_all_required_fields", "Enter values to all required fields!"))
        }


        const nameLastnameValidation = isNameLastnameValid(client.name, client.lastname, t)

        if (nameLastnameValidation.length > 0) errors = errors.concat(nameLastnameValidation)


        if (errors.length === 0) {
            // Here we are deleting all the path from icon, so we only save the name of it!
            let newClient = { ...client }

            // Here we format phone to XXX-XXX-XXX format
            if (client.phone) {
                newClient.phone = phoneFormatter(newClient.phone)
            }

            if (!client.latestVisitPrice) newClient.latestVisitPrice = 0

            const token = localStorage.getItem('access_token')
            const clientOwner = JSON.parse(localStorage.getItem('current_user_local'))

            newClient.client_owner = parseInt(clientOwner.id)
            if (token) {
                const response = await API_CreateNewClientByDashboard(token, newClient)

                if (response && response.isOk) {
                    // Clearing state and all
                    setErrors([])
                    setIsSuccess(true);

                    setSharedClients([...sharedClients, response.new_item])

                    setLatestAddedClient(response.new_item)

                    setClient({
                        name: "",
                        lastname: "",
                        phone: "",
                        latestVisitPrice: 0,
                        description: "",
                        dashboard_id: dashboardId,
                        client_owner: currentUser.id
                    })

                    // Remove Success box after 2 seconds
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 2000); // 2 sec
                }
                else if (response && response.error_message) {
                    errors.push(response.error_message)

                    let res_json = response.response_json || null

                    if (res_json && res_json.phone[0]) {

                        if (res_json && res_json.phone[0] && res_json.phone[0].includes("(cv_pn01)")) {
                            errors.push(t("validationErrors.invalid_phone_format", "Invalid phone format. Valid phone formats are: 123 123 123, 123-123-123 or 123123123") + " (cv_pn01)")
                        }
                    }
                    if (res_json && res_json.latestVisitPrice) {
                        errors.push(t("validationErrors.invalid_latest_price", "Make sure default visit price is in format correct format."))
                    }
                    if (res_json && res_json.detail && res_json.detail.includes("Limit_Error")) {
                        errors.push(t("validationErrors.limit_error.for_clients", "Another client cannot be added because the limit of clients has been reached. Go to Settings to check your dashboard limits.") + " (Limit_Error)")
                    }
                    if (res_json && res_json.description) {
                        errors.push(t("validationErrors.invalid_description", "Description can only contain numbers, letters and symbols !@#$%&") + " (cv_cd01)")
                    }
                    if (res_json && res_json.email) {
                        errors.push(t("validationErrors.email_invalid_format", "Email has wrong format"))
                    }
                }
            }

        }

        setErrors(errors)

    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("Clients.add_client", "New client")}>

            <WrapperFlexCol as_form={true} style={{ gap: "0.25rem", padding: "0" }} on_submit={handleSave}>

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Clients.succes_new_client_added", "New client added!")} />

                }

                {/* Client name and last name  */}
                <LabelLeftIconTwoBoldsTexts style={{ marginTop: "0" }} LeftIcon={UserDetailIcon} LabelText={t("Common.enter", "Enter client's")} LabelBold1={t("Clients.label.name", "name")} LabelBold2={t("Clients.label.lastname", "last name")} />
                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                    <SingleInput
                        style={{ flex: "1" }}
                        value={client.name}
                        onChange={(e) => setClient({ ...client, name: e.target.value })}
                        label="given-name"
                        placeholder={t("Clients.placeholder.name", "Name")}
                    />
                    <SingleInput
                        style={{ flex: "1" }}
                        value={client.lastname}
                        onChange={(e) => setClient({ ...client, lastname: e.target.value })}
                        label="family-name"
                        placeholder={t("Clients.placeholder.lastname", "Last name")}
                    />
                </WrapperFlexRow>

                {/* Client phone and visit price  */}
                <LabelLeftIconTwoBoldsTexts LeftIcon={WalletIcon} LabelText={t("Common.enter", "Enter client's")} LabelBold1={t("Clients.label.phone", "phone number")} LabelBold2={t("Clients.label.visit_price", "default visit price")} />
                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                    <SingleInput
                        style={{ flex: "1" }}
                        value={client.phone}
                        onChange={(e) => setClient({ ...client, phone: e.target.value })}
                        label="phone"
                        placeholder="123-123-123"
                    />
                    <CurrencyInput
                        value={(client.latestVisitPrice)}
                        onChange={(e) => setClient({ ...client, latestVisitPrice: e.target.value })}
                        label={t("Clients.placeholder.visit_price", "Default visit price")}
                        style={{ flex: "1" }}
                    />
                </WrapperFlexRow>

                {/* Client Description  */}
                <LabelLeftSingleIcon LeftIcon={NotesIcon} LabelText={t("Common.enter", "Enter client's")} LabelBold={t("Clients.label.description", "description")} />
                <TextFieldInput
                    label={t("Clients.client_description", "Description")}
                    rows={4}
                    value={client.description}
                    onChange={(e) => setClient({ ...client, description: e.target.value })}
                />

                {/* Actions like save and cancel  */}
                <TwoActionButtonsWrapper
                    onCancel={closePopup}
                    onSave={handleSave}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    saveLabel={t("Common.add", "Add")}
                />


            </WrapperFlexCol>
        </PopupLayout>
    )
}


function DeleteClientPopUp({ closePopup, client, updateDashboardUnsavedChanges }) {

    const token = localStorage.getItem('access_token')
    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setSharedClients } = useSharedDashboardContext()

    const { t } = useTranslation();

    // If we give confirmation we can actually delete client
    // When this is triggered, that means user wants to delete client
    async function handleConfirmation() {
        let errors = [];
        if (client.id && token) {
            setIsLoading(true)
            const response = await API_DeleteClient(token, client.id)

            if (response && response.isOk) {
                // Client deleted successfuly
                setIsSuccess(true)
                updateDashboardUnsavedChanges(false)

                // Remove the deleted client from sharedClients
                setSharedClients(prevClients => prevClients.filter(c => c.id !== client.id));
            }
            else if (response && response.isOk === false && response.error_message) {
                // Error while deleting client
                errors.push(response.error_message)
            }

            setIsLoading(false)
        }

        if (!isSuccess) {
            setErrors(errors);
        }

    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("Common.delete_client", "Delete client")}>
            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleConfirmation}>

                {(isSuccess === false && errors.length === 0) &&
                    <DangerousWarrningContainer message={t("Clients.irreversible_process_message", "This process is irreversible, all data connected to that client will be deleted!")} />
                }

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Clients.client_deleted_successfully", "Client deleted successfully!")} />
                }

                {(!isSuccess) &&
                    <p className="DefaultText">
                        {t("Clients.confirm_delete_client", "Are you sure you want to delete client:")} <b>{client.name} {client.lastname}</b>
                    </p>
                }

                {/* Actions like save and cancel  */}
                <TwoActionButtonsDangerWrapper
                    onCancel={closePopup}
                    onSave={handleConfirmation}
                    loading={isLoading}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                />


            </WrapperFlexCol>
        </PopupLayout>
    )
}

export default Clients;

















