import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Layouts
import PopupLayout from "../Layout/PopupLayout"
import SaveChangesPanel from '../Layout/SaveChangesPanel'

// Svg / Icons
import { ReactComponent as DashboardNameIcon } from "../../../static/n_icons/Tag.svg"
import { ReactComponent as DeleteIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as DescriptionIcon } from "../../../static/n_icons/Settings_Description.svg"
// import { ReactComponent as WorkIcon } from "../../../static/n_icons/WorkTime.svg"
import { ReactComponent as CurrencyIcon } from "../../../static/n_icons/Currency.svg"
import { ReactComponent as GemIcon } from "../../../static/n_icons/Gem_2.svg"
import { ReactComponent as NewTabIcon } from "../../../static/n_icons/NewTab.svg"

// Api's
import { API_DeleteDashboard, API_GetDashboardLimits, API_UpdateDashboard } from '../../../api/API_dashboards'

// Context
import { useDashboardContext } from '../../../context/DashboardContext'
import { useUserContext } from '../../../context/UserAuth'
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext'

// Elements
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { DataBox, IconBox, MySelect, ProgressBar, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ToggleButton } from '../../../components/Elements/Buttons'
import { LabelLeftSingleIcon } from '../../../components/Elements/FormLabels'
import { SingleInput, TextFieldInput } from '../../../components/Elements/FormInputs'

// Helpers
import { translateDayOfWeek } from '../../../translations/translation_helpers'
import currencies from "../../../data/currency_data.json"
import { createTimeIntervals } from '../../../helper/funcs'
import { isDashboardDescriptionValid, isDashboardNameValid } from '../../../utils/data_validators/dashboard_create_new'
import { daysOfWeek } from '../../../helper/_calendar'
import { currencies_obj } from '../../../helper/_analytics'


function Settings() {

    const token = localStorage.getItem('access_token')

    const { t } = useTranslation();


    const { dashboardHash, updateDashboardUnsavedChanges } = useDashboardContext()
    const { sharedCurrentDashboard, setSharedCurrentDashboard, setDashboardCurrency, setSharedCurrentDashboardLimits, sharedCurrentDashboardLimits } = useSharedDashboardContext()
    const { currentUser } = useUserContext()


    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    // Here we update data about current dashboard and later we update dashboard with it
    const [dashboardToUpdate, setDashboardToUpdate] = useState(
        {
            name: sharedCurrentDashboard.name,
            description: sharedCurrentDashboard.description,
            color: sharedCurrentDashboard.color,
            dashboard_status: sharedCurrentDashboard.dashboard_status,
            currency_code: sharedCurrentDashboard.currency_code
        }
    )

    // Only for translation purposes
    let dashboard_status_translated = sharedCurrentDashboard.dashboard_status
    if (dashboard_status_translated === "Standard") {
        dashboard_status_translated = t("Dashboards.card.standard", "Standard")
    }

    const [anyChangesFound, setAnyChangesFound] = useState(false)

    const [showDeleteDashboardPopup, setShowDeleteDashboardPopup] = useState(false)

    // This will return all daysOfWeek as dictionary like 
    // {
    //    monday_start: 8:00
    //    monday_end: 16:00
    //    ...
    // }

    const initialWorkingHours = daysOfWeek.reduce((acc, day) => {
        const [start, end] = sharedCurrentDashboard[day].split('x');
        return {
            ...acc,
            [`${day}_start`]: start,
            [`${day}_end`]: end,
        };
    }, {});

    const [workingHours, setWorkingHours] = useState(initialWorkingHours);

    // This useEffect will check if there are any changes in update dashboard section 
    useEffect(() => {
        const keys = Object.keys(dashboardToUpdate);

        const differences = keys.filter(key => {
            return sharedCurrentDashboard[key] !== dashboardToUpdate[key];
        });

        if (differences.length > 0 && !anyChangesFound) {
            setAnyChangesFound(true)
            updateDashboardUnsavedChanges(true)
        }
        else if (differences.length === 0 && anyChangesFound) {
            setAnyChangesFound(false);
            updateDashboardUnsavedChanges(false);
        }

        if (errors && errors.length !== 0) {
            setErrors([])
        }

        // eslint-disable-next-line
    }, [dashboardToUpdate])


    // When anychangesfound is false (we clear errors if there were previously)
    useEffect(() => {
        if (errors && errors.length !== 0) {
            setErrors([])
        }
    }, [anyChangesFound])

    useEffect(() => {
        fetchDashboardLimits()
    }, [])


    async function fetchDashboardLimits() {

        if (token && dashboardHash) {
            const response = await API_GetDashboardLimits(token, dashboardHash)

            if (response && response.isOk) {
                if (response.items) {

                    const dashboard_limits = response.items.dashboard_limits || {}
                    setSharedCurrentDashboardLimits({ ...dashboard_limits })
                }
            }
            else {
                // Error occured
                setSharedCurrentDashboardLimits({})
            }
        }
    }


    // Whenever we do some work time hours changes, than we want to update dashboard data so it will sync with Save / Reset changes
    useEffect(() => {
        setDashboardToUpdate({
            ...dashboardToUpdate,
            monday: workingHours.monday_start + "x" + workingHours.monday_end,
            tuesday: workingHours.tuesday_start + "x" + workingHours.tuesday_end,
            wednesday: workingHours.wednesday_start + "x" + workingHours.wednesday_end,
            thursday: workingHours.thursday_start + "x" + workingHours.thursday_end,
            friday: workingHours.friday_start + "x" + workingHours.friday_end,
            saturday: workingHours.saturday_start + "x" + workingHours.saturday_end,
            sunday: workingHours.sunday_start + "x" + workingHours.sunday_end,
        });

        // console.log(workingHours.monday_start + "x" + workingHours.monday_end)


        // eslint-disable-next-line
    }, [workingHours])

    function resetDashboardChanges() {
        setDashboardToUpdate({
            name: sharedCurrentDashboard.name,
            description: sharedCurrentDashboard.description,
            color: sharedCurrentDashboard.color,
            dashboard_status: sharedCurrentDashboard.dashboard_status,
            monday: sharedCurrentDashboard.monday,
            tuesday: sharedCurrentDashboard.tuesday,
            wednesday: sharedCurrentDashboard.wednesday,
            thursday: sharedCurrentDashboard.thursday,
            friday: sharedCurrentDashboard.friday,
            saturday: sharedCurrentDashboard.saturday,
            sunday: sharedCurrentDashboard.sunday,
            currency_code: sharedCurrentDashboard.currency_code
        })

        const updatedWorkingHours = {};
        daysOfWeek.forEach(day => {
            const [start, end] = sharedCurrentDashboard[day].split('x');
            updatedWorkingHours[`${day}_start`] = start;
            updatedWorkingHours[`${day}_end`] = end;
        });

        setWorkingHours(updatedWorkingHours);

        setErrors([])
        setLoading(false);
    }


    async function handleUpdateDashboard() {
        // This will remove dashboard_status from newDashboard (we dont want to pass dashboard_status to update)
        // Seems risky
        const { dashboard_status, ...newDashboard } = dashboardToUpdate;

        setIsSuccess(false);
        setErrors([])

        let errors = []

        const nameValidation = isDashboardNameValid(newDashboard.name.trim(), t)
        const descriptionValidation = isDashboardDescriptionValid(newDashboard.description, t)

        if (nameValidation.length > 0) errors = errors.concat(nameValidation)
        if (descriptionValidation.length > 0) errors = errors.concat(descriptionValidation)

        if (token && anyChangesFound && dashboardHash && errors.length === 0) {
            setLoading(true)
            const response = await API_UpdateDashboard(token, dashboardHash, newDashboard)


            if (response && response.isOk === false && response.error_message) {
                errors.push(response.error_message)
            }
            else if (response && response.isOk) {
                // Here we connect FULL DATA of sharedCurrentDashboard and update it with newDashboard data
                const currentDash = { ...sharedCurrentDashboard, ...newDashboard };
                setIsSuccess(true)


                updateDashboardUnsavedChanges(false);

                // Update dashboard currency
                setDashboardCurrency(() => {
                    // Find proper currency so we can display it in the app
                    if (currentDash && currentDash.currency_code) {
                        const dash_currency = currencies.find(currency => currency.Code === currentDash.currency_code);
                        if (dash_currency) {
                            return dash_currency;
                        }
                    }
                    // Return null or another default value if the currency is not found
                    return null;
                });



                setSharedCurrentDashboard(currentDash);
                setDashboardToUpdate(currentDash)
            }
            else if (response && response.isOk === false && response.other_errors) {
                console.log("\n[@Settings > handleUpdateDashboard] Found some server side errors!")
                console.log(response.other_errors)

                errors.push(...response.other_errors)
            }

            // Here we display error message for every error with working days
            if (response.response && response.response.status === 400) {

                const responseBody = response.response_json;
                for (const day in responseBody) {
                    if (responseBody.hasOwnProperty(day) && Array.isArray(responseBody[day])) {
                        const dayErrors = responseBody[day];
                        dayErrors.forEach((error) => {
                            if (error.includes("(dv_ts01)")) {
                                errors.push(`${translateDayOfWeek(day, t)}: ${t("validationErrors.dashboard_endTime", "End time must be at least one hour greater than start time.")}`);
                            }
                            else {
                                errors.push(`${translateDayOfWeek(day, t)}: ${error}`);
                            }
                        });
                    }
                }
            }

            setLoading(false)
        }
        else {
            console.log("\n[@Settings > handleUpdateDashboard] Something is invalid:\n")
            console.log("token: ", token, "\n")
            console.log("dashboardHash: ", dashboardHash, "\n")
            console.log("newDashboard: ", newDashboard, "\n")
        }

        setLoading(false)
        setErrors(errors)
    }

    function handleCurrencyChange(ele) {
        const newCurrencyCode = ele.target.value

        setDashboardToUpdate({
            ...dashboardToUpdate, currency_code: newCurrencyCode
        })
    }

    function handleCloseDeleteDashboardPopup() {
        setShowDeleteDashboardPopup(false)
    }

    const timeIntervals = createTimeIntervals(0, 23, 30);

    return (

        <>


            {(anyChangesFound || loading || errors.length !== 0 || isSuccess) &&
                <SaveChangesPanel
                    successMessage={t("Settings.update_success_message", "Dashboard updated successfully!")}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    errors={errors}
                    onSave={handleUpdateDashboard}
                    onReset={resetDashboardChanges}
                    anyChangesFound={anyChangesFound}
                    loading={loading} />
            }


            {(showDeleteDashboardPopup) && <DeleteDashboardPopup currentUser={currentUser} token={token} sharedCurrentDashboard={sharedCurrentDashboard} handleCloseDeleteDashboardPopup={handleCloseDeleteDashboardPopup} />}


            <div className={"container is-max-desktop"}>


                {/* Section title */}
                <WrapperFlexRow className={"WrapperTitle FlexWrapOnBigTablet"}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                        <h2 className="SectionTitleText"> {t("Sidebar.settings", "Settings")} </h2>

                    </WrapperFlexCol>

                </WrapperFlexRow>



                {/* Current dashboard data card  */}
                <WrapperFlexRow className={"FlexWrapOnBigTablet"} style={{ padding: "0", alignItems: "flex-start", marginTop: "1rem", gap: "1rem" }}>

                    {/* LEFT COL Dashboard card  */}
                    <WrapperFlexCol style={{ padding: "0", gap: "1rem" }}>



                        {/* Update dashboard  */}
                        <DataBox className={"onLayout HideScrollbar"} style={{ gap: "1rem" }}>

                            <p className={"SmallText text-gray"}> {t("Settings.label_dashboard_settings", "Dashboard settings")} </p>

                            <hr className='myHr' />


                            <WrapperFlexCol style={{ padding: "0" }}>
                                <LabelLeftSingleIcon style={{ marginTop: "0" }} LabelText={t("Settings.label_dashboard_name", "Dashboard name")} />
                                <SingleInput
                                    style={{ width: "100%" }}
                                    value={dashboardToUpdate.name}
                                    onChange={(e) => setDashboardToUpdate({ ...dashboardToUpdate, name: e.target.value })}
                                    label="dashboard-name"
                                    placeholder={t("Dashboards.label.enter_dashboard_name.placeholder", "Dashboard name")}
                                />
                            </WrapperFlexCol>

                            <WrapperFlexCol style={{ padding: "0" }}>
                                <LabelLeftSingleIcon style={{ marginTop: "0" }} LabelText={t("Settings.label_dashboard_description", "Dashboard description")} />
                                <TextFieldInput
                                    label={t("Dashboards.label.enter_dashboard_description.placeholder", "Description")}
                                    rows={4}
                                    value={dashboardToUpdate.description}
                                    onChange={(e) => setDashboardToUpdate({ ...dashboardToUpdate, description: e.target.value })}
                                />
                            </WrapperFlexCol>

                            {/* Currency  */}
                            <WrapperFlexCol style={{ padding: "0" }}>
                                <LabelLeftSingleIcon style={{ marginTop: "0" }} LabelText={t("Settings.label_dashboard_currency", "Currency")} />
                                <MySelect className={"inputHeight"} options={currencies_obj} value={dashboardToUpdate.currency_code} onChange={handleCurrencyChange} />
                            </WrapperFlexCol>


                        </DataBox>


                        {/* Working hours */}
                        <DataBox className={"onLayout HideScrollbar"} style={{ gap: "1rem" }}>


                            {/* <LabelLeftSingleIcon style={{ marginTop: "0" }} LeftIcon={WorkIcon} LabelText={t("Settings.label_dashboard_working_hours", "Update dashboard work time hours")} /> */}
                            <p className={"SmallText text-gray"}> {t("Settings.label_dashboard_working_hours", "Dashboard work time hours")} </p>

                            <hr className='myHr' />

                            {/* Here we display all work time days */}
                            {daysOfWeek.map((day) => (
                                <DaySchedule
                                    key={day}
                                    day={day}
                                    workingHours={workingHours}
                                    setWorkingHours={setWorkingHours}
                                    timeIntervals={timeIntervals} // Make sure timeIntervals are defined
                                    sharedCurrentDashboard={sharedCurrentDashboard} // Pass the sharedCurrentDashboard data
                                />
                            ))}

                        </DataBox>

                    </WrapperFlexCol>

                    {/* RIGHT COL  */}
                    <WrapperFlexCol style={{ padding: "0", gap: "1rem" }}>

                        {/* Dashboard Danger zone  */}
                        <DataBox style={{ minHeight: "unset", gap: "1rem" }} className={"onLayout HideScrollbar"}>

                            <p className={"SmallText text-gray"}> {t("Settings.danger_zone.text", "Danger Zone")} </p>

                            <hr className='myHr' />

                            {/* <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>
                                <p className='DefaultText text-bold'> {t("Settings.transfer_ownership.text", "Transfer ownership to other user")} </p>
                                <ButtonNeutralToDanger text={<><TransferIcon /> {t("Settings.transfer", "Transfer")} </>} />
                            </WrapperFlexRow> */}

                            {/* <hr className='myHr' /> */}

                            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>
                                <p className='DefaultText text-bold'> {t("Common.delete_dashboard", "Delete dashboard")} <br />
                                    <span className='SmallText text-gray'>{t("Settings.no_going_back.text", "Once you delete a dashboard, there is no going back.")} </span>
                                </p>
                                <ButtonNeutralToDanger onClick={() => { setShowDeleteDashboardPopup(true) }} text={<><DeleteIcon /> {t("Common.delete_dashboard", "Delete dashboard")}</>} />
                            </WrapperFlexRow>

                        </DataBox>


                        {/* Current dashboard use */}
                        {(sharedCurrentDashboardLimits && Object.keys(sharedCurrentDashboardLimits).length !== 0) &&
                            <DataBox style={{ minHeight: "unset", gap: "1rem" }} className={"onLayout HideScrollbar"}>

                                <p className={"SmallText text-gray"}> {t("Settings.dashboard_used_resources", "Limits overview")} </p>

                                <hr className='myHr' />

                                {/* Users  */}
                                <WrapperFlexCol style={{ padding: "0" }}>
                                    <p className='SmallerText text_light_bold'>  {t("Dashboards.Limit_names.Users", "Users")} </p>
                                    <ProgressBar
                                        limit_current={sharedCurrentDashboardLimits.users.current}
                                        limit_max={sharedCurrentDashboardLimits.users.max}
                                    />
                                </WrapperFlexCol>


                                {/* Visits  */}
                                <WrapperFlexCol style={{ padding: "0" }}>
                                    <p className='SmallerText text_light_bold'>  {t("Dashboards.Limit_names.Visits", "Visits this month")} </p>
                                    <ProgressBar
                                        limit_current={sharedCurrentDashboardLimits.visits.current}
                                        limit_max={sharedCurrentDashboardLimits.visits.max}
                                    />
                                </WrapperFlexCol>

                                {/* Services  */}
                                <WrapperFlexCol style={{ padding: "0" }}>
                                    <p className='SmallerText text_light_bold'>  {t("Dashboards.Limit_names.Services", "Services")}</p>
                                    <ProgressBar
                                        limit_current={sharedCurrentDashboardLimits.services.current}
                                        limit_max={sharedCurrentDashboardLimits.services.max}
                                    />
                                </WrapperFlexCol>

                                {/* Clients  */}
                                <WrapperFlexCol style={{ padding: "0" }}>
                                    <p className='SmallerText text_light_bold'>  {t("Dashboards.Limit_names.Clients", "Clients")} </p>
                                    <ProgressBar
                                        limit_current={sharedCurrentDashboardLimits.clients.current}
                                        limit_max={sharedCurrentDashboardLimits.clients.max}
                                    />
                                </WrapperFlexCol>


                            </DataBox>

                        }

                        {/* Buy premium  */}
                        <DataBox className={"onLayout HideScrollbar premiumBox"} style={{ alignItems: "center" }}>

                            <WrapperFlexRow style={{ padding: "0", gap: "1rem" }}>
                                <IconBox SVG={<GemIcon className="SVG_STROKE_WHITE svg_big" />} />
                                <p className="DefaultText text-bold text_white"> {t("Home.upgrade_plan", "Upgrade your plan")} </p>
                            </WrapperFlexRow>

                            <IconBox className={"premiumArrow"} navitageToWindow={"/user-settings/change-plan"} SVG={<NewTabIcon className='svg_big' />} />
                        </DataBox>

                    </WrapperFlexCol>

                </WrapperFlexRow>

            </div >
        </>
    )
}


export function DaySchedule({ day, workingHours, setWorkingHours, timeIntervals, sharedCurrentDashboard }) {

    const { t } = useTranslation();

    const isClosed = workingHours[`${day}_start`] === 'close';

    // Because our app works in english as default, we cannot change day name from list,
    // We have to do it here 
    const translatedDay = translateDayOfWeek(day, t);

    const handleToggle = () => {
        if (isClosed) {
            if (sharedCurrentDashboard[day.toLowerCase()] === "closexclose") {
                // If initially set to closexclose, set to open hours
                setWorkingHours({
                    ...workingHours,
                    [`${day}_start`]: '08:00',
                    [`${day}_end`]: '16:00',
                });
            } else {
                // Use the original values from sharedCurrentDashboard
                setWorkingHours({
                    ...workingHours,
                    [`${day}_start`]: sharedCurrentDashboard[day.toLowerCase()].split('x')[0],
                    [`${day}_end`]: sharedCurrentDashboard[day.toLowerCase()].split('x')[1],
                });
            }
        } else {
            setWorkingHours({
                ...workingHours,
                [`${day}_start`]: 'close',
                [`${day}_end`]: 'close',
            });
        }
    };


    return (
        <WrapperFlexRow style={{ padding: '0', alignItems: 'center', gap: "0.75rem", flexWrap: "wrap" }}>
            <WrapperFlexCol style={{ padding: '0', width: '100px', minWidth: "70px", gap: '0', }}>
                <p className='SmallText text-capitalize '>{translatedDay}</p>
                {/* <p className='SmallText text-gray'>
                    {isClosed ? <span> {t("Common.is_closed", "is closed")}</span> : <span> {t("Common.is_open", "is open ")}</span>}
                </p> */}
            </WrapperFlexCol>

            <WrapperFlexRow style={{ padding: '0', flex: "1" }}>
                <MySelect
                    className='SelectBox fixedHeight'
                    name={`${day} start`}
                    value={isClosed ? '00:00' : workingHours[`${day}_start`]}
                    onChange={(e) => {
                        setWorkingHours({
                            ...workingHours,
                            [`${day}_start`]: e.target.value,
                        });
                    }}
                    options={isClosed ? [{ value: "00:00", label: "00:00" }] : timeIntervals}
                    style={{ flex: '1' }}
                    disabled={isClosed}
                />
                <MySelect
                    className='SelectBox fixedHeight'
                    name={`${day} end`}
                    value={isClosed ? '00:00' : workingHours[`${day}_end`]}
                    onChange={(e) => {
                        setWorkingHours({
                            ...workingHours,
                            [`${day}_end`]: e.target.value,
                        });
                    }}
                    options={isClosed ? [{ value: "00:00", label: "00:00" }] : timeIntervals}
                    style={{ flex: '1' }}
                    disabled={isClosed}
                />
                <ToggleButton
                    id={day}
                    onChange={handleToggle}
                    isChecked={!isClosed}
                />
            </WrapperFlexRow>
        </WrapperFlexRow>
    );
}


export function DeleteDashboardPopup({ handleCloseDeleteDashboardPopup, token, currentUser, sharedCurrentDashboard }) {


    const { t } = useTranslation();

    const deletionConfirmText = t("Common.delete", "Delete") + " " + sharedCurrentDashboard.name
    const [confirmationText, setConfirmationText] = useState("")

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    async function handleConfirmDeletion() {

        let errors = [];
        if (token && currentUser.id === sharedCurrentDashboard.dashboard_owner) {
            const response = await API_DeleteDashboard(token, sharedCurrentDashboard.hash_id)

            if (response && response.isOk === false && response.error_message) {
                errors.push(response.error_message)
                setIsSuccess(false)
            }
            else if (response && response.isOk === true) {
                setIsSuccess(true)
                setConfirmationText("")

                // Redirect user to /dashboards after 3 seconds 
                setTimeout(() => {
                    window.location.href = '/dashboards';
                }, 3000); // 3000 milliseconds = 3 seconds
            }
        }

        setErrors(errors)

    }

    return (
        <PopupLayout closePopup={handleCloseDeleteDashboardPopup} popupTitle={t("Common.delete_dashboard", "Delete dashboard")}>
            <WrapperFlexCol style={{ padding: "0" }}>

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Settings.deletion.success", "Dashboard deleted. You will be redirected to your dashboards in 3 seconds.")} />
                }

                <DangerousWarrningContainer message={t("Settings.deletion.warning", 'This action will permanently delete your dashboard along with all customer and appointment data. Additionally, if the dashboard has a purchased subscription, it will be immediately canceled. This operation cannot be undone.')} />

                <p className={"DefaultText"} style={{ lineHeight: "1.5" }}>
                    {t("Settings.deletion.deletion_text", "In order to delete dashboard, please enter")} <span className={"SmallText text-highlighted-warrning text-bold"}>{deletionConfirmText}</span> {t("Common.below", "below")}:
                </p>

                <SingleInput
                    style={{ flex: "1" }}
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    label="Enter Highlighted text"
                />

                <TwoActionButtonsWrapper
                    onCancel={handleCloseDeleteDashboardPopup}
                    onSave={handleConfirmDeletion}
                    cancelLabel={t("Common.go_back", "Go back")}
                    saveLabel={t("Common.button.confirm_deletion", "Confirm Deletion")}
                    isSaveDisabled={!(confirmationText === deletionConfirmText)}
                    as_form={false}
                    saveButtonAsDanger={true}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )
}


export default Settings