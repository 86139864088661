import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../../../context/UserAuth'
import { API_CancelSubscription, API_CreateCheckoutSession, API_GetDashboardByOwnerWithSubscriptions, API_GetSpecifiedDashboardSubscriptionDetail, API_GetSubscriptionsLimits } from '../../../../api/API_Stripe'
import PopupLayout from '../../../Dashboard/Layout/PopupLayout'
import { LoadingAnimationFourDots, SelectBetweenButtons, TwoActionButtonsDangerWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../../components/Elements/Wrappers'
import { useTranslation } from 'react-i18next'
import { ButtonNeutralToDanger, ButtonNeutralToLightPrimary, ButtonPrimaryFill, ButtonPrimaryPremiumFill } from '../../../../components/Elements/Buttons'
import { translateBillingPeriods, translateDashboardStatus, translateSubscriptionPlan } from '../../../../translations/translation_helpers'
import { getDateFromTimeStamp } from '../../../../helper/dateFunctions'
import classes from "./shared.module.scss"
import { ReactComponent as ClockIcon } from "../../../../static/n_icons/Clock.svg"
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from '../../../../components/Elements/FormMessageBoxes'
import { SingleInput } from '../../../../components/Elements/FormInputs'
import { useNavigate } from 'react-router-dom'
import { useDashboardContext } from '../../../../context/DashboardContext'
import { API_GetDashboardLimits } from '../../../../api/API_dashboards'

// LIVE_MODE has to match in stripe.py (For now)
const LIVE_MODE = true;

const BASIC_MONTHLY = (LIVE_MODE) ? "price_1PUQJ8P4gji86bUfBV8GQ0XS" : "price_1PNdAqP4gji86bUfaEKM1zOd"
const PRO_MONTHLY = (LIVE_MODE) ? "price_1PUQJ6P4gji86bUfD5fgp5Ed" : "price_1PNdB8P4gji86bUfpLBRAy13"

const BASIC_YEARLY = (LIVE_MODE) ? "price_1PUQJ5P4gji86bUftUrVqjxL" : "price_1PNdBNP4gji86bUfbJWLRtD5"
const PRO_YEARLY = (LIVE_MODE) ? "price_1PUQJ3P4gji86bUfNT2qqeR7" : "price_1PNdBdP4gji86bUfzlYkpNoi"

const AVALIABLE_PLANS_DETAILS = {
    "Monthly": {
        // "Free": {
        //     price: 0.0,
        //     stripe_product: -1
        // },
        "Basic": {
            price: 6,
            stripe_product: BASIC_MONTHLY
        },
        "Pro": {
            price: 10,
            stripe_product: PRO_MONTHLY
        }
    },

    "Yearly": {
        // "Free": {
        //     price: 0.0,
        //     stripe_product: -1
        // },
        "Basic": {
            price: 68,
            stripe_product: BASIC_YEARLY
        },
        "Pro": {
            price: 80,
            stripe_product: PRO_YEARLY
        }
    }
}

function ChangePlan() {

    const token = localStorage.getItem('access_token')

    const { t } = useTranslation()
    const { currentUser } = useUserContext()

    const [dashboards, setDashboards] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [showUpdatePlanPopup, setShowUpdatePlanPopup] = useState(false)
    const [showCancelSubscriptionPopup, setShowCancelSubscriptionPopup] = useState(false)
    const [selectedDashboardToUpdate, setSelectedDashboardToUpdate] = useState(null)

    // Trigger this to refresh subscriptions (after cancelation or payment)
    const [refreshSubscriptions, setRefreshSubscriptions] = useState(false)



    useEffect(() => {

        if (currentUser && currentUser.id) {
            setIsLoading(true)
            loadDashboardsByOwner()
        }

    }, [currentUser.id, refreshSubscriptions])

    async function loadDashboardsByOwner() {
        if (token && currentUser.username && currentUser.id) {
            const response = await API_GetDashboardByOwnerWithSubscriptions(token);

            if (response && response.isOk && response.items) {
                setDashboards(response.items)
                // console.log(response.items)
            }
            else {
                console.log("There was an error while loading dashboards!")
                setDashboards([])
            }

            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }
    }

    function handleDashboardToUpdate(dashboard_obj) {
        setSelectedDashboardToUpdate(dashboard_obj)
        setShowUpdatePlanPopup(true)
    }

    function handleDashboardCancelSubscription(dashboard_obj) {
        setSelectedDashboardToUpdate(dashboard_obj)
        setShowCancelSubscriptionPopup(true)
    }

    function handlePopupClose(which_popup_close) {
        setSelectedDashboardToUpdate(null);
        which_popup_close();
    }


    async function updatePlan(dashboard_id, currentUser_id, currentUser_email, stripe_product_id) {


        if (token && currentUser && currentUser.username) {

            const response = await API_CreateCheckoutSession(token, stripe_product_id, dashboard_id, currentUser_id, currentUser_email);


            // Here we get proper stripe checkout url 
            // URL for checkout is in response.checkout_url.sessionUrl
            if (response.isOk) {

                let checkout_url = response.checkout_url.sessionUrl || undefined

                // If user somehow selected the same plan as he currently have
                if (checkout_url === '-1') {
                    console.log("Wrong plan")
                }


                else if (checkout_url) {
                    console.log("Got checkout url!")
                    console.log(checkout_url)

                    window.location.href = checkout_url;
                }


            }
            else {
                // Something went wrong 
                console.log("\n[ChangePlan > changePlan_TEST] Something went wrong..")
                console.log("Response: ", response)
            }



        }


    }


    async function cancelPlan(dashboard_id, setSuccess, setError) {
        if (token && currentUser && currentUser.username) {

            const response = await API_CancelSubscription(token, dashboard_id);


            // Here we get proper stripe checkout url 
            // URL for checkout is in response.checkout_url.sessionUrl
            if (response.isOk) {


                // console.log("YAY", response)

                setSuccess(true)

                setTimeout(() => {
                    setShowCancelSubscriptionPopup(false)
                    setRefreshSubscriptions(!refreshSubscriptions)
                }, 3000)

            }
            else {
                // Something went wrong 
                setError([response.response_json.error])

                console.log("\n[ChangePlan > cancelPlan] Something went wrong..")
                console.log("Response: ", response)
            }



        }
    }


    return (
        <React.Fragment>
            <div>

                {/* Update plan popup */}
                {showUpdatePlanPopup && (
                    <UpdatePlanPopup
                        dashboard={selectedDashboardToUpdate}
                        onClose={() => handlePopupClose(() => setShowUpdatePlanPopup(false))}
                        updatePlan={updatePlan}
                    />
                )}


                {/* Cancel Plan popup  */}
                {showCancelSubscriptionPopup && (
                    <CancelPlanPopup
                        dashboard={selectedDashboardToUpdate}
                        onClose={() => handlePopupClose(() => setShowCancelSubscriptionPopup(false))}
                        cancelPlan={cancelPlan}
                    />
                )}


                <WrapperFlexRow className={"WrapperTitle"} style={{ flexWrap: "wrap", gap: "1rem" }}>
                    <h2 className='SectionTitleText'> {t("UserSettings.change_plan", "Change plan")} </h2>
                </WrapperFlexRow>

                <p className='SmallText text_left_error_border' style={{ marginBottom: "2rem" }}>
                    {t("UserSettings.change_plan_text", "The subscription plan will be changed immediately, and you will be billed right away. However, you will be credited for unused time on your subscription.")}
                    <br /><br />
                    <span className='text-bold'>{t("UserSettings.note", "Note")}:</span> {t("UserSettings.change_plan_text_note", "the charge may fail with certain cards, in which case not to worry — we'll notify you and you'll be able to pay the invoice manually.")}
                </p>


                {(isLoading) ?

                    <WrapperFlexCol style={{ alignItems: "center" }}>
                        <LoadingAnimationFourDots />
                    </WrapperFlexCol>

                    :

                    (dashboards.length) ?
                        <div className={classes.TableWrapper}>



                            <table className={classes.Table}>

                                <thead>
                                    <tr>
                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.table_name", "Dashboard name")} </th>
                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.plan", "Plan")} </th>
                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.period", "Period")} </th>
                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.status", "Status")} </th>

                                        {/* Leave this as empty for addtional info column for subscription end period  */}
                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.active_untill", "Active untill")} </th>

                                        <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.action", "Action")}</th>

                                        {/* Leave empty for correct display  */}
                                        <th className='DefaultText text-bold'>  </th>


                                    </tr>
                                </thead>


                                <tbody>

                                    {(Object.keys(dashboards) && Object.keys(dashboards).length > 0) &&
                                        dashboards.map((dashboard, idx) => {

                                            const plan = dashboard.subscription_product_our_name || dashboard.dashboard_status
                                            const translated_plan = translateSubscriptionPlan(plan, t)
                                            const translated_plan_split = translated_plan.split("/")

                                            return (
                                                <tr key={`dashboard-${idx}`}>

                                                    {/* dashboard name  */}
                                                    <td className='DefaultText text-bold'>
                                                        <WrapperFlexCol style={{ padding: "0", gap: "0" }}>
                                                            {dashboard.dashboard_name}
                                                            <span className='SmallerText text-gray'>{dashboard.customer} </span>
                                                        </WrapperFlexCol>

                                                    </td>

                                                    {/* subscription name OR dashboard status (if free / no subscription) or '-'  */}

                                                    {translated_plan_split.length === 2 ?
                                                        <td className='SmallText'>

                                                            <p> {translated_plan_split[0]} </p>
                                                            <p className='SmallerText text-gray'> {translated_plan_split[1]} </p>

                                                        </td>
                                                        :
                                                        <td className='SmallText'>

                                                            <p> {translated_plan || " "} </p>

                                                        </td>
                                                    }

                                                    {/* subscription period or '-' */}
                                                    <td className='SmallText'> {(dashboard.subscription_product_our_name) ? getDateFromTimeStamp(dashboard.sub_period_start) + '-' + getDateFromTimeStamp(dashboard.sub_period_end) : "-"} </td>

                                                    {/* Status either Active | Not active OR '-' */}
                                                    <td className='SmallText'>
                                                        <WrapperFlexCol style={{ padding: "0", gap: "0" }}>
                                                            <span>{(dashboard.subscription) ? ((dashboard.is_plan_active === 'active') ? t("UserSettings.change_plan_section.active", 'Active') : t("UserSettings.change_plan_section.not_active", 'Not active')) : t("UserSettings.change_plan_section.not_active", 'Not active')} </span>
                                                            <span className='SmallerText text-gray'> {t("UserSettings.change_plan_section.auto_payment", "Auto payment")} {(dashboard.subscription && dashboard.cancel_at_period_end === false) ? t("UserSettings.change_plan_section.on", "On") : t("UserSettings.change_plan_section.off", "Off")} </span>
                                                        </WrapperFlexCol>
                                                    </td>

                                                    {/* Subscription ending date (if dashboard.cancel_at_period_end == true) */}
                                                    {(dashboard.cancel_at_period_end) ?
                                                        <td className='SmallText'> <WrapperFlexRow style={{ justifyContent: "center" }} className='text_gray_bg'> {getDateFromTimeStamp(dashboard.cancel_at)} <ClockIcon className={"SVG_STROKE svg_medium"} /> </WrapperFlexRow> </td>
                                                        :
                                                        <td className='SmallText'> - </td>
                                                    }

                                                    <td style={{ padding: '0' }} className='DefaultText'> <ButtonPrimaryPremiumFill className={"small"} text={t("UserSettings.change_plan_section.change_plan", "Change plan")} onClick={() => { handleDashboardToUpdate(dashboard) }} /> </td>
                                                    <td style={{ padding: '0.25rem' }} className='DefaultText'> <ButtonNeutralToDanger className={"small"} text={t("Common.cancel", "Cancel")} disabled={!dashboard.subscription || dashboard.cancel_at_period_end} onClick={() => { handleDashboardCancelSubscription(dashboard) }} /> </td>

                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>

                        </div>
                        :
                        <p className='SmallText text_error'> {t("UserSettings.change_plan_no_dashboards", "No dashboards found to display. Please create your first board before refining your plan..")} </p>

                }



                <p className='SmallText' style={{ marginTop: "1rem" }}>
                    {t("UserSettings.contact", "If you need help or have any questions, please contact us at")} <a className='as_link linkPrimary' href="mailto:support@annualty.com">support@annualty.com</a>
                </p>


            </div >


            {/* <MeetOurSubscriptions /> */}

        </React.Fragment>
    )
}





export function MeetOurSubscriptions({ }) {

    const token = localStorage.getItem('access_token')

    const { t } = useTranslation()
    const { currentUser } = useUserContext()

    // Dashboard limits
    const [limits, setLimits] = useState(null)

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        fetchSubscriptionsLimits()

    }, [])


    async function fetchSubscriptionsLimits() {

        if (token) {
            const response = await API_GetSubscriptionsLimits(token)

            if (response && response.isOk) {
                if (response.items) {

                    const dashboard_limits = response.items
                    setLimits({ ...dashboard_limits })
                }
            }
            else {
                // Error occured
                setLimits({})
            }

            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }


    return (
        <p> Hello </p>
    )
}




export function UpdatePlanPopup({ dashboard, onClose, updatePlan }) {

    let isAlreadySubscribing = dashboard.is_plan_active === 'active'
    // isAlreadySubscribing -> We want to display error message that says
    // That changing subscription to different one will delete all current invoices and will overwritte everything.

    const { currentUser } = useUserContext()
    const { t } = useTranslation()
    const token = localStorage.getItem("access_token")

    const [isLoading, setIsLoading] = useState(true)

    // It can be either 'Monthly' or 'Yearly' (Same as keys in AVALIABLE_PLANS_DETAILS)
    // This is for changing from Month to Year 
    const [billingPeriod, setBillingPeriod] = useState('Monthly')

    // All data about selected dashboard subscription by default (Meaning this is selected dashboard current subscription not NEW )
    // We also assign it as -1 if subscription was not found meaning dashboard has no subscription 
    const [selectedDashboardSubscription, setSelectedDashboardSubscription] = useState(null)


    // Holds dashboard plan like 'Free', 'Basic' or 'Pro'
    const currentPlan = dashboard.dashboard_status

    // This will hold either 'Monthly' or 'Yearly', it is needed so we can select proper box 
    // So we can what is current subscription plan selected between Monthly and Yearly 
    const [currentPlanInterval, setCurrentPlanInterval] = useState(null)


    // This is what acctually selected user, so this will hold details about what subscription user want 
    // This will hold two element array ['interval', 'subscription_type'] for example ['Monthly', 'Pro']
    const [userSelectedSubscription, setUserSelectedSubscription] = useState([null, null])


    useEffect(() => {
        loadSelectedDashboardPlan()
    }, [])

    async function loadSelectedDashboardPlan() {


        if (token && currentUser.username && currentUser.id) {
            const response = await API_GetSpecifiedDashboardSubscriptionDetail(token, currentUser.id, dashboard.id);

            if (response && response.isOk && response.items) {

                let dashboardDetail = response.items.subscription_details

                setSelectedDashboardSubscription(dashboardDetail)

                if (dashboardDetail.sub_plan_interval === 'month') {
                    setCurrentPlanInterval("Monthly")
                }
                else if (dashboardDetail.sub_plan_interval === 'year') {
                    setCurrentPlanInterval("Yearly")
                }

                setIsLoading(false)
            }
            else {
                console.log("There was an error while loading dashboard subscription detail! (Subscription was not found so its free dashboard)")
                setSelectedDashboardSubscription(-1)
                setIsLoading(false)
            }
        }
        // When user is not logged in and server is off
        else {
            setIsLoading(false)
        }
    }

    function handleBillingPeriodChange(newPeriod) {
        setBillingPeriod(newPeriod)
        setUserSelectedSubscription([null, null])
    }

    // When users clicks on any plan 
    function handleUserSelectedSubscription(selectedPlan, selectedPeriod) {

        // This makes sure that user wont select the same subscription from UI 
        if ((currentPlan !== selectedPlan || billingPeriod !== currentPlanInterval)) {
            setUserSelectedSubscription([selectedPlan, selectedPeriod])
        }

    }

    function handleCheckoutSession() {
        if (userSelectedSubscription && userSelectedSubscription[1] !== null && userSelectedSubscription[2] !== null) {
            const selectedPlan = userSelectedSubscription[0]
            const selectedPeriod = userSelectedSubscription[1]

            const stripePriceId = AVALIABLE_PLANS_DETAILS[selectedPeriod][selectedPlan].stripe_product

            if (stripePriceId) {
                updatePlan(dashboard.id, currentUser.id, currentUser.email, stripePriceId)
            }


        }

    }

    console.log("billingPeriod", billingPeriod)
    console.log("selectedDashboardSubscription", (selectedDashboardSubscription) && selectedDashboardSubscription.sub_plan_interval)
    console.log("currentPlan", currentPlan)
    console.log("currentPlanInterval", currentPlanInterval)

    return (
        <PopupLayout closePopup={onClose} popupTitle={t("UserSettings.change_plan", "Change plan")} isHeroLayout={true}>


            {isAlreadySubscribing &&
                <DangerousWarrningContainer
                    message={t('UserSettings.change_plan_section.already_has_subscription_message', 'Changing your subscription will result in the loss of sales documents (in the invoices tab). They will be overwritten with new documents for the new subscription. Before you change your plan, make sure you have downloaded the previous documents. The current plan will be stopped and a new plan will come into effect immediately.')}
                />
            }

            {/* Title  */}
            <WrapperFlexCol style={{ alignItems: "center", padding: "0", gap: "0", marginTop: "2rem", marginBottom: "0" }}>
                <p className='DefaultText'> {t("UserSettings.select_new_plan_for", "Select new plan for")}: </p>
                <h2 className='DefaultText text-bold'> {dashboard.dashboard_name} </h2>
            </WrapperFlexCol>



            {/* Month / Year switch  */}
            <WrapperFlexRow style={{ justifyContent: "center", marginTop: "1rem" }}>
                <SelectBetweenButtons translationFunction={translateBillingPeriods} currentValue={billingPeriod}>
                    <ButtonNeutralToLightPrimary onClick={() => handleBillingPeriodChange('Monthly')} text={('Monthly')} />
                    <ButtonNeutralToLightPrimary onClick={() => handleBillingPeriodChange('Yearly')} text={('Yearly')} />
                </SelectBetweenButtons>
            </WrapperFlexRow>



            {/* Select New plan  */}

            {(isLoading) ?

                <WrapperFlexCol style={{ alignItems: "center" }}>
                    <LoadingAnimationFourDots />
                </WrapperFlexCol>

                :

                <WrapperFlexRow style={{ padding: "0" }}>

                    {Object.entries(AVALIABLE_PLANS_DETAILS[billingPeriod]).map(([plan, details]) => {


                        // CurrentPLan meaning that this dashboard has this plan already 
                        let isCurrentPlan = ((currentPlan === plan && currentPlanInterval === billingPeriod) || (selectedDashboardSubscription === -1 && currentPlan === plan))

                        // Currently selected plan
                        let currentlySelectedPlan = (plan === userSelectedSubscription[0] && billingPeriod === userSelectedSubscription[1])


                        let translated_plan = translateDashboardStatus(plan, t);
                        let translated_billing_period = translateBillingPeriods(billingPeriod, t);

                        return (

                            <WrapperFlexCol
                                onClick={() => { handleUserSelectedSubscription(plan, billingPeriod) }}
                                key={`plan-${plan}-${billingPeriod}`}
                                style={{ position: "relative" }}
                                className={`isHoverable layoutBg
                            ${(isCurrentPlan) ? 'isActive' : null} 
                            ${(currentlySelectedPlan) ? 'isActiveBg' : null}`}
                            >
                                {isCurrentPlan && <WrapperFlexRow className={classes.CurrentPlan}> <p className='SmallText text_black text_light_bold'> Obecny plan </p> </WrapperFlexRow>}


                                <p className='DefaultText text-bold' style={{ textAlign: "center" }}>
                                    {translated_plan}
                                </p>
                                <p className='DefaultText text-bold' style={{ textAlign: "center" }}>
                                    {/* We want to have billing in dollars for now  */}
                                    {details.price.toFixed(2)} $  <span className='MicroText'> / {translated_billing_period}</span>
                                </p>

                            </WrapperFlexCol>
                        )
                    })}

                </WrapperFlexRow>

            }




            {/* Summary  */}
            <React.Fragment>

                <WrapperFlexRow style={{ justifyContent: "center", marginTop: "2rem" }}>
                    <ButtonPrimaryPremiumFill
                        disabled={!(userSelectedSubscription && userSelectedSubscription[1] !== null && userSelectedSubscription[2] !== null)}
                        text={t("UserSettings.move_to_payment", 'Proceed to Payment')}
                        onClick={handleCheckoutSession} />
                </WrapperFlexRow>

            </React.Fragment>


        </PopupLayout>
    )

}







export function CancelPlanPopup({ dashboard, onClose, cancelPlan }) {

    const { t } = useTranslation()

    const deletionConfirmText = t("Common.cancel", "Cancel") + " " + dashboard.dashboard_name
    const [confirmationText, setConfirmationText] = useState("")

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)


    return (
        <PopupLayout closePopup={onClose} popupTitle={t("UserSettings.cancel_plan", "Cancel plan")} isHeroLayout={true}>

            <WrapperFlexCol style={{ padding: "0" }}>

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("UserSettings.subscription_canceled", "Subscription cancelled successfully") + "."} />
                }

                <DangerousWarrningContainer message={t("UserSettings.subscription_cancel_warning", 'This action will stop automatic payments and cancel the subscription. After the current subscription period ends, the dashboard will be switched to the free plan.')} />

                <p className={"DefaultText"} style={{ lineHeight: "1.5" }}>
                    {t("UserSettings.cancel_subscription_text", "In order to cancel subscription, please enter")} <span className={"SmallText text-highlighted-warrning text-bold"}>{deletionConfirmText}</span> {t("Common.below", "below")}:
                </p>

                <SingleInput
                    style={{ flex: "1" }}
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    label="Enter Highlighted text"
                />

                <TwoActionButtonsWrapper
                    onCancel={onClose}
                    onSave={() => cancelPlan(dashboard.id, setIsSuccess, setErrors)}
                    cancelLabel={t("Common.go_back", "Go back")}
                    saveLabel={t("Common.button.confirm_deletion", "Confirm Deletion")}
                    isSaveDisabled={!(confirmationText === deletionConfirmText)}
                    as_form={false}
                    saveButtonAsDanger={true}
                />

            </WrapperFlexCol>

        </PopupLayout>

    )
}


export default ChangePlan