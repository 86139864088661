import classes from "./Elements_styles/LoadingBoxes.module.scss"


export function LoadingDashboardPage({ }) {


    return (
        <div className={`ComponentLoading`}>
            <p> Loading.. </p>
            <span className="loading_animation"></span>
        </div>
    )
}

export function ButtonLoadingBars() {

    return (
        <div className={classes.ButtonLoadingBars}>

        </div>
    )
}