// React
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Styles

// Layout
import PopupLayout from "../Layout/PopupLayout"

// image / svg
// import { ReactComponent as Controll } from "../../../static/icons/Controll.svg"
import { ReactComponent as NewUserIcon } from "../../../static/n_icons/User_add.svg"
import { ReactComponent as ArrowRightIcon } from "../../../static/n_icons/ArrowSmallRight.svg"
import { ReactComponent as UserDeleteIcon } from "../../../static/n_icons/UserDelete.svg"
import { ReactComponent as UserEditIcon } from "../../../static/n_icons/UserEdit.svg"
import { ReactComponent as DeleteInviteIcon } from "../../../static/n_icons/EmailDelete.svg"
import { ReactComponent as WorkIcon } from "../../../static/n_icons/WorkTime.svg"
import { ReactComponent as TagIcon } from "../../../static/n_icons/Tag.svg"

// Context
import { useUserContext } from '../../../context/UserAuth'
import { useDashboardContext } from '../../../context/DashboardContext'
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext'

// Apis
import {
    API_DeleteGuestFromDashboard, API_DeleteInvite, API_DoesUserExistsByEmail, API_GetControlPanelInvites, API_GetDashboardGuestsByDashboard,
    API_InviteNewGuest, API_UpdateDashboardGuestPermissions
} from '../../../api/API_dashboard_guests_and_invitations'


// helpers
import { formatDateTime } from '../../../helper/dateFunctions'
import paths from "../../../data/paths.json"
import { daysOfWeek } from '../../../helper/_calendar'
import { createTimeIntervals } from '../../../helper/funcs'
import { translateDayOfWeek, translateUserPermissions } from '../../../translations/translation_helpers'


// (My) React components
import { ClickableIconWrapper, DataBox, LinkWithSVG, PerrmissionBox, SelectBetweenButtons, Tag, TwoActionButtonsDangerWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow, WrapperGrid2 } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToLightPrimary, ButtonPrimaryFill } from '../../../components/Elements/Buttons'
import { DangerousWarrningContainer, ErrorContainer, LoadingMessage, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { LabelLeftSingleIcon } from '../../../components/Elements/FormLabels'
import { SingleInput } from '../../../components/Elements/FormInputs'
import { DaySchedule } from './Settings'
import { possiblePermissions } from '../../../helper/_UserPermissions'
import { PriceListTag } from './PriceList'



// One thing to note
// We add loading animation ONLY when we are first time loading data, then we dont do that because it looks crappy 


function ControlPanel() {

    const { t } = useTranslation();

    const path_to_avatars = paths.path_to_avatar_images;

    const { currentUser } = useUserContext()
    const { currentDashboardOwnerData } = useDashboardContext()
    const { sharedCurrentDashboard, setSharedCurrentDashboard, initialControlPanelFetch, setInitialControlPanelFetch, dashboardInvites, dashboardUsers, setDashboardInvites, setDashboardUsers } = useSharedDashboardContext()

    const token = localStorage.getItem("access_token")



    // Helper for displaying loading phase
    const [dashboardUsersLoaded, setDashboardUsersLoaded] = useState(false)

    // Here we store all invites
    const [dashboardInvitesLoaded, setDashboardInvitesLoaded] = useState(false);
    const filterInvites = dashboardInvites.filter(invite => invite.invitation_status !== "Accepted")

    // User popups (delete user / edit user)
    const [showDeleteUserPopup, setShowDeleteUserPopup] = useState(false)
    const [showEditUserPopup, setShowEditUserPopup] = useState(false)

    const [selectedUserPopup, setSelectedUserPopup] = useState({})   // We are storing user object here when we click on Edit or Delete (No point of double state)

    // Invite popups 
    const [showDeleteInvitePopup, setShowDeleteInvitePopup] = useState(false)
    const [selectedInvitePopup, setSelectedInvitePopup] = useState({}) // invite object that was selected to be deleted 

    // Invite User Popup toggle (add new user)
    const [showInviteNewUserPopup, setShowInviteNewUserPopup] = useState(false);



    useEffect(() => {
        if (initialControlPanelFetch === false) {
            setDashboardUsersLoaded(false)
            // loadDashboardUsers()

            setDashboardInvitesLoaded(false)
            loadDashboardInvites()


            setInitialControlPanelFetch(true)
        }
        // else {
        //     // Load quietly new stuff without loading animation
        //     loadDashboardUsers()
        //     loadDashboardInvites()
        // }


        // eslint-disable-next-line
    }, [])


    async function loadDashboardUsers() {


        if (token && sharedCurrentDashboard.id) {

            const response = await API_GetDashboardGuestsByDashboard(token, sharedCurrentDashboard.id)

            if (response && response.isOk && response.items) {
                // Success in fetching dashboard users
                console.log(`\n[@ControlPanel > loadDashboardUsers] Dashboard users loaded correctly!`)

                // Here we are also adding OWNER to this list 
                // As for current logic, we take working hours of owner from dashboard because this is better place to hold it instead of user 
                setDashboardUsers([{ ...currentDashboardOwnerData, working_hours: sharedCurrentDashboard.working_hours_owner }, ...response.items])
            }
            else if (response && response.error_message) {
                // Something went wrong in fetch 
                console.log(`\n[@ControlPanel > loadDashboardUsers] Something went wrong with the fetch.`)
                console.log(`\n[@ControlPanel > loadDashboardUsers] ${response.error_message}`)
            }
            else {
                console.log(`\n[@ControlPanel > loadDashboardUsers] Something went wrong with the fetch`)
            }

            // For quiet loading to work
            if (!dashboardUsersLoaded) {
                setDashboardUsersLoaded(true)
            }

        }

    }


    async function loadDashboardInvites() {


        if (token && sharedCurrentDashboard.id) {
            const response = await API_GetControlPanelInvites(token, sharedCurrentDashboard.hash_id)

            if (response && response.isOk && response.items) {
                // Success in fetching dashboard users
                console.log(`\n[@ControlPanel > loadDashboardInvites] Invites loaded correctly!`)
                setDashboardInvites(response.items)
            }
            else if (response && response.error_message) {
                // Something went wrong in fetch 
                console.log(`\n[@ControlPanel > loadDashboardInvites] Something went wrong with the fetch.`)
                console.log(`\n[@ControlPanel > loadDashboardInvites] ${response.error_message}`)
            }
            else {
                console.log(`\n[@ControlPanel > loadDashboardInvites] Something went wrong with the fetch`)
            }

            // Thanks to this, we can clear state if for example we changed user permissions so he no longer can see the invites 
            // To achieve that we just need to check if there is an error when fetching invites and set them to empty 
            if (!response.isOk) {
                setDashboardInvites([])
            }

            // For quiet loading to work
            if (!dashboardInvitesLoaded) {
                setDashboardInvitesLoaded(true)
            }
        }
    }


    function refreshDashboardInvites() {
        loadDashboardInvites()
    }

    function refreshDashboardUsers() {
        loadDashboardUsers()
    }


    return (
        <div className='container is-widescreen'>

            {/* Invite new  */}
            {(showInviteNewUserPopup) && <InviteNewUserPopup refreshDashboardInvites={refreshDashboardInvites} currentUser={currentUser} sharedCurrentDashboard={sharedCurrentDashboard} token={token} handleCloseNewUserPopup={() => setShowInviteNewUserPopup(false)} />}

            {/* Delete invite  */}
            {(showDeleteInvitePopup) && <DeleteInvitePopup onClose={() => setShowDeleteInvitePopup(false)} invite={selectedInvitePopup} refreshDashboardInvites={refreshDashboardInvites} token={token} />}

            {/* Delete user  */}
            {(showDeleteUserPopup) && <DeleteUserPopup onClose={() => setShowDeleteUserPopup(false)} user={selectedUserPopup} sharedCurrentDashboard={sharedCurrentDashboard} token={token} refreshDashboardUsers={refreshDashboardUsers} />}

            {/* Edit user  */}
            {(showEditUserPopup) && <EditUserPopup setSharedCurrentDashboard={setSharedCurrentDashboard} dashboardUsers={dashboardUsers} setDashboardUsers={setDashboardUsers} currentUser={currentUser} token={token} sharedCurrentDashboard={sharedCurrentDashboard} onClose={() => setShowEditUserPopup(false)} user={selectedUserPopup} />}

            {/* Title  */}
            <WrapperFlexRow className={"WrapperTitle"}>

                <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                    <h2 className="SectionTitleText"> {t("Sidebar.users", "Users")} </h2>
                    <p className="DefaultText text_gray_on_layout"> {t("ControlPanel.manage_users", "Manage user access")}</p>

                </WrapperFlexCol>

                <ButtonPrimaryFill onClick={() => setShowInviteNewUserPopup(true)} text={t("ControlPanel.invite_user", "Invite user")} />

            </WrapperFlexRow>




            <WrapperGrid2 className={"onBigTablet FirstElementBigger"}>

                {/* THis logic is stiupid, we have to make it baterr  */}
                {(dashboardUsers.length !== 0 || dashboardInvites.length !== 0) ?
                    <WrapperFlexCol style={{ padding: "0", height: "min-content", gap: "2rem" }}>

                        {/* Display users  */}
                        <DataBox className={"onLayout HideScrollbar"} style={{ height: "min-content" }}>

                            {/* Display all users  */}
                            <RenderUsers currentUser={currentUser} path_to_avatars={path_to_avatars} users={dashboardUsers} sharedCurrentDashboard={sharedCurrentDashboard} t={t} setShowDeleteUserPopup={setShowDeleteUserPopup} setSelectedUserPopup={setSelectedUserPopup} setShowEditUserPopup={setShowEditUserPopup} />


                        </DataBox>


                        {(filterInvites.length !== 0) &&
                            <>
                                <h2 className='DefaultText text-bold text-gray' style={{ marginBottom: "-1.5rem" }}> {t("ControlPanel.sent_invitations", "Sent invitations")} </h2>

                                <DataBox className={"onLayout HideScrollbar"} style={{ height: "min-content" }}>


                                    {/* Display all invites (Pending ) */}
                                    <RenderInvites path_to_avatars={path_to_avatars} invites={filterInvites} setSelectedInvitePopup={setSelectedInvitePopup} setShowDeleteInvitePopup={setShowDeleteInvitePopup} />


                                </DataBox>
                            </>
                        }


                    </WrapperFlexCol>

                    :
                    <p> 404 - Data not found </p>
                }





                {/* User roles description  */}
                <DataBox className={"onLayout HideScrollbar"}>
                    <WrapperFlexCol style={{ padding: "0" }}>
                        <h2 className='DefaultText text-bold'> {t("ControlPanel.roles_title", "User roles")} </h2>
                        <hr className='myHr' />
                        <p className='SmallText'>
                            {t("ControlPanel.team_management_description", "Manage team members in your organization and define their access levels. You have the ability to invite new team members within the allocated slots specified in your subscription plan.")}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexCol style={{ padding: "0" }}>
                        <h2 className='DefaultText text-bold'>
                            {t("ControlPanel.administrator_title", "Administrator")}
                        </h2>
                        <hr className='myHr' />
                        <p className='SmallText'>
                            {t("ControlPanel.administrator_description", "Users with administrator privileges have the same capabilities as owners, except for access to settings.")}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexCol style={{ padding: "0" }}>
                        <h2 className='DefaultText text-bold'>
                            {t("ControlPanel.manager_title", "Manager")}
                        </h2>
                        <hr className='myHr' />
                        <p className='SmallText'>
                            {t("ControlPanel.manager_description", "A user with this permission can perform all actions except viewing user management and changing panel settings.")}
                        </p>

                    </WrapperFlexCol>

                    <WrapperFlexCol style={{ padding: "0" }}>
                        <h2 className='DefaultText text-bold'>
                            {t("ControlPanel.organizer_title", "Organizer")}
                        </h2>
                        <hr className='myHr' />
                        <p className='SmallText'>
                            {t("ControlPanel.organizer_description", "Focuses on organizing visits and schedules. Can manage the calendar, clients, and notes.")}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexCol style={{ padding: "0" }}>
                        <h2 className='DefaultText text-bold'>
                            {t("ControlPanel.guest_title", "Guest")}
                        </h2>
                        <hr className='myHr' />
                        <p className='SmallText'>
                            {t("ControlPanel.guest_description", "Initial role for every new user. Such user cannot modify anything in the system and has limited access to most features. Has access to the calendar.")}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end" }}>
                        <LinkWithSVG navigateTo={`user-roles`} text={t("ControlPanel.full_list", "Full list of permissions")} SVG={<ArrowRightIcon className={"SVG_STROKE_GRAY"} />} />
                    </WrapperFlexRow>

                </DataBox>




            </WrapperGrid2>


        </div>
    )

}


export function RenderUsers({ currentUser, path_to_avatars, users, sharedCurrentDashboard, t, setShowDeleteUserPopup, setSelectedUserPopup, setShowEditUserPopup }) {

    return (
        (users && users.length !== 0) &&
        users.map((user, idx) => {

            let userAvatarPath = (user.avatar) ? path_to_avatars + user.avatar : ""

            let username = (user.name && user.lastname) ? user.name + " " + user.lastname : user.username

            let isCurrentUser = currentUser.id === user.id;
            let isOwner = user.id === sharedCurrentDashboard.dashboard_owner

            return (

                <WrapperFlexCol style={{ padding: "0", gap: "1rem" }} key={`display-user-${idx}`}>

                    {/* Upper  */}
                    <WrapperFlexRow style={{ padding: "0", gap: "1rem", justifyContent: "space-between", flexWrap: "wrap", height: "unset" }} key={`user-${idx}`}>


                        {/* Left side  */}
                        <WrapperFlexRow style={{ padding: "0", gap: "1rem", overflow: "hidden", width: "unset" }}>


                            {/* User avatar  */}
                            {(userAvatarPath) &&
                                <img className={"UserImage"} src={userAvatarPath} alt={`${user.username}-avatar`} />
                            }

                            <WrapperFlexCol style={{ padding: "0", gap: "0" }}>
                                {/* User name and lastname / OR / Username  */}
                                <WrapperFlexRow style={{ padding: "0" }}>
                                    <p className='DefaultText text-bold text_no_wrap'> {username} </p>
                                    {(isCurrentUser) && <PriceListTag bg={"var(--primary_color)"} text={t("Common.You", "You")} />}
                                </WrapperFlexRow>

                                {/* User email  */}
                                <p className='SmallerText text-gray text_no_wrap'> {user.email} </p>
                            </WrapperFlexCol>


                        </WrapperFlexRow>



                        {/* Right side (Permissions and action buttons ) */}
                        <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", width: "min-content", justifyContent: "flex-end" }}>


                            {/* Show Edit and Delete options only if we are the owner of the dashboard */}
                            {/* When we are the owner, we can edit ourselfs and other  */}
                            {/* If we are the guest, we cannot do anything  */}
                            {(currentUser.id === sharedCurrentDashboard.dashboard_owner) &&

                                <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", width: "unset" }}>


                                    {/* ACTION DELETE USER  */}

                                    {/* We dont show Delete user if we are the owner (in our row, pointles ) */}
                                    {sharedCurrentDashboard.dashboard_owner !== user.id &&
                                        <ClickableIconWrapper className={"onWhiteHover_STROKE_DANGER"} onClick={() => {
                                            setShowDeleteUserPopup(true)
                                            setSelectedUserPopup(user)
                                        }}>

                                            <UserDeleteIcon className={"SVG_STROKE_GRAY"} />

                                        </ClickableIconWrapper>
                                    }



                                    {/* ACTION: EDIT USER   */}
                                    <ClickableIconWrapper className={"onWhiteHover_STROKE_GRAY"} onClick={() => {
                                        setShowEditUserPopup(true)
                                        setSelectedUserPopup(user)
                                    }}>
                                        <UserEditIcon className={"SVG_STROKE_GRAY"} />
                                    </ClickableIconWrapper>



                                </WrapperFlexRow>

                            }

                            {/* // Here we display "Owner" only if user.id is equal to dashboard owner  */}
                            <PerrmissionBox isOwner={isOwner} text={isOwner ? t("ControlPanel.user_permissions.owner", "Owner") : translateUserPermissions([user.permissions], t)} />



                        </WrapperFlexRow>


                    </WrapperFlexRow>



                    {/* <hr className='myHr' style={{ maxWidth: "200px" }} /> */}


                    {/* Lower */}
                    {/* Working hours  */}
                    <WrapperFlexRow style={{ padding: "0", gap: "1rem", flexWrap: "wrap" }}>

                        {Object.keys(user.working_hours).map((key, idx) => {

                            // We have property id in DashboardGuest -> working_hours so we have to ignore it 
                            if (key === "id") {
                                return null
                            }

                            let workingHours = user.working_hours[key] // Holds working hours like "08:00x16:00" or "closexclose"
                            let workingHours_formatted = "" // Will hold formatted value like "08:00 - 16:00"

                            if (workingHours === "closexclose") {
                                workingHours_formatted = "-"
                            }
                            else if (workingHours) {
                                workingHours_formatted = workingHours.split("x")[0] + " - " + workingHours.split("x")[1]
                            }

                            return (
                                <WrapperFlexCol style={{ padding: "0", gap: "0.15rem", width: "unset" }} key={`user-${user.id}-working-hours-${idx}`}>

                                    <p className='SmallerText text-bold'> {translateDayOfWeek(key, t)} </p>
                                    <p className='SmallerText text_gray_bg text_no_wrap' style={{ minWidth: "80px", padding: "0.25rem 0.5rem" }}> {workingHours_formatted} </p>

                                </WrapperFlexCol>

                            )

                        })}

                    </WrapperFlexRow>



                    {/* Display hr for all but not the last one  */}
                    {(idx < users.length - 1) &&
                        <hr className='myHr' />
                    }


                </WrapperFlexCol>


            )

        })
    )
}


export function RenderInvites({ path_to_avatars, invites, setSelectedInvitePopup, setShowDeleteInvitePopup }) {

    const { t } = useTranslation();

    return (

        <>

            {invites.map((invite, idx) => {

                let defaultImage = path_to_avatars + "Default_avatar.png"

                let send_date = formatDateTime(invite.creation_date)
                let send_date_text = send_date[0] + " " + send_date[1]

                return (

                    <WrapperFlexCol style={{ padding: "0", gap: "1rem" }} key={`invite-${idx}`}>

                        <WrapperFlexRow className={'FlexWrapOnTablet'} style={{ padding: "0", gap: "0.5rem", justifyContent: "space-between" }}>


                            {/* Left side  */}
                            <WrapperFlexRow style={{ padding: "0", gap: "1.25rem", overflow: "hidden", width: "min-content" }}>


                                {/* User avatar  */}
                                <img className={"UserImage"} src={defaultImage} alt={`${invite.reciever}-avatar`} />


                                {/* User name and lastname / OR / Username  */}
                                <p className='DefaultText text-bold text_no_wrap'> {invite.invitation_receiver} </p>

                                {/* User email  */}
                                {/* <p className='SmallText text-gray text_no_wrap'> {user.email} </p> */}


                            </WrapperFlexRow>



                            {/* Right side (Permissions and action buttons ) */}
                            <WrapperFlexRow className={"FullWidthOnTablet"} style={{ padding: "0", gap: "0.75rem", justifyContent: "flex-end" }}>

                                {/* Display status of invite depending of status */}
                                {
                                    (invite.invitation_status === "Declined") ?
                                        <WrapperFlexCol style={{ padding: "0", gap: "0", alignItems: "flex-end" }}>
                                            <p className='SmallerText text-bold' style={{ minWidth: "max-content" }}> {t("ControlPanel.invitation_status.declined", "Declined")} </p>
                                            <p className='SmallerText text-light text-gray' style={{ minWidth: "max-content" }}> {t("ControlPanel.sent", "Sent at")} {send_date_text} </p>
                                        </WrapperFlexCol>
                                        :
                                        (invite.invitation_status === "Sent") &&
                                        <WrapperFlexCol style={{ padding: "0", gap: "0", alignItems: "flex-end" }}>
                                            <p className='SmallerText text-bold' style={{ minWidth: "max-content" }}> {t("ControlPanel.waiting_for_confirmation", "Waiting for confirmation")} </p>
                                            <p className='SmallerText text-light text-gray' style={{ minWidth: "max-content" }}> {t("ControlPanel.sent", "Sent at")} {send_date_text} </p>
                                        </WrapperFlexCol>

                                }

                                {/* <PerrmissionBox text="Gość" /> */}

                                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", justifyContent: "flex-end", width: "unset" }}>

                                    {/* ACTION DELETE USER  */}
                                    <ClickableIconWrapper className={"onWhiteHover_STROKE_DANGER"} onClick={() => {
                                        setSelectedInvitePopup(invite)
                                        setShowDeleteInvitePopup(true)
                                    }}>

                                        <DeleteInviteIcon className={"SVG_STROKE_GRAY"} />
                                    </ClickableIconWrapper>
                                </WrapperFlexRow>

                            </WrapperFlexRow>


                        </WrapperFlexRow>

                        {(idx < invites.length - 1) &&
                            <hr className='myHr' style={{ marginBottom: "-1rem" }} />
                        }

                    </WrapperFlexCol>

                )
            })}

        </>
    )
}




// # POPUPS # 

export function EditUserPopup({ onClose, user, sharedCurrentDashboard, setSharedCurrentDashboard, token, currentUser, setDashboardUsers, dashboardUsers }) {

    const { t } = useTranslation()

    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([])

    const [editedUser, setEditedUser] = useState({ ...user })

    const [newPermissions, setNewPermissions] = useState(user.permissions || "")

    const initialWorkingHours = daysOfWeek.reduce((acc, day) => {
        const [start, end] = user.working_hours[day].split('x');
        return {
            ...acc,
            [`${day}_start`]: start,
            [`${day}_end`]: end,
        };
    }, {});

    const [workingHours, setWorkingHours] = useState(initialWorkingHours);

    const timeIntervals = createTimeIntervals(0, 23, 30);


    useEffect(() => {

        setEditedUser((prev) => ({
            ...prev,
            working_hours: {
                monday: workingHours.monday_start + "x" + workingHours.monday_end,
                tuesday: workingHours.tuesday_start + "x" + workingHours.tuesday_end,
                wednesday: workingHours.wednesday_start + "x" + workingHours.wednesday_end,
                thursday: workingHours.thursday_start + "x" + workingHours.thursday_end,
                friday: workingHours.friday_start + "x" + workingHours.friday_end,
                saturday: workingHours.saturday_start + "x" + workingHours.saturday_end,
                sunday: workingHours.sunday_start + "x" + workingHours.sunday_end,
            }
        }));


    }, [workingHours])

    async function handleUpdateUser() {

        let errors = []

        if (token && currentUser && currentUser.id) {

            setIsLoading(true)
            const response = await API_UpdateDashboardGuestPermissions(token, sharedCurrentDashboard.id, user.id, editedUser, newPermissions)

            if (response && response.isOk === false && response.error_message) {
                errors.push(response.error_message)
            }
            else if (response && response.isOk) {

                setIsSuccess(true)

                // Update permissions and working hours for the user
                let updatedUser = {
                    ...user,
                    working_hours: editedUser.working_hours // Assuming editedUser.working_hours is the updated working hours data
                };

                // Add permisions to update if we are not the owner,
                // We do not update owners permissions (because it does not have it)
                if (currentUser.id !== editedUser.id && possiblePermissions[newPermissions]) {
                    updatedUser.permissions = newPermissions
                }

                // Update state of the user in setDashboardUsers
                setDashboardUsers(prevUsers => {
                    return prevUsers.map(u => {
                        if (u.id === editedUser.id) {
                            return updatedUser;
                        }
                        return u;
                    });
                });


                // We have to also update state of sharedDashboard with updatedUser.workingHours IF we are updating the owner! 
                // Without this, after proper edit, when we do a silent fetch, it will get old data from sharedDashboard, when it was not yet updated in state
                if (updatedUser.id === sharedCurrentDashboard.dashboard_owner) {
                    setSharedCurrentDashboard({ ...sharedCurrentDashboard, working_hours_owner: updatedUser.working_hours })
                }


                // Clear success message after 2 seconds 
                setTimeout(() => {
                    setIsSuccess(false)
                }, 2000)

            }
            if (response.response && response.response.status === 400) {

                const responseBody = response.response_json.details;

                if (responseBody) {
                    for (const day in responseBody) {
                        if (responseBody.hasOwnProperty(day) && Array.isArray(responseBody[day])) {
                            const dayErrors = responseBody[day];
                            dayErrors.forEach((error) => {
                                if (error.includes("(dv_ts01)")) {
                                    errors.push(`${translateDayOfWeek(day, t)}: ${t("validationErrors.dashboard_endTime", "End time must be at least one hour greater than start time.")}`);
                                }
                                else {
                                    errors.push(`${translateDayOfWeek(day, t)}: ${error}`);
                                }
                            });
                        }
                    }
                }


            }

            setErrors(errors)

            setIsLoading(false)
        }

    }

    return (
        <PopupLayout popupTitle={t("Common.edit_user", "Edit user")} closePopup={onClose}>
            <WrapperFlexCol style={{ padding: "0" }}>

                {(currentUser.id !== user.id) &&
                    <>
                        <LabelLeftSingleIcon LeftIcon={TagIcon} LabelText={t("ControlPanel.user_role", "User role")} />

                        <SelectBetweenButtons currentValue={newPermissions} translationFunction={translateUserPermissions}>
                            <ButtonNeutralToLightPrimary onClick={() => setNewPermissions('Manager')} text={possiblePermissions['Manager']} />
                            <ButtonNeutralToLightPrimary onClick={() => setNewPermissions('Employee')} text={possiblePermissions['Employee']} />
                            <ButtonNeutralToLightPrimary onClick={() => setNewPermissions('Guest')} text={possiblePermissions['Guest']} />
                        </SelectBetweenButtons>
                    </>
                }


                <LabelLeftSingleIcon LeftIcon={WorkIcon} style={(currentUser.id === user.id) ? { marginTop: "0" } : { marginTop: "" }} LabelText={t("ControlPanel.working_hours", "Working hours")} />


                {daysOfWeek.map((day) => (
                    <DaySchedule
                        key={day}
                        day={day}
                        workingHours={workingHours}
                        setWorkingHours={setWorkingHours}
                        timeIntervals={timeIntervals} // Make sure timeIntervals are defined
                        sharedCurrentDashboard={sharedCurrentDashboard} // Pass the sharedCurrentDashboard data
                    />
                ))}



                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("ControlPanel.user_edit_success", "User updated successfully!")} />
                }


                <TwoActionButtonsWrapper
                    onCancel={onClose}
                    onSave={handleUpdateUser}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    saveLabel={t("Common.button.saveChanges", "Save")}
                    isSuccess={isSuccess}
                    loading={isLoading}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )

}


export function DeleteUserPopup({ sharedCurrentDashboard, user, onClose, token, refreshDashboardUsers }) {
    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    async function deleteUser() {

        setIsLoading(true)
        setIsSuccess(false)
        setErrors([])
        let errors = []

        if (sharedCurrentDashboard && sharedCurrentDashboard.id && user && user.id && token) {

            const response = await API_DeleteGuestFromDashboard(token, sharedCurrentDashboard.id, user.id)

            if (response && response.isOk) {
                // Success invite deleted
                setIsSuccess(true)
                refreshDashboardUsers()
            }
            else if (response && response.error_message) {
                // Error while adding an invite 
                errors.push(response.error_message)
            }
            else {
                errors.push(t("Common.something_went_wrong", "Something went wrong. Try again later!"))
            }
        }
        else {
            // Invite not found
            errors.push(t("validationErrors.user_not_found", "User not found!"))
        }

        if (errors.length > 0) {
            setErrors(errors)
        }

        setIsLoading(false)


    }

    return (
        <PopupLayout popupTitle={t("Common.delete_user", "Delete user")} closePopup={onClose}>
            <WrapperFlexCol style={{ padding: "0" }}>

                {/* {(isLoading) &&
                    <LoadingMessage message={t("loadingMessages.deleting_user", `Deleting user`) + ".."} />
                } */}

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("ControlPanel.user_deleted", "User deleted") + "!"} />
                }

                {(!isSuccess) &&
                    <DangerousWarrningContainer message={t("ControlPanel.deleteUserConfirmation", `This action will delete ${user.username}. Every client or visit that was added by that user will be assigned to the owner of the dashboard instead!`, { username: user.username })} />
                }

                <TwoActionButtonsDangerWrapper
                    onCancel={onClose}
                    onSave={deleteUser}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                    loading={isLoading}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )
}


export function DeleteInvitePopup({ invite, onClose, token, refreshDashboardInvites }) {

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    async function handleDeleteInvite() {

        setIsLoading(true)
        setIsSuccess(false)
        setErrors([])
        let errors = []

        if (invite && invite.id && token) {

            const response = await API_DeleteInvite(token, invite.id)

            if (response && response.isOk) {
                // Success invite deleted
                setIsSuccess(true)
                refreshDashboardInvites()
            }
            else if (response && response.error_message) {
                // Error while adding an invite 
                errors.push(response.error_message)
            }
            else {
                errors.push(t("Common.something_went_wrong", "Something went wrong. Try again later!"))
            }
        }
        else {
            // Invite not found
            errors.push(t("validationErrors.user_not_found", "User not found!"))
        }

        if (errors.length > 0) {
            setErrors(errors)
        }

        setIsLoading(false)


    }

    return (
        <PopupLayout popupTitle={t("Common.delete_invite", "Delete invite")} closePopup={onClose}>
            <WrapperFlexCol style={{ padding: "0" }}>

                {/* {(isLoading) &&
                    <LoadingMessage message={t("loadingMessages.deleting_invite", "Deleting invite")} />
                } */}

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("ControlPanel.invite_deleted", "Invite deleted!")} />
                }

                {(!isSuccess) &&
                    <p className="DefaultText">
                        {t("ControlPanel.invite_delete_are_you_sure", "Are you sure you want to delete this invitation?")}
                    </p>
                }

                <TwoActionButtonsDangerWrapper
                    onCancel={onClose}
                    onSave={handleDeleteInvite}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                    loading={isLoading}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )
}


function InviteNewUserPopup({ currentUser, sharedCurrentDashboard, handleCloseNewUserPopup, token, refreshDashboardInvites }) {

    const [userEmail, setUserEmail] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [isSuccess, setIsSuccess] = useState(false)
    const [errors, setErrors] = useState([])

    const { t } = useTranslation();

    async function handleInvite(e) {

        e.preventDefault()

        setIsLoading(true)
        setIsSuccess(false)
        setErrors([])
        let errors = []

        const doesUserExists = await API_DoesUserExistsByEmail(token, userEmail)

        if (doesUserExists && doesUserExists.isOk) {
            // User found

            const found_user_data = doesUserExists.items
            const newInvite = {
                "dashboard_id": sharedCurrentDashboard.id,
                "invitation_receiver_id": found_user_data.id,
                "invitation_owner_id": currentUser.id
            }

            const response = await API_InviteNewGuest(token, newInvite)

            if (response && response.isOk) {
                // Success invite added
                setIsSuccess(true)
                refreshDashboardInvites()
            }
            else if (response && response.error_message) {
                // Error while adding an invite 
                errors.push(response.error_message)

                if (response.response_json && response.response_json.detail && response.response_json.detail.includes('Limit_Error')) {
                    errors.push(t("validationErrors.limit_error.for_users", "Another user cannot be added because the limit of users (+ invites) for the dashboard has been reached. Go to Settings to check your dashboard limits.") + " (Limit_Error)")
                }

            }
            else {
                errors.push(t("Common.something_went_wrong", "Something went wrong. Try again later!"))
            }


        }
        else {
            // User not found
            errors.push(t("validationErrors.user_not_found", "User not found!"))
        }

        if (errors.length > 0) {
            setErrors(errors)
        }

        setIsLoading(false)


    }


    return (
        <PopupLayout popupTitle={t("ControlPanel.invite_user", "Invite user")} closePopup={handleCloseNewUserPopup}>
            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleInvite}>

                {/* {(isLoading) &&
                    <LoadingMessage message={t("loadingMessages.sending_invite_to", "Sending invite to: ")} boldText={userEmail} />
                }  */}

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} style={{ margin: 0, marginBottom: "0.75rem" }} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("ControlPanel.invitation_sent", "Invitation sent") + "!"} style={{ margin: 0, marginBottom: "0.75rem" }} />
                }

                <LabelLeftSingleIcon style={{ marginTop: "0" }} LeftIcon={NewUserIcon} LabelText={t("Common.enter", "Enter user's")} LabelBold={"e-mail"} />

                <WrapperFlexRow style={{ padding: "0", alignItems: "stretch" }}>
                    <SingleInput
                        style={{ width: "100%" }}
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        label="email"
                        placeholder={t("Signin.email_placeholder", "Email")}
                    />

                    <ButtonPrimaryFill loading={isLoading} text={t("Common.send_invite", "Send invite")} onClick={handleInvite} />
                </WrapperFlexRow>

            </WrapperFlexCol>
        </PopupLayout>
    )
}




export default ControlPanel