import React from 'react'
import { ReactComponent as IconDanger } from "../../static/icons/Danger.svg"
import { ReactComponent as IconSuccess } from "../../static/icons/Success.svg"
import { WrapperFlexCol, WrapperFlexRow } from './Wrappers'
import { useTranslation } from 'react-i18next';

export function DangerousWarrningContainer({ style = {}, message = "" }) {

    const { t } = useTranslation();

    return (
        <div className="ErrorContainer" style={style}>

            <div className={"c-icon-holder"}>
                <IconDanger className={"SVG_STROKE_ERROR"} />
            </div>

            <WrapperFlexRow className={'c-message-container'}>

                <WrapperFlexCol className={"c-message-holder"}>
                    <h2 className='DefaultText text-bold text_error'> {t("FormMessageBoxes.warrning", "Warrning")}! </h2>

                    <p className='SmallText text_error'> {message} </p>


                </WrapperFlexCol>

            </WrapperFlexRow>
        </div>
    )
}

export function LoadingMessage({ className, message, boldText = "" }) {
    return (
        <WrapperFlexRow style={{ justifyContent: "space-between" }} className={`${className} NeutralContainer`}>
            <p>
                {message} <b>{boldText}</b>
            </p>
            <span className='loading_animation_circles'></span>
        </WrapperFlexRow>
    );
}

export function ErrorContainer({ style = {}, errors }) {

    const { t } = useTranslation();

    return (
        <div className="ErrorContainer" style={style}>

            <div className={"c-icon-holder"}>
                <IconDanger className={"SVG_STROKE_ERROR"} />
            </div>

            <WrapperFlexRow className={'c-message-container'}>

                <WrapperFlexCol className={"c-message-holder"}>
                    <h2 className='DefaultText text-bold text_error'> {t("FormMessageBoxes.error", "Error")} </h2>

                    {(errors) && errors.map((error, idx) => {
                        // Check if ele is an array and has at least one element
                        const capitalizedFirstElement = error.charAt(0).toUpperCase() + error.slice(1);
                        return <p key={`error-${idx}`} className='SmallText text_error'> - {capitalizedFirstElement} </p>;

                    })}
                </WrapperFlexCol>

            </WrapperFlexRow>
        </div>
    )
}

export function SuccessContainer({ style = {}, message = "" }) {

    const { t } = useTranslation();

    return (
        <div className="SuccessContainer" style={style}>

            <div className={"c-icon-holder"}>
                <IconSuccess className={"SVG_STROKE_SUCCESS"} />
            </div>

            <WrapperFlexRow className={'c-message-container'}>

                <WrapperFlexCol className={"c-message-holder"}>
                    <h2 className='DefaultText text-bold text_success'> {t("FormMessageBoxes.success", "Success")} </h2>
                    <p className='SmallText'> {message} </p>
                </WrapperFlexCol>

            </WrapperFlexRow>
        </div>
    )
}
