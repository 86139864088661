// React
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'


// Styles
import classes from "./Pages_styles/Calendar.module.scss"
import muStyles from "../../../styles/mu-styles.module.scss"

// Layout
import PopupLayout from "../Layout/PopupLayout"


// Icons / svg
import { ReactComponent as CalendarIcon } from "../../../static/n_icons/Calendar_2.svg"
import { ReactComponent as ArrowSmallLeftIcon } from "../../../static/n_icons/ArrowSmallLeft.svg"
import { ReactComponent as ArrowSmallRightIcon } from "../../../static/n_icons/ArrowSmallRight.svg"
import { ReactComponent as ArrowHideLeftIcon } from "../../../static/n_icons/HideLeft.svg"
import { ReactComponent as ArrowHideRightIcon } from "../../../static/n_icons/HideRight.svg"
import { ReactComponent as ScrollToIcon } from "../../../static/n_icons/VerticalScroll.svg"
import { ReactComponent as SelectClientIcon } from "../../../static/n_icons/EditUser.svg"
import { ReactComponent as NotesIcon } from "../../../static/n_icons/Settings_Description.svg"
import { ReactComponent as PlusIcon } from "../../../static/n_icons/Plus.svg"
import { ReactComponent as LatestIcon } from "../../../static/n_icons/Latest.svg"
// import { ReactComponent as LatestClientIcon } from "../../../static/icons/latest.svg"
import { ReactComponent as AssignUserIcon } from "../../../static/n_icons/UserStar.svg"
import { ReactComponent as EditVisitIcon } from "../../../static/n_icons/EditVisit.svg"
// import { ReactComponent as ColorBucketIcon } from "../../../static/icons/Color_bucket.svg"
import { ReactComponent as DeleteIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as CloseIcon } from "../../../static/n_icons/Close.svg"
import { ReactComponent as TagIcon } from "../../../static/n_icons/Tag.svg"
import { ReactComponent as HeartIcon } from "../../../static/n_icons/Heart.svg"
import { ReactComponent as RightLineIcon } from "../../../static/n_icons/Right_Line.svg"

// Context 
import { useUserContext } from '../../../context/UserAuth'
import { useDashboardContext } from '../../../context/DashboardContext'
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext'

// Apis
import { API_CreateNewClientByDashboard, } from "../../../api/API_dashboard_clients"
import { API_CreateNewVisit, API_DeleteVisit, API_EditVisit, API_GetVisitsByMonthAndYear, API_ToggleIsCheckedByOwner } from '../../../api/API_Visits'

// (My) React Components
import { DashboardColorPicker, DataBox, MySelect, NormalBox, SelectBetweenButtons, TwoActionButtonsDangerWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToGray, ButtonNeutralToLightPrimary, ButtonPrimaryFill, ToggleButton } from '../../../components/Elements/Buttons'
import { LabelLeftIconThreeBoldsTexts, LabelLeftIconTwoBoldsTexts, LabelLeftSingleIcon } from '../../../components/Elements/FormLabels'
import { CurrencyInput, SingleInput, TextFieldInput } from '../../../components/Elements/FormInputs'
import { DangerousWarrningContainer, ErrorContainer, LoadingMessage, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { PriceListTag } from './PriceList'

// Public files
import paths from "../../../data/paths.json"
import visit_colors from "../../../data/visit_colors.json";

// Helpers 
import { createTimeSlots } from '../../../helper/dateFunctions'
import { capitalizeFirstLetter, formatTimeRange } from '../../../helper/funcs'
import { translateCalendarTimeSpan, translateDayOfWeek, translateMonth } from '../../../translations/translation_helpers'
import { isNameLastnameValid } from '../../../utils/data_validators/client_validator'
import { getLocalStorageItem, getObjectFromLocalStorage, setLocalStorageItem } from '../../../helper/local_storage'
import { formatCalendarDate, formatCalendarDateForInput, getDaysInMonth, getTodaysWorkingHours, getVisitTimeIntervals, getWeekdayFromCalendarDate, isWorkTime, monthNames, possibleTimeSpans, valid_work_days } from '../../../helper/_calendar'

// Mui
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';



const path_to_avatars = paths.path_to_avatar_images;


export default function Calendar() {

    const { t } = useTranslation();

    const token = localStorage.getItem("access_token")

    const [searchParams, setSearchParams] = useSearchParams()

    const { currentUser } = useUserContext()
    const { currentDashboardOwnerData, currentDashboard } = useDashboardContext()

    const { sharedVisits, setSharedVisits, calendarDate, setCalendarDate, initialCalendarFetch, setInitialCalendarFetch, sharedCurrentDashboard } = useSharedDashboardContext()

    // So we can pass it to CalendarCurrentTimePointer and we can automatically scroll to
    const CalendarRightRef = useRef(null)
    const CurrentTimePointerRef = useRef(null)



    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const currentYear = currentDate.getFullYear();

    // Simple helper variable that holds true ONLY if calendarDate state is exactly as today
    // we need that for example when we display this row line of current time on calendar (we dont want to display it on any other days)
    const [isCalendarDateSetToToday, setIsCalendarDateSetToToday] = useState(Boolean(String(calendarDate.day) === String(currentDay) && String(calendarDate.month) === String(currentMonth) && String(calendarDate.year === currentYear)))

    // calendar_is_full_screen 
    // Is localstorage value that holds 'yes' or 'no' 
    const [isCalendarFullScreen, setIsCalendarFullScreen] = useState((localStorage.getItem("calendar_is_full_screen") ? localStorage.getItem("calendar_is_full_screen") : 'no'))

    // calendar_time_span_selected 
    // Is localstorage value that holds one of below values
    // ['day', 'week', 'month']
    const calendarTimeSpanSelected = localStorage.getItem("calendar_time_span_selected")

    // Current filter in calendar ['day', 'week', 'month']
    const [currentTimeSpan, setCurrentTimeSpan] = useState((calendarTimeSpanSelected) ? calendarTimeSpanSelected : "day")


    const [showAddVisitPopup, setShowAddVisitPopup] = useState(false)

    // Additional variable that will hold str in format HH:MM for example 02:00 
    // We are using it in AddOrEditVisitPopup so we can automaticaly set visit start_hour and start_minute
    const [specifiedDayRowVisit, setSpecifiedDayRowVisit] = useState(null)


    // For edit visit we use Add Visit component
    const [showEditVisitPopup, setShowEditVisitPopup] = useState(false)
    const [visitToEdit, setVisitToEdit] = useState()


    // This holds full visit to delete and its passed to deleteVisitPopup
    const [showDeleteVisitPopup, setShowDeleteVisitPopup] = useState(false)
    const [visitToDelete, setVisitToDelete] = useState()

    // Mini Calendar 

    const [debounceTimeout, setDebounceTimeout] = useState(); // To store the timeout ID


    let todaysWorkingHours = getTodaysWorkingHours(sharedCurrentDashboard, calendarDate);         // Holds String etc '08:00x16:00'
    const currentWeekday = getWeekdayFromCalendarDate(calendarDate)                         // Holds String etc 'monday'
    let todayDashboardStartTime = todaysWorkingHours.split("x")[0]                          // Holds String etc '08:00'
    let todayDashboardEndTime = todaysWorkingHours.split("x")[1]                            // Holds String etc '16:00'

    const isTodayWorkingDay = (todaysWorkingHours === 'closexclose') ? false : true         // Is dashboard day workday or not 
    const currentlyWorking = isWorkTime(todayDashboardStartTime, todayDashboardEndTime);    // Is current time between dashboard working hours 


    // Init, Load visits
    useEffect(() => {

        const calendarDateValid = checkUrlData()

        // Initial load, we do this only once per user session. Only when he first time oppened calendar
        // It is needed so we dont rerender calendar everytime we enter this component 
        if (initialCalendarFetch === false) {

            fetchMonthOfVisits(calendarDateValid.month, calendarDateValid.year)
            setInitialCalendarFetch(true)

            setCalendarDate({ ...calendarDateValid })

        }
        else {
            // We wanna do a silent fetch if we dont have any params selected 
            // Meaning, user just openned the calendar, we dont do this if user clicked on some date adding to url something (because it will cause rerender)
            if (searchParams.size === 0) {
                fetchMonthOfVisits(calendarDateValid.month, calendarDateValid.year)

                setCalendarDate({ ...calendarDateValid })
            }
        }


        // eslint-disable-next-line
    }, [])



    // A simple url check, if user passed correct data
    // We check if for example day is not 31 when month is February
    // And if date is between some proper numbers 
    // If something in url is wrong, we dont touch calendarDate and it is set to default (today date, if day is mismatch we set to as 1)
    // Returns valid calendarDate object 
    function checkUrlData() {
        const dayParam = parseInt(searchParams.get("day")) || currentDay;
        const monthParam = parseInt(searchParams.get("month")) || currentMonth;
        const yearParam = parseInt(searchParams.get("year")) || currentYear;

        // Validate day, month, and year
        const isValidMonth = monthParam >= 1 && monthParam <= 12;
        const lastDayOfMonth = new Date(yearParam, monthParam, 0).getDate();
        const isValidDay = isValidMonth && dayParam >= 1 && dayParam <= lastDayOfMonth;

        const isValidYear = yearParam >= currentYear && yearParam < currentYear + 50;  // Assuming years should be greater than or equal to the current year + 50

        if (isValidDay && isValidMonth && isValidYear) {

            return {
                day: dayParam,
                month: monthParam,
                year: yearParam
            }
        } else {

            // Handle invalid parameters, perhaps redirect or show an error message
            // console.error("Invalid date parameters in the URL");
            return {
                day: dayParam,
                month: monthParam,
                year: yearParam
            }
        }
    }


    // Every time calendar date changes
    useEffect(() => {

        // Update IsCalendarDateSetToToday 
        setIsCalendarDateSetToToday(Boolean(String(calendarDate.day) === String(currentDay) && String(calendarDate.month) === String(currentMonth) && String(calendarDate.year === currentYear)))

        // Scroll to the top when calendarDate changes and we are on 'day' time span 
        // if (CalendarRightRef.current && currentTimeSpan === 'day') {
        //     CalendarRightRef.current.scrollTop = 0;
        // }


        // eslint-disable-next-line
    }, [calendarDate])

    // Switch between day / week / month in calendar 
    // We also store that information in localStorage
    function handleTimeSpan(newTimeSpan) {
        if (possibleTimeSpans.includes(newTimeSpan)) {
            setCurrentTimeSpan(newTimeSpan)

            localStorage.setItem("calendar_time_span_selected", newTimeSpan)
        }
    }

    // Toggle visit isConfirmedByUser (For Client.js and Calendar.js)
    async function toggleVisitIsConfirmedByUser(visit) {
        if (token && currentUser && currentUser.id) {

            const response = await API_ToggleIsCheckedByOwner(token, visit.id)

            if (response.isOk) {
                // Toggle success
                let updated_item = { ...visit }
                updated_item.isCheckedByOwner = !updated_item.isCheckedByOwner

                setSharedVisits(prev =>
                    prev.map(item =>
                        item.id === updated_item.id ? updated_item : item
                    )
                );
            }
            else {
                // Toggle unsuccessful 
                console.log(response.error_message)

            }
        }
    }

    // This function is triggered when we click on 'reposition calendar' button 
    // So user will be moved to current time on calendar
    function moveToCurrentTime() {

        if (CalendarRightRef.current && CurrentTimePointerRef.current) {

            CurrentTimePointerRef.current.scrollIntoView({
                behavior: 'smooth', // You can use 'auto' for immediate scrolling
                block: 'center',
                inline: 'center',
            });
        }
    }

    // Show / hide left panel 
    function toggleLeftPanel() {
        let isCalendarFullScreen_local = localStorage.getItem("calendar_is_full_screen")

        // If localstorage doesnt exists 
        // Then set fullScreen and also create localstorage
        if (!isCalendarFullScreen_local) {
            localStorage.setItem("calendar_is_full_screen", 'yes')
            setIsCalendarFullScreen('yes')
        }

        // Switch to 'yes' => Show
        else if (isCalendarFullScreen_local && isCalendarFullScreen_local === 'no') {
            localStorage.setItem("calendar_is_full_screen", 'yes')
            setIsCalendarFullScreen('yes')
        }

        // Hide 'no'
        else {
            localStorage.setItem("calendar_is_full_screen", 'no')
            setIsCalendarFullScreen('no')
        }


    }



    function handleAddEditVisitClose(type) {
        // Type can be either Add or Edit 
        if (type === "Add") {
            setShowAddVisitPopup(false);

            // We have to clear this when closing visits, because then it will show wrong user selected when we add new visit
            // It is needed for this functionlaity when we add visit in 'day' time span while hovering over row 
            setSpecifiedDayRowVisit(null)
        }
        else if (type === 'Edit') {
            setShowEditVisitPopup(false)
        }

    }


    // This function is called when we change month in our date 
    async function fetchMonthOfVisits(month, year) {


        if (token && currentDashboard.id) {

            const response = await API_GetVisitsByMonthAndYear(token, currentDashboard.id, year, month)

            if (response && response.isOk && response.items) {
                // Success in fetching dashboard guests
                console.log(`\n[@Calendar > fetchMonthOfVisits] Visits loaded correctly!`)
                setSharedVisits(response.items)
            }
            else if (response && response.error_message) {
                // Something went wrong in fetch 
                console.log(`\n[@Calendar > fetchMonthOfVisits] Something went wrong with the fetch.`)
                console.log(`\n[@Calendar > fetchMonthOfVisits] ${response.error_message}`)
            }
            else {
                console.log(`\n[@Calendar > fetchMonthOfVisits] Something went wrong with the fetch`)
            }

        }
    }


    return (
        <div className={classes.Calendar}>

            {(showAddVisitPopup) && <AddOrEditVisitPopup type='Add' specifiedDayRowVisit={specifiedDayRowVisit} visitToEdit={visitToEdit} setVisitToEdit={setVisitToEdit} todayDashboardStartTime={todayDashboardStartTime} todayDashboardEndTime={todayDashboardEndTime} handleClose={() => { handleAddEditVisitClose('Add') }} token={token} />}

            {(showEditVisitPopup) && <AddOrEditVisitPopup type='Edit' visitToEdit={visitToEdit} setVisitToEdit={setVisitToEdit} todayDashboardStartTime={todayDashboardStartTime} todayDashboardEndTime={todayDashboardEndTime} handleClose={() => { handleAddEditVisitClose('Edit') }} token={token} />}

            {(showDeleteVisitPopup) && <DeleteVisitPopup token={token} visitToDelete={visitToDelete} setVisitToDelete={setVisitToDelete} setShowDeleteVisitPopup={setShowDeleteVisitPopup} />}


            {/* Loading animation  */}
            {/* <WrapperFlexCol className={`${classes.LoadingCalendarOverlay}`}>
                    <DataBoxWrapper className={`HideScrollbar noShadow DataBox_style noHover ${classes.LoadingCalendarBox}`}>
                        <span className='loading_animation_circles'></span>
                        <p className='SmallText text-gray'> Loading calendar.. </p>
                    </DataBoxWrapper>
                </WrapperFlexCol> */}


            <WrapperFlexRow className={classes.RightBottomControlls}>

                <SelectBetweenButtons currentValue={currentTimeSpan} translationFunction={translateCalendarTimeSpan}>
                    <ButtonNeutralToLightPrimary onClick={() => handleTimeSpan('day')} text={('day')} />
                    <ButtonNeutralToLightPrimary onClick={() => handleTimeSpan('month')} text={('month')} />
                </SelectBetweenButtons>

                {/* Scroll into current time */}
                {/* Only avaliable when between working hours, so it wont display when off the working hours  */}
                {/* Also it will only show up on 'day' time span  */}
                {(currentlyWorking && isCalendarDateSetToToday && calendarTimeSpanSelected === 'day') &&
                    <ButtonPrimaryFill className={`${classes.ScrollToCurrentTime} centerAllContent`} text={<ScrollToIcon className={"SVG_STROKE_WHITE"} />} onClick={moveToCurrentTime} />
                }
                <ButtonPrimaryFill className={`${classes.AddVisitBtn} centerAllContent`} onClick={() => { setShowAddVisitPopup(true) }} text={<PlusIcon className={"SVG_STROKE_WHITE"} />} />
            </WrapperFlexRow>

            {/* Calendar Actions like Switch between Day / Week / Month view  */}
            {/* Also Here we display current Day or Week etc  */}

            <DataBox className={`${classes.CalendarNav} HideScrollbar noShadow`} style={{ padding: "0" }}>

                <WrapperFlexRow className={classes.CalendarControls}>

                    {(isCalendarFullScreen === 'no') ?
                        <>
                            <ButtonNeutralToGray label_stroke={true} noMargin={true} className={`centerAllContent ${classes.btnTransform}`} onClick={toggleLeftPanel} text={<ArrowHideLeftIcon className={"SVG_STROKE"} />} />
                        </>
                        :
                        <>
                            <ButtonNeutralToGray label_stroke={true} noMargin={true} className={`centerAllContent ${classes.btnTransform}`} onClick={toggleLeftPanel} text={<ArrowHideRightIcon className={"SVG_STROKE"} />} />
                        </>

                    }

                    <CalendarDateControlls currentWeekday={currentWeekday} debounceTimeout={debounceTimeout} currentTimeSpan={currentTimeSpan} fetchMonthOfVisits={fetchMonthOfVisits} setSearchParams={setSearchParams} />


                </WrapperFlexRow>




            </DataBox>


            {/* Main Content holder  */}
            <div className={classes.CalendarWrapper}>


                {/* Left side of calendar / Controls  */}
                <WrapperFlexCol className={classes.CalendarLeft + " HideScrollbar"} style={{ alignItems: "flex-start", display: ((isCalendarFullScreen === 'yes') ? "none" : "flex") }}>

                    {/* Small calendar that we can pick a day  */}
                    <MiniCalendar setSearchParams={setSearchParams} setDebounceTimeout={setDebounceTimeout} debounceTimeout={debounceTimeout} fetchMonthOfVisits={fetchMonthOfVisits} />

                    {/* Calendar Workfrom info  */}
                    <RenderCalendarWorkDetail isCalendarDateSetToToday={isCalendarDateSetToToday} isTodayWorkingDay={isTodayWorkingDay} todayDashboardEndTime={todayDashboardEndTime} todayDashboardStartTime={todayDashboardStartTime} currentWeekday={currentWeekday} />


                    {/* Calendar Users  */}
                    <RenderCalendarUsers currentWeekday={currentWeekday} />


                    <RenderPriceLists />


                </WrapperFlexCol>

                {/* Render Calendar depending on Time span  */}
                {/* day or month  */}

                <CalendarCurrentTimeSpanController setSpecifiedDayRowVisit={setSpecifiedDayRowVisit} currentWeekday={currentWeekday} currentTimeSpan={currentTimeSpan} CalendarRightRef={CalendarRightRef} visitsInMonth={sharedVisits} t={t} setVisitToEdit={setVisitToEdit}
                    toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} setShowAddVisitPopup={setShowAddVisitPopup} handleTimeSpan={handleTimeSpan} currentDashboardOwnerData={currentDashboardOwnerData} setShowDeleteVisitPopup={setShowDeleteVisitPopup} setVisitToDelete={setVisitToDelete} setShowEditVisitPopup={setShowEditVisitPopup} isCalendarDateSetToToday={isCalendarDateSetToToday} CurrentTimePointerRef={CurrentTimePointerRef}

                />

            </div>

        </div >
    )
}



// This is where we click on arrow left and right above calendar
// Here we put those controls and update calendarDate from here
export function CalendarDateControlls({ currentWeekday, currentTimeSpan, fetchMonthOfVisits, debounceTimeout, setSearchParams }) {

    const { t } = useTranslation();
    const { setCalendarDate, calendarDate } = useSharedDashboardContext()

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const currentYear = currentDate.getFullYear();

    const isTodayButtonDisabled =
        String(calendarDate.day) !== String(currentDay) ||
        String(calendarDate.month) !== String(currentMonth) ||
        String(calendarDate.year) !== String(currentYear);

    // Function to update the calendar date state
    function updateCalendarDate(day, month, year) {
        setCalendarDate({ day, month, year });
        setSearchParams({ day: day, month: month, year: year }, { replace: true })
    }

    // When user clicks on today
    function handleTodayClick() {
        // This is needed, because if we spam left / right on mini calendar (month change) and click immidietly to today 
        // We might get data for wrong months (other data will be fetched faster..)
        clearTimeout(debounceTimeout);
        updateCalendarDate(currentDay, currentMonth, currentYear);

        if (currentMonth !== calendarDate.month) {
            fetchMonthOfVisits(currentMonth, currentYear)
        }

    }

    // Function to handle arrow clicks
    function arrowLeftClick() {
        if (currentTimeSpan === 'day' || currentTimeSpan === 'month') {

            const prevDate = () => {
                let { day, month, year } = calendarDate;

                // Decrease the day by one
                day--;

                // Check if the day becomes zero
                if (day === 0) {
                    // Move to the previous month
                    month--;

                    // Check if the month becomes zero (December)
                    if (month === 0) {
                        // Move to the previous year (December of the previous year)
                        year--;
                        month = 12; // Set the month to December
                    }


                    fetchMonthOfVisits(month, year)

                    // Calculate the last day of the previous month
                    const lastDayOfPreviousMonth = new Date(year, month, 0).getDate();

                    // Set the day to the last day of the previous month
                    day = lastDayOfPreviousMonth;
                }

                return { day, month, year };
            }

            const newDate = prevDate()
            setCalendarDate({ ...newDate })
            setSearchParams({ day: newDate.day, month: newDate.month, year: newDate.year }, { replace: true })
        }


    }

    function arrowRightClick() {
        if (currentTimeSpan === 'day' || currentTimeSpan === 'month') {
            const nextDate = () => {
                let { day, month, year } = calendarDate;

                // Calculate the last day of the current month
                const lastDayOfCurrentMonth = new Date(year, month, 0).getDate();

                // Increase the day by one
                day++;

                // Check if the day exceeds the last day of the current month
                if (day > lastDayOfCurrentMonth) {
                    // Move to the next month
                    month++;

                    // Check if the month exceeds December
                    if (month > 12) {
                        // Move to the next year
                        year++;
                        month = 1; // Set the month to January
                    }

                    fetchMonthOfVisits(month, year)

                    // Set the day to the first day of the next month
                    day = 1;
                }

                return { day, month, year };
            }

            const newDate = nextDate()
            setCalendarDate({ ...newDate })
            setSearchParams({ day: newDate.day, month: newDate.month, year: newDate.year }, { replace: true })


        }
    }

    return (

        <WrapperFlexRow style={{ padding: "0", gap: "1rem", width: "unset" }}>

            <ButtonNeutralToGray label_stroke={true} noMargin={true} className={"centerAllContent"} text={<ArrowSmallLeftIcon className={"SVG_STROKE"} />} onClick={() => arrowLeftClick()} />

            <WrapperFlexCol style={{ padding: "0", gap: '0', width: "unset", justifyContent: "center", alignItems: "center" }}>
                <p className={`${classes.CalendarDateText} DefaultText text-bold text_no_wrap`}> {formatCalendarDate(calendarDate)} </p>
                <p className={`SmallerText text_no_wrap text_light_bold text-grayr`}> {translateDayOfWeek(currentWeekday, t)} </p>
            </WrapperFlexCol>


            <ButtonNeutralToGray label_stroke={true} noMargin={true} className={"centerAllContent"} text={<ArrowSmallRightIcon className={"SVG_STROKE"} />} onClick={() => arrowRightClick()} />

            <ButtonPrimaryFill onClick={handleTodayClick} style={{ padding: "0.5rem 1rem" }} text={t("Calendar.today", "Today")} disabled={!isTodayButtonDisabled} />

        </WrapperFlexRow>
    );
}



// Component that returns a div (absolute) that will point at current time 
// Only when currentTime is between StartTime and EndTime 
function CalendarCurrentTimePointer({ calendarRowHeight, CurrentTimePointerRef, adjustedStartTime, adjustedEndTime }) {

    const [topPosition, setTopPosition] = useState(0);

    if (adjustedEndTime === "00:00") adjustedEndTime = "24:00"

    // Parse the start and end times
    const parseTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return { hours, minutes };
    };


    const { hours: startHours, minutes: startMinutes } = parseTime(adjustedStartTime);
    const { hours: endHours } = parseTime(adjustedEndTime);

    useEffect(() => {
        const updateTimePosition = () => {

            const now = new Date();
            const currentHours = now.getHours();
            const currentMinutes = now.getMinutes();

            if (currentHours >= startHours && currentHours < endHours) {
                const minutesFromStart = (currentHours - startHours) * 60 + (currentMinutes - startMinutes);
                // Assuming the time slot is divided into 15-minute intervals
                const position = (minutesFromStart / 15) * calendarRowHeight;
                setTopPosition(position);
            } else {
                setTopPosition(-100); // Hide pointer if outside of working hours
            }
        };

        updateTimePosition(); // Set initial position

        // Any seconds that u set up here, will actually make a initial delay to our timer, 
        // if you set it at one minute, when user enter at 09:59:49 i will show 09:59 untile we hit 10:00:49 then it will switch to 10:00
        // Not a problem, for now make it with 10 seconds delay, seems to be optional 
        const interval = setInterval(updateTimePosition, 10000); // Update every 10 seconds

        return () => clearInterval(interval); // Clean up the interval on unmount


        // eslint-disable-next-line
    }, [adjustedStartTime, adjustedEndTime]);

    if (topPosition < 0) {
        return null;
    }

    const now = new Date();
    const currentHours = String(now.getHours()).padStart(2, '0'); // Ensure two digits with leading zero
    const currentMinutes = String(now.getMinutes()).padStart(2, '0'); // Ensure two digits with leading zero


    return (
        <div
            id="calendar-pointer"
            className={classes.CalendarCurrentTimePointer}
            style={{ top: `${topPosition}px` }}
            ref={CurrentTimePointerRef}
        >
            <WrapperFlexRow className={classes.CalendarCurrentTimePointer_Time} style={{ justifyContent: "center" }}>
                <p className='SmallText text_white text-bold'> {currentHours}:{currentMinutes} </p>
            </WrapperFlexRow>
        </div>
    );
}



// MiniCalendar that we can use to navigate between months 
function MiniCalendar({ fetchMonthOfVisits, debounceTimeout, setDebounceTimeout, setSearchParams }) {


    const { t } = useTranslation();
    const { setCalendarDate, calendarDate } = useSharedDashboardContext()

    const daysInCurrentMonth = getDaysInMonth(calendarDate.year, calendarDate.month);
    const currentDate = new Date();

    // THIS IS CALLED DEBOUNCE TIMEOUT 
    const delayedFetchMonthOfVisits = (month, year) => {
        clearTimeout(debounceTimeout);

        setDebounceTimeout(setTimeout(() => {
            fetchMonthOfVisits(month, year);
            setSearchParams((prev) => ({ ...prev, day: calendarDate.day, month: month, year: year }), { replace: true });
        }, 600));

    };

    // Function to update calendar date
    const updateCalendarDate = (newDay, newMonth, newYear) => {
        setCalendarDate((prevDate) => ({ ...prevDate, day: newDay, month: newMonth, year: newYear }));
        setSearchParams({ day: newDay, month: newMonth, year: newYear }, { replace: true })
    };

    // Function to handle arrow left/right click
    // increment is int -1 or 1 (-1 is left, 1 is right )
    const arrowLeftRight = (increment) => {
        let { month, year } = calendarDate;
        month += increment;

        if (month > 12) {
            month = 1;
            year++;
        } else if (month < 1) {
            month = 12;
            year--;
        }

        delayedFetchMonthOfVisits(month, year);

        // Update setCalendarDate with the new values
        setCalendarDate({ ...calendarDate, day: 1, month, year });

    };

    // Function to change the selected day
    const changeCurrentDay = (newDay) => {
        updateCalendarDate(newDay, calendarDate.month, calendarDate.year);
    };


    return (
        <DataBox className={`${classes.CalendarMini} HideScrollbar onLayout noShadow`}>


            <div className={classes.CalendarMiniWrapper}>


                <div className={classes.CalendarMiniActions}>

                    <p className='DefaultText text-bold'>
                        {translateMonth(monthNames[calendarDate.month - 1].toLowerCase(), t)} &nbsp;
                        {calendarDate.year}
                    </p>

                    <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", width: "unset" }}>
                        <ButtonNeutralToLightPrimary style={{ border: 0 }} label_stroke={false} text={<ArrowSmallLeftIcon />} onClick={() => arrowLeftRight(-1)} />
                        <ButtonNeutralToLightPrimary style={{ border: 0 }} label_stroke={false} text={<ArrowSmallRightIcon />} onClick={() => arrowLeftRight(1)} />
                    </WrapperFlexRow>

                </div>


                <div className={classes.CalendarMiniDaysNames}>
                    {valid_work_days.map((day, idx) => (
                        <p key={`day-${day}x${idx}`} className='SmallerText text-bold'>{translateDayOfWeek(day.toLowerCase(), t).substring(0, 3)}</p>
                    ))}
                </div>


                <div className={classes.CalendarMiniDays}>

                    {daysInCurrentMonth.map((day, idx) => {
                        if (day === "0") {
                            return <div key={`day-x${day}${idx}`}></div>;
                        }
                        const isDayOnTodayDate = (String(day).padStart(2, '0') === currentDate.getDate().toString().padStart(2, '0') &&
                            String(calendarDate.month) === (currentDate.getMonth() + 1).toString() &&
                            String(calendarDate.year) === currentDate.getFullYear().toString());

                        const isCurrentDateOnCorrectDay = (String(calendarDate.day) === day)

                        return (
                            <div onClick={() => changeCurrentDay(day)} key={`day-x${day}${idx}`}>
                                <p className={`SmallText ${classes.CalendarDay} ${(isCurrentDateOnCorrectDay) && classes.currentDay} ${(isDayOnTodayDate) && classes.todayDay}`}>{day}</p>
                            </div>
                        );
                    })}

                </div>
            </div>
        </DataBox>
    );
}



// A small box with informations about current day and work time from to 
function RenderCalendarWorkDetail({ isCalendarDateSetToToday, isTodayWorkingDay, todayDashboardStartTime, todayDashboardEndTime, currentWeekday }) {

    const { t } = useTranslation();

    return (
        <DataBox className={`${classes.CalendarWorkdays} onLayout HideScrollbar noShadow`} style={{ height: "unset", gap: "0.5rem" }}>

            <p className={'SmallText'}>
                {(isCalendarDateSetToToday) && t("Calendar.today_is", "Today is") + " "}
                <span className='text-capitalize text-bold' style={{ display: "inline-block" }}>
                    {translateDayOfWeek(currentWeekday, t)}
                </span>
            </p>


            {(isTodayWorkingDay) ?
                <p className={'SmallerText'}> {t("Calendar.work_time_from", "Work time is from")}
                    <span className='text-bold'> {todayDashboardStartTime} </span>
                    {t("Calendar.work_time_to", "to")}
                    <span className='text-bold'> {todayDashboardEndTime} </span>
                </p>
                :
                <p className={'SmallText'}> {t("Calendar.work_day_closed", "We are closed")} </p>
            }

        </DataBox>
    )
}



// Display list of current users / guests on the left panel in calendar
function RenderCalendarUsers({ currentWeekday }) {

    const { dashboardUsers } = useSharedDashboardContext()
    const { t } = useTranslation();

    return (
        <DataBox className={`${classes.CalendarUsers} onLayout HideScrollbar noShadow`} style={{ height: "unset", gap: "0.5rem" }}>

            {(dashboardUsers && dashboardUsers.length > 0) &&
                <>

                    <p className={'SmallText text-bold'}> {t("Common.users", "Users")} </p>


                    <div className={classes.CalendarUsersWrapper}>
                        {dashboardUsers.map((guest, idx) => {

                            const userImgPath = (guest && guest.avatar) ? path_to_avatars + guest.avatar : ""

                            // Working days that user actualy works 
                            // ['monday', 'tuesday', ..]
                            const workingDays = Object.keys(guest.working_hours)

                            // Today working hours of guest like '[08:00, 16:00]' (splited by 'x;)
                            const todayWorkingHours = (guest.working_hours[currentWeekday]) && (guest.working_hours[currentWeekday].split('x'))

                            return (
                                <WrapperFlexCol style={{ padding: "0", gap: "0.5rem", height: "unset" }} key={`${guest.username}-id-${idx}`}>

                                    <WrapperFlexRow style={{ padding: "0" }}>

                                        <img src={userImgPath} alt="" />

                                        <WrapperFlexCol style={{ padding: "0", gap: 0 }}>
                                            <p className={'SmallText text_light_bold text-capitalize'}> {guest.username} </p>
                                            {(todayWorkingHours[0] === 'close' || todayWorkingHours[1] === 'close') ?
                                                <p className={'SmallText'}> {t("Calendar.user_not_work", "Not working in this day")} </p>
                                                :
                                                <p className={'SmallText'}> {todayWorkingHours[0]} - {todayWorkingHours[1]} </p>
                                            }

                                        </WrapperFlexCol>
                                    </WrapperFlexRow>

                                    <p className='SmallerText text-gray' style={{ marginTop: '0.5rem' }}> {t("Calendar.avaliable_in", "Avaliable")} </p>

                                    {/* Work hours of users  */}
                                    <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", gap: "0.5rem" }}>
                                        {workingDays.map((day, idx) => {

                                            if (day === 'id') return null


                                            if (guest.working_hours[day] === "closexclose") {
                                                return null
                                            }


                                            return (
                                                <div className={classes.CalendarUserAvaliableDayTag} key={`${guest.username}-avaliable-${day}-${idx}`}>
                                                    <p className='SmallerText'> {translateDayOfWeek(day.toLowerCase(), t).substring(0, 3)} </p>
                                                </div>
                                            )

                                        })}
                                    </WrapperFlexRow>

                                    {/* Dont show last hr  */}
                                    {(dashboardUsers.length - 1 > idx) && <hr className={"myHr"} style={{ marginTop: "0.5rem" }} />}

                                </WrapperFlexCol>
                            )
                        })}

                    </div>

                </>

            }
        </DataBox>
    )
}



// Display list of all services 
function RenderPriceLists() {

    const { t } = useTranslation();
    const { moveToPathInsideDashboard, dashboardHash } = useDashboardContext()

    const { sharedDashboardPriceLists } = useSharedDashboardContext()


    // Dont show it if we dont have any priceLists 
    if (sharedDashboardPriceLists && sharedDashboardPriceLists.length === 0) return

    // Sort the price lists by name (A to Z)
    // !IMPORTANT, We should fix it later, so we wont sort on every rerender.. 
    let sortedPriceLists = sharedDashboardPriceLists.slice().sort((a, b) => a.name.localeCompare(b.name));


    return (
        <DataBox className={`onLayout HideScrollbar noShadow ${classes.PriceListBox}`} style={{ height: "unset", gap: "0.5rem", minWidth: "unset" }}>

            <WrapperFlexRow style={{ padding: "0", height: "unset", justifyContent: "space-between" }}>

                <p className={'SmallText text-bold'}> <span className='text-capitalize' style={{ display: "inline-block" }}> {t("PriceList.your_services", "List of services")} {`(${sortedPriceLists.length})`} </span> </p>

                <div onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/price-list`)} className={classes.PriceListNavigateTo}> <RightLineIcon className={"svg_medium SVG_STROKE"} /> </div>
            </WrapperFlexRow>

            <hr className='myHr' />

            <WrapperFlexCol className={"HideScrollbar"} style={{ height: "unset", gap: "0.5rem", padding: "0", maxHeight: "300px", overflowY: "auto" }}>

                {sortedPriceLists.map((ele, idx) => {
                    return (
                        <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }} key={`price-list-${idx}`}>
                            <p className='SmallerText text_light_bold' style={{ overflow: "hidden" }}> {idx + 1}. {ele.name} </p>
                            <PriceListTag bg={ele.color} text={ele.tag} />
                        </WrapperFlexRow>
                    )
                })}

            </WrapperFlexCol>


        </DataBox>
    )
}



// This is a controler that will display proper stuff depending on current time span ['day', 'week', 'month']
export function CalendarCurrentTimeSpanController({ setSpecifiedDayRowVisit, currentWeekday, toggleVisitIsConfirmedByUser, setShowAddVisitPopup, handleTimeSpan, currentTimeSpan, CalendarRightRef, visitsInMonth, t, setVisitToEdit, setShowDeleteVisitPopup, setVisitToDelete, setShowEditVisitPopup, isCalendarDateSetToToday, CurrentTimePointerRef }) {


    if (currentTimeSpan === 'day') {
        return (
            <CalendarDay setSpecifiedDayRowVisit={setSpecifiedDayRowVisit} setShowAddVisitPopup={setShowAddVisitPopup} currentWeekday={currentWeekday} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} CalendarRightRef={CalendarRightRef} visitsInMonth={visitsInMonth} t={t} setVisitToEdit={setVisitToEdit}
                setShowDeleteVisitPopup={setShowDeleteVisitPopup} setVisitToDelete={setVisitToDelete} setShowEditVisitPopup={setShowEditVisitPopup} isCalendarDateSetToToday={isCalendarDateSetToToday} CurrentTimePointerRef={CurrentTimePointerRef}
            />
        )
    }

    else if (currentTimeSpan === 'week') {
        return (
            <p> {currentTimeSpan} </p>
        )
    }

    else if (currentTimeSpan === 'month') {
        return (
            <CalendarMonth toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} setShowAddVisitPopup={setShowAddVisitPopup} handleTimeSpan={handleTimeSpan} setShowDeleteVisitPopup={setShowDeleteVisitPopup} setVisitToDelete={setVisitToDelete} visitsInMonth={visitsInMonth} t={t} setShowEditVisitPopup={setShowEditVisitPopup} setVisitToEdit={setVisitToEdit} />
        )

    }


    return (
        <p> {currentTimeSpan} </p>
    )


}



// CALENDAR: DAY 
function CalendarDay({ setSpecifiedDayRowVisit, setShowAddVisitPopup, currentWeekday, toggleVisitIsConfirmedByUser, CalendarRightRef, visitsInMonth, t, setVisitToEdit, setShowDeleteVisitPopup, setVisitToDelete, setShowEditVisitPopup, isCalendarDateSetToToday, CurrentTimePointerRef }) {

    const { calendarDate, dashboardUsers, sharedCurrentDashboard } = useSharedDashboardContext()
    const { currentUser } = useUserContext()

    // Here are some values that has to be the same as in scss. 
    // It has to be same as styles in scss about visits (visitsBox etc..)
    const calendarRowHeight = 64; // height of single time row (has to be exact like in calendar.scss)
    const calendarRowPadding = 16 // 0.5rem = 16 of top / bottom padding
    const dashboardTimeSlotStep = 15 // 15 mins 

    let todaysWorkingHours = getTodaysWorkingHours(sharedCurrentDashboard, calendarDate);         // Holds String etc '08:00x16:00'
    let todayDashboardStartTime = todaysWorkingHours.split("x")[0]                          // Holds String etc '08:00'
    let todayDashboardEndTime = todaysWorkingHours.split("x")[1]                            // Holds String etc '16:00'

    // Helper to know if current day is working day or not so we can change display a bit
    const isTodayWorkingDay = (todaysWorkingHours === 'closexclose') ? false : true

    // If today is not working day, then we put calendar in hours from 01:00 to 23:00 
    if (!isTodayWorkingDay) {
        todayDashboardStartTime = "01:00"
        todayDashboardEndTime = "23:00"
    }

    // Bottom / Top margin of calendar
    // 1 Means one hour before and after calendar will be added
    // If we have step at 15 then we will have FOUR more rows that are a margin 
    // const calendarTimeMargin = (todayDashboardStartTime !== "00:00" && todayDashboardEndTime !== "23:00" && todayDashboardEndTime !== "00:00") ? 1 : 0

    // Start of calendar margin
    const bottomMargin = (todayDashboardStartTime === "01:00") ? 0 : 1

    // End of calendar margin 
    const topMargin = (todayDashboardEndTime === "00:00") ? 0 : 1

    const { adjustedStartTime, adjustedEndTime } = getAdjustedTimes(bottomMargin, topMargin, todayDashboardStartTime, todayDashboardEndTime);

    const currentCalendarDate = formatCalendarDateForInput(calendarDate);


    // Visits where assigned worker is Currently logged user
    let visitsForCurrentUser = [];

    if (visitsInMonth && visitsInMonth.length > 0 &&
        currentUser && currentUser.id !== undefined) {

        visitsForCurrentUser = visitsInMonth.filter(
            (visit) =>
                visit.visit_assigned_worker_id === currentUser.id &&
                String(visit.visit_date) === String(currentCalendarDate)
        );
    }


    // This function is for adding maring HOURS for calendar meaning 
    // If we have a 08:00 - 16:00 time in todaysWorkingHours, we want to add for example '1' hour margin 
    // So we will have 7:00 - 17:00 
    function getAdjustedTimes(bottomMargin, topMargin, todayDashboardStartTime, todayDashboardEndTime) {


        const parseTimeToDate = (timeStr) => {
            const [hours, minutes] = timeStr.split(':').map(Number);
            const date = new Date();
            date.setHours(hours, minutes, 0, 0); // Set the time
            return date;
        };

        const formatTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        // Parse the start and end times
        let startTimeDate = parseTimeToDate(todayDashboardStartTime);
        let endTimeDate = parseTimeToDate(todayDashboardEndTime);

        // Adjust the start time by the bottom margin, unless it's 23:00
        if (todayDashboardStartTime !== "23:00") {
            startTimeDate.setHours(startTimeDate.getHours() - bottomMargin);
        }

        // Adjust the end time by the top margin, unless it's 00:00
        if (todayDashboardEndTime !== "00:00") {
            endTimeDate.setHours(endTimeDate.getHours() + topMargin);
        }

        // Format the adjusted times back to strings
        let adjustedStartTime = formatTime(startTimeDate);
        let adjustedEndTime = formatTime(endTimeDate);

        // Special handling for adjusted times
        if (adjustedStartTime === "00:00") {
            adjustedStartTime = "23:00";
        }
        if (adjustedEndTime === "00:30") {
            adjustedEndTime = "00:00";
        }

        return { adjustedStartTime, adjustedEndTime };
    }



    return (
        // Right side MAIN CALENDAR
        // We want to add Column for every user
        // We manualy add first left column that has time in it

        <div ref={CalendarRightRef} className={`${classes.CalendarRight}`}>


            {/* This is calendar HEADER  */}
            <div className={classes.CalendarRightUsersDescription}>


                <div style={{ width: "50px", flex: "unset", minWidth: "50px" }} className={classes.UserDescription}>
                    <p className={"SmallText text-bold " + classes.UserDescriptionText}> {t("Calendar.time", "Time")} </p>
                </div>


                {/* Display all guest description (Top of column ) */}
                {(dashboardUsers && dashboardUsers.length > 0) &&
                    dashboardUsers.map((guest, idx) => {


                        let pathToImage = (guest && guest.avatar) ? path_to_avatars + guest.avatar : ""

                        // Today working hours of guest like '[08:00, 16:00]' (splited by 'x;)
                        const todayWorkingHours = (guest.working_hours[currentWeekday]) && (guest.working_hours[currentWeekday].split('x'))

                        return (

                            <div className={classes.UserDescription} key={`${guest.username}-${idx}-calendar-description`}>

                                <WrapperFlexRow style={{ padding: 0, alignItems: "center", justifyContent: "center" }}>

                                    <img src={pathToImage} alt="" />

                                    <WrapperFlexCol style={{ padding: 0, gap: "0", width: "unset", justifyContent: "center" }}>
                                        <p className={"SmallText text-bold text-capitalize " + classes.UserDescriptionText}> {guest.username} </p>
                                        {(todayWorkingHours[0] === 'close' || todayWorkingHours[1] === 'close') ?
                                            <p className={'SmallerText text_light_bold ' + classes.UserDescriptionText}> {t("Calendar.user_not_work", "Not working in this day")} </p>
                                            :
                                            <p className={'SmallerText text_light_bold ' + classes.UserDescriptionText}> {todayWorkingHours[0]} - {todayWorkingHours[1]} </p>
                                        }
                                    </WrapperFlexCol>

                                </WrapperFlexRow>

                            </div>
                        )
                    })
                }

            </div>

            {/* Time column */}
            <WrapperFlexRow className={classes.CalendarRightColumns}>


                {/* Render Calendar Pointer  */}
                {(isCalendarDateSetToToday) && <CalendarCurrentTimePointer calendarRowHeight={calendarRowHeight} CurrentTimePointerRef={CurrentTimePointerRef} adjustedStartTime={adjustedStartTime} adjustedEndTime={adjustedEndTime} />}


                {/* Single Time span column  */}
                <CalendarTimeColumn calendarRowHeight={calendarRowHeight} todayDashboardStartTime={adjustedStartTime} dashboardTimeSlotStep={dashboardTimeSlotStep} todayDashboardEndTime={adjustedEndTime} />


                {/* Current user column  */}
                <CalendarUserColumn columnUserOwnerId={currentUser.id} setSpecifiedDayRowVisit={setSpecifiedDayRowVisit} setShowAddVisitPopup={setShowAddVisitPopup} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} setVisitToEdit={setVisitToEdit} setShowEditVisitPopup={setShowEditVisitPopup} setVisitToDelete={setVisitToDelete} setShowDeleteVisitPopup={setShowDeleteVisitPopup} topMargin={topMargin} bottomMargin={bottomMargin} timeSpan={dashboardTimeSlotStep} calendarRowHeight={calendarRowHeight} calendarRowPadding={calendarRowPadding} visits={visitsForCurrentUser} todayDashboardStartTime={adjustedStartTime} dashboardTimeSlotStep={dashboardTimeSlotStep} todayDashboardEndTime={adjustedEndTime} />


                {/* Guests columns  */}
                {(dashboardUsers && dashboardUsers.length > 0) &&
                    dashboardUsers.map((guest, idx) => {

                        // Dont display US, current user is already displayed as FIRST column 
                        if (guest.id === currentUser.id) {
                            return null;
                        }

                        let visitsForGuest = []

                        if (visitsInMonth && visitsInMonth.length > 0) {
                            visitsForGuest = visitsInMonth.filter(visit =>
                                visit.visit_assigned_worker_id === guest.id &&
                                visit.visit_date === currentCalendarDate
                            );
                        }


                        return (
                            <React.Fragment key={`${guest.username}-${idx}-calendar`}>
                                <CalendarUserColumn columnUserOwnerId={guest.id} setSpecifiedDayRowVisit={setSpecifiedDayRowVisit} setShowAddVisitPopup={setShowAddVisitPopup} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} setVisitToEdit={setVisitToEdit} setShowEditVisitPopup={setShowEditVisitPopup} setVisitToDelete={setVisitToDelete} setShowDeleteVisitPopup={setShowDeleteVisitPopup} topMargin={topMargin} bottomMargin={bottomMargin} timeSpan={dashboardTimeSlotStep} calendarRowHeight={calendarRowHeight} visits={visitsForGuest} calendarRowPadding={calendarRowPadding} todayDashboardStartTime={adjustedStartTime} dashboardTimeSlotStep={dashboardTimeSlotStep} todayDashboardEndTime={adjustedEndTime} />
                            </React.Fragment>
                        )
                    })
                }


            </WrapperFlexRow>
        </div>
    )
}



// CALENDAR: DAY 
// This displays LEFT SIDE of calendar column with time 
export function CalendarTimeColumn({ todayDashboardStartTime, todayDashboardEndTime, dashboardTimeSlotStep }) {


    // This is needed so our calendar will show up correctly
    // Without this any dashboard that starts at etc 7:00 until 23:00 + 1h margin (24:00) will set todayDashboardEndTime to "00:00" wchich will give error
    // We want todayDashboardEndTime set at "24:00" (this only happend when) todayDashboardEndTime is set at 23:00 with margin + 1
    if (todayDashboardEndTime === "00:00") todayDashboardEndTime = "24:00"

    const timeSlots = createTimeSlots(todayDashboardStartTime, todayDashboardEndTime, dashboardTimeSlotStep);

    return (

        <WrapperFlexCol className={classes.CalendarTimeColumn}>

            {timeSlots.map((time, idx) => {
                return (
                    <div className={`${classes.CalendarTimeBox} ${(idx === 0) && classes.CalendarFirstRow} `} key={`time-${time}-idx-${idx}`}>
                        <p className='SmallText text-bold text-gray'> {time} </p>
                    </div>
                )
            })}

        </WrapperFlexCol>

    )
}



// CALENDAR: DAY 
// This displays User visits in a column 
export function CalendarUserColumn({ columnUserOwnerId, setSpecifiedDayRowVisit, toggleVisitIsConfirmedByUser, setShowAddVisitPopup, setVisitToEdit, setShowEditVisitPopup, setShowDeleteVisitPopup, setVisitToDelete, bottomMargin, topMargin, calendarRowPadding, timeSpan, calendarRowHeight, todayDashboardStartTime, todayDashboardEndTime, dashboardTimeSlotStep, visits }) {

    const { t } = useTranslation()
    const { moveToPathInsideDashboard, dashboardHash } = useDashboardContext()
    const { priceListById, dashboardCurrency } = useSharedDashboardContext()


    // This is needed so our calendar will show up correctly
    // Without this any dashboard that starts at etc 7:00 until 23:00 + 1h margin (24:00) will set todayDashboardEndTime to "00:00" wchich will give error
    // We want todayDashboardEndTime set at "24:00" (this only happend when) todayDashboardEndTime is set at 23:00 with margin + 1

    if (todayDashboardEndTime === "00:00") todayDashboardEndTime = "24:00"

    function editVisit(visit) {
        console.log("Edit visit", visit)
        setVisitToEdit(visit)
        setShowEditVisitPopup(true)
    }

    function hasVisitEnded(visit) {
        // Create a date object from visit_date and set time using start_hour and start_minute
        const visitDateTime = new Date(visit.visit_date);
        visitDateTime.setHours(parseInt(visit.start_hour), parseInt(visit.start_minute), 0, 0);

        // Calculate the end time of the visit
        const length = parseInt(visit.length);
        const endDateTime = new Date(visitDateTime.getTime() + length * 60000); // 60000 milliseconds in a minute

        // Get the current date and time
        const currentDateTime = new Date();

        // Compare the current date and time with the end date and time of the visit
        return currentDateTime > endDateTime;
    }

    function handleVisitToDelete(visit) {
        // We are passing more details about visit to Delete popup
        // Currently this is my best idea to pass this data, without copying this function to delete popup right 
        visit.visitTimeAsString = formatTimeRange(visit.start_hour, visit.start_minute, visit.length)
        setVisitToDelete(visit)
        setShowDeleteVisitPopup(true)
    }

    // Here we have some components that will render our visit 
    // We do this because we have different layour for different visits 
    // OneRowVisit | 15 min visit has height of 34px so its small 
    // TwoRowVisit | 30 min visit has height of 84 px 
    // ThreeRowPlusVisit | 45 min visit has enought of space to do everything else


    function OneRowVisit({ visit, visitIdx, priceListObject }) {
        const opacity = hasVisitEnded(visit) ? 0.5 : 1.0;

        return (
            <div className={`${classes.VisitBoxContent} ${classes.OneRowVisit}`} style={{ borderLeft: `4px solid ${visit.visit_accent_color}`, opacity: opacity }}>
                <WrapperFlexCol style={{ padding: "0", gap: "0rem", height: "unset", width: "unset", overflow: "hidden" }}>

                    {/* Time and tag  */}
                    <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", height: "unset", width: "100%" }}>
                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem" }}>
                            <div className={classes.VisitAcceptBox}> <input id={`visit-${visitIdx}`} type="checkbox" checked={visit.isCheckedByOwner} onChange={() => { toggleVisitIsConfirmedByUser(visit) }} /></div>
                            <p className={`${classes.visitText} SmallerText`}> {formatTimeRange(visit.start_hour, visit.start_minute, visit.length)}</p>
                        </WrapperFlexRow>

                        {/* Assign tag  */}
                        {(priceListObject) &&
                            <PriceListTag bg={priceListObject.color} text={priceListObject.tag} size={"small"} />
                        }
                    </WrapperFlexRow>

                    {/* Client name and price  */}
                    <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", width: "unset", height: "unset", alignItems: "flex-end" }}>
                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${visit.visit_client_id}`)} className="MicroText text-bold text_overflow_hidden as_link">
                            {visit.visit_client.name + " " + visit.visit_client.lastname}
                        </p>
                        <p className={`${classes.visitText} MicroText text-bold HideOnBigTablet`}>  {visit.price} {(dashboardCurrency.Symbol) || ""}  </p>
                    </WrapperFlexRow>

                </WrapperFlexCol>

                <WrapperFlexRow style={{ padding: "0", gap: "0", height: "unset", width: "unset", justifyContent: "flex-end", alignSelf: "center" }}>
                    <p className={classes.VisitEditWrapper} onClick={() => editVisit(visit)}> <EditVisitIcon title={`Edit visit`} className={"SVG_STROKE"} /> </p>
                    <p className={classes.VisitEditWrapper} onClick={() => handleVisitToDelete(visit)}> <DeleteIcon title={`Delete visit`} className={"SVG_STROKE"} /> </p>
                </WrapperFlexRow>
            </div>
        )
    }


    function TwoRowVisit({ visit, visitIdx, priceListObject }) {
        const opacity = hasVisitEnded(visit) ? 0.5 : 1.0;

        return (
            <div className={`${classes.VisitBoxContent} ${classes.TwoRowVisit}`} style={{ borderLeft: `4px solid ${visit.visit_accent_color}`, opacity: opacity }}>
                <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", height: "unset" }}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0rem", height: "unset" }}>

                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", height: "unset", width: "100%", justifyContent: "space-between" }}>
                            <WrapperFlexRow style={{ padding: "0" }}>
                                <div className={classes.VisitAcceptBox}> <input id={visitIdx} type="checkbox" checked={visit.isCheckedByOwner} onChange={() => { toggleVisitIsConfirmedByUser(visit) }} /></div>
                                <p className={`${classes.visitText} SmallText`}> {formatTimeRange(visit.start_hour, visit.start_minute, visit.length)}</p>
                            </WrapperFlexRow>

                            {/* Assign tag  */}
                            {(priceListObject) &&
                                <WrapperFlexRow style={{ padding: "0", width: "unset" }}>
                                    <PriceListTag bg={priceListObject.color} text={priceListObject.tag} size={"small"} />
                                </WrapperFlexRow>
                            }
                        </WrapperFlexRow>
                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${visit.visit_client_id}`)} className="SmallText text-bold text_overflow_hidden as_link">
                            {visit.visit_client.name + " " + visit.visit_client.lastname}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexRow className={"HideScrollbar"} style={{ padding: "0", gap: "0", height: "unset", width: "unset", maxHeight: "20px", overflowY: "scroll", alignItems: "flex-start" }}>
                        <p className='SmallText' style={{ lineHeight: "1" }}>{visit.description}</p>
                    </WrapperFlexRow>
                </WrapperFlexCol>

                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", height: "unset", width: "100%", justifyContent: "space-between", alignItems: "flex-end", alignSelf: "flex-end" }}>
                    <p className={`${classes.visitText} DefaultText text-bold`}>  {visit.price} {(dashboardCurrency.Symbol) || ""} </p>
                    <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", width: "unset", height: "unset", justifyContent: "flex-end", alignItems: "flex-end", alignSelf: "flex-end" }}>
                        <p className={classes.VisitEditWrapper} onClick={() => editVisit(visit)}> <EditVisitIcon title={`Edit visit`} className={"SVG_STROKE"} /> </p>
                        <p className={classes.VisitEditWrapper} onClick={() => handleVisitToDelete(visit)}> <DeleteIcon title={`Delete visit`} className={"SVG_STROKE"} /> </p>
                    </WrapperFlexRow>
                </WrapperFlexRow>
            </div>
        )
    }


    function ThreeRowPlusVisit({ visit, visitIdx, priceListObject }) {
        const opacity = hasVisitEnded(visit) ? 0.5 : 1.0;

        return (
            <div className={`${classes.VisitBoxContent} ${classes.ThreeRowPlusVisit}`} style={{ borderLeft: `4px solid ${visit.visit_accent_color}`, opacity: opacity }}>
                <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", height: "unset" }}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0rem", height: "unset" }}>

                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", height: "unset", width: "100%", justifyContent: "space-between" }}>
                            <WrapperFlexRow style={{ padding: "0" }}>
                                <div className={classes.VisitAcceptBox}> <input id={`visit-${visitIdx}`} type="checkbox" checked={visit.isCheckedByOwner} onChange={() => { toggleVisitIsConfirmedByUser(visit) }} /></div>
                                <p className={`${classes.visitText} SmallText`}> {formatTimeRange(visit.start_hour, visit.start_minute, visit.length)}</p>
                            </WrapperFlexRow>

                            {/* Assign tag  */}
                            {(priceListObject) &&
                                <WrapperFlexRow style={{ padding: "0", width: "unset" }}>
                                    <PriceListTag bg={priceListObject.color} text={priceListObject.tag} size={"small"} />
                                </WrapperFlexRow>
                            }
                        </WrapperFlexRow>
                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${visit.visit_client_id}`)} className="SmallText text-bold text_overflow_hidden as_link">
                            {visit.visit_client.name + " " + visit.visit_client.lastname}
                        </p>
                    </WrapperFlexCol>

                    <WrapperFlexRow className={"HideScrollbar"} style={{ padding: "0", gap: "0", height: "unset", width: "unset", maxHeight: "40px", overflowY: "scroll", alignItems: "flex-start" }}>
                        <p className='SmallText' style={{ lineHeight: "1" }}>{visit.description}</p>
                    </WrapperFlexRow>
                </WrapperFlexCol>

                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", height: "unset", width: "100%", justifyContent: "space-between", alignItems: "flex-end", alignSelf: "flex-end" }}>
                    <p className={`${classes.visitText} DefaultText text-bold`}>  {visit.price} {(dashboardCurrency.Symbol) || ""}  </p>
                    <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", width: "unset", height: "unset", justifyContent: "flex-end", alignItems: "flex-end", alignSelf: "flex-end" }}>
                        <p className={classes.VisitEditWrapper} onClick={() => editVisit(visit)}> <EditVisitIcon title={`Edit visit`} className={"SVG_STROKE"} /> </p>
                        <p className={classes.VisitEditWrapper} onClick={() => handleVisitToDelete(visit)}> <DeleteIcon title={`Delete visit`} className={"SVG_STROKE"} /> </p>
                    </WrapperFlexRow>
                </WrapperFlexRow>
            </div>
        )
    }


    function RenderProperRow({ howManyRowsTaken, visit, visitIdx }) {

        let priceListObject = (visit.visit_assigned_to_price_list_id !== null && priceListById[visit.visit_assigned_to_price_list_id]) ? priceListById[visit.visit_assigned_to_price_list_id] : null

        if (howManyRowsTaken === 1) {
            return (
                <OneRowVisit visit={visit} visitIdx={visitIdx} priceListObject={priceListObject} />
            )
        }
        else if (howManyRowsTaken === 2) {
            return (
                <TwoRowVisit visit={visit} visitIdx={visitIdx} priceListObject={priceListObject} />
            )
        }
        if (howManyRowsTaken >= 3) {
            return (
                <ThreeRowPlusVisit visit={visit} visitIdx={visitIdx} priceListObject={priceListObject} />
            )
        }

    }


    // It is triggered while hovering over some row in timeSpan = 'day'
    // time is variable that holds row time for example "02:00"
    function handleAddVisitForSelectedRow(selectedTime) {
        setSpecifiedDayRowVisit({
            selectedTime: selectedTime,
            selectedUser: columnUserOwnerId
        })
        setShowAddVisitPopup(true)
    }


    const timeSlots = createTimeSlots(todayDashboardStartTime, todayDashboardEndTime, dashboardTimeSlotStep);

    // This is currently the most optimized way to display visits
    // we create dictionary like 
    // "7:15": [visit1, visit2]... 
    // So we dont do this n times while rendering stuff 
    const timeVisitsDictionary = timeSlots.reduce((result, time) => {
        const [hour, minute] = time.split(':').map(Number);

        // Find the corresponding visits in the visitsArray
        const matchingVisits = visits.filter(visit =>
            visit.start_hour === hour && visit.start_minute === minute
        );

        // If matching visits are found, add them to the result object
        if (matchingVisits.length > 0) {
            const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
            result[formattedTime] = matchingVisits;
        }

        return result;
    }, {});

    // This holds the ammount of MARGIN rows we need to add at TOP or BOTTOM 
    // IF we have calendarTimeMargin at 1 we * by 60 (60 minutes) and divide by timeSpan 
    // So we will have 4 rows (we want 4 rows before and after actual calendar)
    // const howManyMarginRows = parseInt(calendarTimeMargin * 60 / timeSpan)
    const howManyBottomRows = parseInt(bottomMargin * 60 / timeSpan)
    const howManyUpperRows = parseInt(topMargin * 60 / timeSpan)

    // Helper that holds length of timeSlots 
    let timeSlotsLength = timeSlots.length;

    return (

        <div className={classes.CalendarUserVisitsColumn}>


            {timeSlots.map((time, idx) => {
                const matchingVisits = (timeVisitsDictionary[time]) ? timeVisitsDictionary[time] : [];

                return (
                    <div
                        className={`${classes.CalendarVisitRow} ${(idx < howManyBottomRows || idx >= timeSlotsLength - howManyUpperRows) ? classes.CalendarVisitRowMargin : ""} ${(matchingVisits.length > 2) ? classes.TooManyVisits : ""}`}
                        key={`time-${time}-idx-${idx}`}
                    >

                        {/* Add visit on hover (for selected row) */}
                        <WrapperFlexRow style={{ padding: "0" }} className={classes.AddVisitBtn_Container}>
                            <p onClick={() => handleAddVisitForSelectedRow(time)} className={'SmallerText text_light_bold ' + classes.AddVisitBtn_Day}>
                                {/* {t("Common.add", "Add")} */}
                                <PlusIcon className={"SVG_STROKE"} />
                            </p>
                        </WrapperFlexRow>

                        {matchingVisits.length > 0 &&

                            matchingVisits.map((visit, visitIdx) => {

                                const howManyRowsTaken = Math.ceil(visit.length / timeSpan)
                                const maxHeight = calendarRowHeight * ((howManyRowsTaken > 1) ? howManyRowsTaken : 1) - calendarRowPadding
                                const visitCheckBoxIdx = `visit-${idx}x${visitIdx}x${visit.id}`;


                                return (
                                    <div
                                        key={`visit-${visitIdx}`}
                                        className={`HideScrollbar ${classes.VisitBox} ${(matchingVisits.length >= 3) ? classes.TooManyVisits : ""}`}
                                        style={{ height: `calc(${String(maxHeight)}px` }}>



                                        {/* {visit.visit_date} */}
                                        <RenderProperRow howManyRowsTaken={howManyRowsTaken} visit={visit} visitIdx={visitCheckBoxIdx} />


                                    </div>
                                )

                            })
                        }
                    </div>
                )
            })}


        </div>


    )
}



// CALENDAR: MONTH
function CalendarMonth({ toggleVisitIsConfirmedByUser, setShowAddVisitPopup, handleTimeSpan, setVisitToDelete, setShowDeleteVisitPopup, setVisitToEdit, setShowEditVisitPopup, t, visitsInMonth }) {

    const { dashboardUsersAsDictionary, moveToPathInsideDashboard, dashboardHash, currentDashboardOwnerData, dashboardGuests } = useDashboardContext()
    const { priceListById, calendarDate, setCalendarDate, dashboardCurrency } = useSharedDashboardContext()

    // console.log(visitsInMonth)
    const daysInCurrentMonth = getDaysInMonth(calendarDate.year, calendarDate.month);
    const daysInCurrentMonth_length = daysInCurrentMonth.length
    const howManyRowsNeeded = (daysInCurrentMonth_length > 35) ? 6 : 5; // 35 because we need to check if we are using 5 rows or 6 (5 (rows) * 7 (days))

    // THis is made, because we want to gather all USERS connected to dashboard
    // to find later their profile pictures and assign their pictures to the visit 
    const dashboardUsersCombined = [...dashboardGuests]
    dashboardUsersCombined.push(currentDashboardOwnerData)

    // console.log(dashboardUsersAsDictionary)

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
    const currentDayOfMonth = String(today.getDate()).padStart(2, '0');

    // Holds '2024-01-20' etc.
    const currentDateToday = `${currentYear}-${currentMonth}-${currentDayOfMonth}`;

    // this is a helper, it needs to go from -1 
    let currentDatePointer = -1;

    // This was made, so user can click on a button to change from month to day with selected day
    // We only update currentDate.day because user cannot change to other month from this view 
    function openMonthAsDay(currentDay) {
        handleTimeSpan('day')
        setCalendarDate({
            ...calendarDate,
            day: currentDay
        })
    }

    function handleVisitToDelete(visit) {
        setVisitToDelete(visit)
        setShowDeleteVisitPopup(true)
    }

    function editVisit(visit) {
        // console.log("Edit visit", visit)
        setVisitToEdit(visit)
        setShowEditVisitPopup(true)
    }

    function handleAddVisitPopup(currentDay) {
        // console.log(currentDay)
        setCalendarDate({
            ...calendarDate,
            day: parseInt(currentDay)
        })
        setShowAddVisitPopup(true)
    }



    // For now, this is the most optimized way of getting grouped visits to 
    // display later in visitBoxes 
    const groupedVisitsByDate = visitsInMonth.reduce((result, visit) => {
        const dateKey = visit.visit_date;

        if (!result[dateKey]) {
            result[dateKey] = [visit];
        } else {
            result[dateKey].push(visit);
        }

        // Add visit_timeRange property to each visit
        // Meaning, add 8:00-12:00 or any .. like that 
        visit.visit_timeRange = formatTimeRange(visit.start_hour, visit.start_minute, visit.length);

        return result;
    }, {});

    // Sort each array of visits within a grouped date by start_hour and start_minute
    // So we have visits in current date by order (hour / minute) 12:00 -> 13:00 -> 14:00.. instead of 13:00 -> 12:00.. 
    for (const dateKey in groupedVisitsByDate) {
        groupedVisitsByDate[dateKey].sort((a, b) => {
            const timeA = a.start_hour * 60 + a.start_minute;
            const timeB = b.start_hour * 60 + b.start_minute;
            return timeA - timeB;
        });
    }

    // This color is used for showing ammount of visits with some kind of opacity
    // More visits in a day = more opacity in that color 
    const primaryColorRGB = "56, 118, 253";

    return (
        <div className={`${classes.CalendarMonth}`}>

            <div className={classes.CalendarDaysRowWrapper}>

                {/* Header  */}
                <div className={classes.CalendarMonthHeader}>
                    {valid_work_days.map((day, idx) => {
                        return (
                            <div key={`day-${day}x${idx}`} className={classes.CalendarMonthWrapper}>
                                <p className='SmallerText text-bold'> {translateDayOfWeek(day.toLowerCase(), t)} </p>
                            </div>
                        )
                    })}
                </div>


                {/* Render 'howManyRowsNeeded' rows for calendar */}
                {/* There might be a month that needs 4 rows and might be 5 or  6 also  */}
                {Array(howManyRowsNeeded).fill().map((_, index) => {

                    return (
                        <div key={index} className={classes.CalendarDaysRow}>

                            {/* Render 7 columns for row */}
                            {Array(7).fill().map((_, innerIndex) => {

                                currentDatePointer += 1;

                                const currentDay = daysInCurrentMonth[currentDatePointer];
                                const currentDate = `${calendarDate.year}-${String(calendarDate.month).padStart(2, '0')}-${String(currentDay).padStart(2, '0')}`;

                                // console.log(currentDate)

                                // Conditionally render CalendarVisitsBox only if currentDay is not '0' or undefined
                                if (String(currentDay) !== '0' && currentDay !== undefined) {

                                    let howManyVisits = (groupedVisitsByDate[currentDate]) ? groupedVisitsByDate[currentDate].length : 0

                                    // Helper, this will calculate opacity so we can display day with more visits with more color 
                                    // default is 0.25 opacity of primary color 
                                    let opacity = (howManyVisits === 0) ? 0.25 : 0.25 + (10 * parseFloat(howManyVisits / 100))


                                    return (
                                        <div className={`${classes.CalendarVisitsBox} ${(currentDate === currentDateToday) ? classes.currentDayHeaderIsToday : ""} ${(index + 1 === howManyRowsNeeded ? classes.lastRowBorderBottom : "")}`} key={innerIndex}>

                                            {/* Header of visit box (date and visit count) */}
                                            <div className={`${classes.currentDayHeader}`}>


                                                <WrapperFlexRow style={{ padding: "0", width: "unset", gap: '0.5rem' }}>
                                                    <p className={`DefaultText text-bold`}> {currentDay} </p>
                                                    <p onClick={() => { openMonthAsDay(currentDay) }} className={'SmallerText text_overflow_hidden text_light_bold ' + classes.SwitchToDay}> {t("Calendar.open_as_day", "Open day")} </p>
                                                </WrapperFlexRow>

                                                <WrapperFlexRow style={{ padding: "0", width: "unset", gap: '0.25rem' }}>
                                                    <p onClick={() => handleAddVisitPopup(currentDay)} className={'SmallerText text_light_bold ' + classes.AddVisitBtn}> {t("Common.add", "Add")}</p>
                                                    {(howManyVisits > 0) &&
                                                        <p className={`SmallText ${classes.howManyVisits}`} style={{ backgroundColor: `rgba(${primaryColorRGB}, ${opacity})` }}>
                                                            {howManyVisits}
                                                        </p>
                                                    }
                                                </WrapperFlexRow>


                                            </div>


                                            {/* Map over visits for the current date */}
                                            <div className={`${classes.CalendarVisitWrapper} HideScrollbar `}>

                                                {/* Render content for each visit */}
                                                {groupedVisitsByDate[currentDate] && groupedVisitsByDate[currentDate].map((visit, visitIndex) => {

                                                    // Get price list assigned to this visit by id 
                                                    let priceListObject = (visit.visit_assigned_to_price_list_id !== null && priceListById[visit.visit_assigned_to_price_list_id]) ? priceListById[visit.visit_assigned_to_price_list_id] : null

                                                    // This holds full path to assigned user to visit avatar 
                                                    let pathToUserAvatar = (dashboardUsersAsDictionary[visit.visit_assigned_worker_id]) ? path_to_avatars + dashboardUsersAsDictionary[visit.visit_assigned_worker_id].avatar : ""

                                                    return (

                                                        <div key={`visit-${visit.id}-${visitIndex}`} style={{ borderLeft: `4px solid ${visit.visit_accent_color}` }} className={`${classes.CalendarVisit}`}>

                                                            {/* Visit checkbox + time  */}
                                                            <WrapperFlexRow style={{ padding: "0", gap: "0rem", height: "unset", width: "unset", justifyContent: "space-between" }}>
                                                                <WrapperFlexCol style={{ minWidth: "unset", overflow: "hidden", padding: "0", gap: "0rem" }}>

                                                                    {/* Assign tag  */}
                                                                    {(priceListObject) &&
                                                                        <WrapperFlexRow style={{ padding: "0", marginTop: "0.15rem" }}>
                                                                            <PriceListTag bg={priceListObject.color} text={priceListObject.tag} size={"small"} />
                                                                        </WrapperFlexRow>
                                                                    }

                                                                    <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", height: "unset", width: "unset", alignItems: "center", justifyContent: "space-between" }}>

                                                                        {/* User avatar  */}
                                                                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", alignItems: "center", overflow: "hidden" }}>
                                                                            <div className={classes.VisitToggleBox}> <input id={`visit-${visit.id}-${visitIndex}`} type="checkbox" checked={visit.isCheckedByOwner} onChange={() => { toggleVisitIsConfirmedByUser(visit) }} /></div>

                                                                            {pathToUserAvatar && <img className={classes.CalendarMonthUserAvatar} alt={`user avatar`} src={pathToUserAvatar} />}
                                                                            {(dashboardUsersAsDictionary[visit.visit_assigned_worker_id] && dashboardUsersAsDictionary[visit.visit_assigned_worker_id].username) && <p className='SmallText text-capitalize' style={{ maxWidth: "64px", overflow: "hidden" }}> {dashboardUsersAsDictionary[visit.visit_assigned_worker_id].username} </p>}


                                                                        </WrapperFlexRow>

                                                                        <p className="SmallText text_no_wrap" style={{ minWidth: "max-content" }}> {visit.visit_timeRange} </p>

                                                                    </WrapperFlexRow>

                                                                    {/* Visit client name and lastname  */}
                                                                    <WrapperFlexRow style={{ padding: "0", gap: "0.5rem", alignItems: "center", justifyContent: "space-between", marginBottom: "0.25rem" }}>
                                                                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${visit.visit_client_id}`)} className="SmallText text-bold as_link text_no_wrap">

                                                                            {visit.visit_client.name + " " + visit.visit_client.lastname}

                                                                        </p>

                                                                        {/* Visit price  */}
                                                                        <p className='SmallText' style={{ minWidth: "max-content" }}>
                                                                            {(visit && (visit.price || visit.price === 0)) ? visit.price : "-"} {(dashboardCurrency.Symbol) || ""}
                                                                        </p>
                                                                    </WrapperFlexRow>


                                                                    <WrapperFlexRow style={{ padding: "0", gap: "0rem", height: "unset", alignItems: "flex-end", width: "100%" }}>

                                                                        <p className='SmallerText' style={{ maxWidth: "150px", overflow: "hidden" }}> {(visit && visit.description) && visit.description}</p>


                                                                        <WrapperFlexRow style={{ padding: "0", paddingLeft: "0.5rem", gap: "0.1rem", height: "unset", justifyContent: "flex-end", flex: "1" }}>
                                                                            <p className={classes.VisitEditWrapper} onClick={() => editVisit(visit)}> <EditVisitIcon title={`Edit visit`} className={"SVG_STROKE"} /> </p>
                                                                            <p className={classes.VisitEditWrapper} onClick={() => handleVisitToDelete(visit)}> <DeleteIcon title={`Delete visit`} className={"SVG_STROKE"} /> </p>
                                                                        </WrapperFlexRow>

                                                                    </WrapperFlexRow>
                                                                </WrapperFlexCol>


                                                            </WrapperFlexRow>
                                                        </div>

                                                    )

                                                })}

                                            </div>


                                        </div>
                                    );

                                } else {
                                    // Render an empty div for days with '0' or undefined
                                    return <div className={`HideScrollbar ${classes.CalendarVisitsBox} ${(index + 1 === howManyRowsNeeded ? classes.lastRowBorderBottom : "")}`} key={innerIndex}></div>;
                                }
                            })}
                        </div>
                    );
                })}
            </div >

        </div >

    )
}



// POPUPS 

// Our element for displaying all price lists in add / edit popup
export function PriceListSelect({ selectedPriceListTag, setSelectedPriceList }) {

    const { t } = useTranslation();

    const [searchPriceList, setSearchPriceList] = useState("");

    const { sharedDashboardPriceLists, dashboardCurrency } = useSharedDashboardContext();


    // Sort the price lists by name
    // Put favourites on top
    const sortedPriceLists = sharedDashboardPriceLists
        .slice()
        .sort((a, b) => {
            // Sort by isFavourite first (true comes first)
            if (a.isFavourite !== b.isFavourite) {
                return b.isFavourite - a.isFavourite;
            }
            // Then, sort by name
            return a.name.localeCompare(b.name);
        });

    // Filter the price lists based on the search input
    const filteredPriceLists = sortedPriceLists.filter(
        (ele) => ele.name.toLowerCase().includes(searchPriceList.toLowerCase()) || ele.tag.toLowerCase().includes(searchPriceList.toLowerCase())
    );

    function handleSelectPriceList(ele) {
        setSelectedPriceList(ele);
    }

    const formatValue = (value, defaultText) => {
        return value !== null && value !== undefined ? `${value}` : defaultText;
    };

    return (
        <WrapperFlexCol style={{ padding: "0" }} className={classes.PriceListSelect}>

            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between", alignItems: "flex-end" }}>
                <LabelLeftSingleIcon LeftIcon={TagIcon} LabelText={t("Common.assign", "Assign")} LabelBold={t("Calendar.service", "service")} />
            </WrapperFlexRow>


            <SingleInput
                style={{ flex: "1" }}
                value={searchPriceList}
                onChange={(e) => setSearchPriceList(e.target.value)}
                label="price_list_name"
                placeholder={t("Calendar.search_price_list", "Search price list by name or tag")}
                maxLength={"16"}
            />


            <NormalBox style={{ padding: "0.5rem", maxHeight: "114px", overflowY: "auto", gap: "0", minWidth: "300px", minHeight: "114px" }} className={"BoxStyle RowborderRadius"}>


                {filteredPriceLists.map((ele, idx) => {
                    return (
                        <WrapperFlexCol style={{ gap: "0" }} onClick={() => handleSelectPriceList(ele)} className={`${classes.PriceListBox} ${(selectedPriceListTag && ele.tag === selectedPriceListTag.tag && ele.name === selectedPriceListTag.name) ? classes.SelectedPriceList : ""}`} key={`price-list-${idx}`}>

                            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>
                                <p className='SmallText text_light_bold' style={{ overflow: "hidden" }}> {idx + 1}. {ele.name} </p>
                                <WrapperFlexRow style={{ padding: "0", width: "unset" }}>
                                    {(ele.isFavourite) &&
                                        <HeartIcon className={classes.FavPriceListIcon} />
                                    }
                                    <PriceListTag bg={ele.color} text={ele.tag} />
                                </WrapperFlexRow>
                            </WrapperFlexRow>

                            <WrapperFlexRow style={{ padding: "0" }}>
                                <p className='MicroText text-gray' style={{ overflow: "hidden" }}>
                                    {formatValue(ele.length, "-")} min
                                </p>
                                <p className='MicroText text-gray' style={{ overflow: "hidden" }}>
                                    {formatValue(ele.price, "-")} {(dashboardCurrency.Symbol) || ""}
                                </p>
                            </WrapperFlexRow>

                        </WrapperFlexCol>
                    )
                })}


            </NormalBox>
        </WrapperFlexCol>
    )
}



// Add or Edit (by type variable) Popup on visit 
export function AddOrEditVisitPopup({ type = "Add", token, handleClose, visitToEdit, setVisitToEdit, specifiedDayRowVisit = null }) {

    // specifiedDayRowVisit - it is an object that is specified in handleAddVisitForSelectedRow() 
    /*  
    {
        selectedTime: str in format HH:MM like '02:00'
        selectedUser: int with user id (assigned to visit)
    }
    */
    // It is used from outside where user hovers over row in timeSpan = 'day' and clicks Add visit 


    const specifiedTime = (specifiedDayRowVisit) ? specifiedDayRowVisit.selectedTime.split(":") : null
    const specifiedUser = (specifiedDayRowVisit) ? specifiedDayRowVisit.selectedUser : null

    // console.log(specifiedTime, specifiedUser)

    const { t } = useTranslation();
    const { currentUser } = useUserContext()
    const { dashboardGuests, currentDashboard, dashboardUsersAsDictionary } = useDashboardContext()
    const { sharedDashboardPriceLists, priceListById, sharedClients, setSharedClients, setSharedVisits, calendarDate, latestAddedClient } = useSharedDashboardContext();

    const dashboardId = currentDashboard.id

    // Sort clients by last name
    const sortedClients = sharedClients.sort((a, b) => {
        const lastnameA = a.lastname.toLowerCase();
        const lastnameB = b.lastname.toLowerCase();
        return lastnameA.localeCompare(lastnameB);
    });

    // Format client data for a select box.
    // Here, we also check for the lenght of those clients 
    // If we have normal client like Tomek nowak, it is fine 
    // But if we spam client name or lastname like 'qwqweqweqweqweqwe', 'qsdojkcasoidjo qiwjdo iqwjdo iqwjd ' 
    // Then our select box get the width of this longgest client name / lastname and this will make our layout a bit messy 
    const clientsList = sortedClients.map((client, idx) => {
        // Perform security check for length
        let truncatedLastname = client.lastname;
        if (truncatedLastname.length > 14) {
            truncatedLastname = truncatedLastname.substring(0, 11) + "...";
        }

        let truncatedName = client.name;
        if (truncatedName.length > 14) {
            truncatedName = truncatedName.substring(0, 11) + "...";
        }

        return {
            value: client.id,
            label: `${idx + 1}. ${truncatedLastname} ${truncatedName}`,
        };
    });

    // variable 'type' is made because we dont want to copy paste this component 
    // We want to use him the same as in Edit with small changes
    // Type is default to 'Add' and can be also named 'Edit' only!

    const visitToEditId = (type === 'Add') ? -1 : visitToEdit.id   // Helper that can be accessed only if we edit visit

    function assign_proper_worker_id() {
        if (type === "Add") {
            if (specifiedUser) {
                return specifiedUser
            }
            return currentUser.id
        }
        else if (type === "Edit") {
            return (dashboardUsersAsDictionary[visitToEdit.visit_assigned_worker_id]) ? visitToEdit.visit_assigned_worker_id : currentUser.id
        }
        else {
            return currentUser.id
        }
    }


    const [visit, setVisit] = useState({
        // DATE "2023-12-24"
        visit_date: (type === 'Add') ? formatCalendarDateForInput(calendarDate) : visitToEdit.visit_date,

        // INT Will get current hour + 1 (so if it 12 i will return 13)
        // If type == "Add" 
        //      If specifiedTime exists assign to start_hour HH 
        //      else Assign to default 8 
        // else
        //      Assign to visitToEdit.start_hour 
        start_hour: (type === 'Add') ? (specifiedTime) ? specifiedTime[0] : 8 : visitToEdit.start_hour,

        // INT only allowed [0, 15, 30, 45]
        start_minute: (type === 'Add') ? (specifiedTime) ? specifiedTime[1] : 0 : visitToEdit.start_minute,

        // INT Default length of visit 
        length: (type === 'Add') ? 60 : visitToEdit.length,

        // INT of user id 
        visit_assigned_worker_id: assign_proper_worker_id(),

        // Finally int (When goes to db )
        visit_client_id: (type === 'Add') ? (sortedClients.length > 0) && sortedClients[0].id : visitToEdit.visit_client_id,

        // STRING optional
        description: (type === 'Add') ? "" : visitToEdit.description,

        // Default price (Float or Int possible)
        price: (type === 'Add') ? 100 : (!visitToEdit.price || visitToEdit.price === " ") ? 0 : visitToEdit.price,

        // Accent color in #xxxxxx (hex)
        visit_accent_color: (type === 'Add') ? visit_colors[0].color : visitToEdit.visit_accent_color,

        // Bool (is Visit finished)
        isFinished: (type === 'Add') ? false : visitToEdit.isFinished,

        // Bool (is Visit checked by owner)
        isCheckedByOwner: (type === 'Add') ? false : visitToEdit.isCheckedByOwner,

        // Owner of visit (so who creates the visit, what user id)
        visit_owner_id: (type === 'Add') ? currentUser.id : visitToEdit.visit_owner_id,

        // dashboard id 
        visit_belong_to_dashboard: dashboardId,

        // INT id to price list (that we connect to or null)
        visit_assigned_to_price_list_id: (type === 'Add') ? null : visitToEdit.visit_assigned_to_price_list_id

    })



    // This is our toggle so user can show / hide a div that holds all price lists services so he can select them!
    // Also, we store information ON or OFF if user toggled Assign to service BTN (so we can ON or OFF him by default)
    const latest_assign_to_service_action = getLocalStorageItem("latest_assign_to_service")
    const [assignService, setAssignService] = useState((visit.visit_assigned_to_price_list_id || (type === "Add" && latest_assign_to_service_action === "ON")) ? true : false)

    const storedDefaultPriceList = getObjectFromLocalStorage('default_price_list');

    // Default price list is price list from localstorage (if exists)
    function getDefaultPriceList(storedDefaultPriceList) {


        if (storedDefaultPriceList && priceListById[parseInt(storedDefaultPriceList.id)]) {
            return priceListById[parseInt(storedDefaultPriceList.id)];
        } else {
            // Clear the stored default_price_list_id if it doesn't exist in priceListById
            localStorage.removeItem('default_price_list');
            return null;
        }

    }

    // Usage:
    const defaultPriceList = getDefaultPriceList(storedDefaultPriceList);


    // We are getting tag (if added) here
    // This seems a bit cluncky but it is better to have all of this here in useState so we wont have any flashing (component updates) when using useEffect
    // This approach is better 
    const [selectedPriceListTag, setSelectedPriceList] = useState(() => {

        // Only for "add" set selectedPriceTag if user has default price list set and his last action was (assing service -> ON )
        if (latest_assign_to_service_action === "ON" && storedDefaultPriceList && priceListById[parseInt(storedDefaultPriceList.id)] && type === "Add") {
            return priceListById[parseInt(storedDefaultPriceList.id)];
        }

        if (visit.visit_assigned_to_price_list_id !== null) {
            // Find the selected price list in sharedDashboardPriceLists
            const selectedPriceList = sharedDashboardPriceLists.find(priceList => priceList.id === visit.visit_assigned_to_price_list_id);

            // Return the found price list or null if not found
            return selectedPriceList || null;
        } else {
            // If visit_assigned_to_price_list_id is null, set selectedPriceListTag to null
            return null;
        }
    });

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const [isLoading, setIsLoading] = useState(false);


    const timeIntervals = getVisitTimeIntervals(t)


    // This helps us so we can click toggle box that will show / hide name and lastname for new client if user wants to add visit to client that doesnt exists yet 
    const [addClientWithVisit, setAddClientWithVisit] = useState(false)
    const [client, setClient] = useState({
        dashboard_id: parseInt(dashboardId),
        client_owner: currentUser.id,
        latestVisitPrice: visit.price,
        name: "",
        lastname: ""
    })


    // Here we are updating visit when we select price list tag

    useEffect(() => {

        if (selectedPriceListTag) {

            setVisit((prevVisit) => {
                const updatedVisit = {
                    ...prevVisit,

                    // Update visit color to be equal to priceListTag
                    visit_accent_color: selectedPriceListTag.color,

                    // Update visit description to be equal to priceListTag
                    description: selectedPriceListTag.description || prevVisit.description,

                    // Update visit price to be equal to priceListTag
                    price: (selectedPriceListTag.price !== null && selectedPriceListTag.price !== undefined)
                        ? parseFloat(selectedPriceListTag.price)
                        : prevVisit.price,

                    // Update visit length to be equal to priceListTag
                    length: (selectedPriceListTag.length !== null && selectedPriceListTag.length !== undefined)
                        ? parseInt(selectedPriceListTag.length)
                        : prevVisit.length,


                    visit_assigned_to_price_list_id: selectedPriceListTag.id
                };

                return updatedVisit;
            });
        }

    }, [selectedPriceListTag]);

    // Generate a list of guest names for UI components.
    let guestNamesList = fillGuestNames();

    // Here we handling adding new client also checking for error 
    // We are calling it before adding visit, so we can create client, make sure that he exists and then we can get his id and assign him to visit 
    // We return [array of errors, newClient object, boolean if client already exists in our db]
    async function addClientBefore() {

        let errors = [];
        let newClient;

        // console.log("PRICE:", typeof client.latestVisitPrice, client.latestVisitPrice)

        if (!client.name || !client.lastname) {
            errors.push(t("validationErrors.enter_all_required_fields", "Enter values to all required fields!"))
        }

        // Important,
        // Here we check if client with this name and lastname already exists, if yes, then we dont even say that to the user
        // We just return him instead of adding him to the db. No point of that right
        // NOT YET DISCUSSED but we this might be actually missleading? 
        // WE might want to create two the same clients (edge case) but what then? I think user would rather name this client like "Marcel Milosz 1" instead of naming him the same? 
        const existingClient = sharedClients.find(
            (c) => c.name === client.name && c.lastname === client.lastname
        );

        if (existingClient && Object.keys(existingClient).length > 0) {
            console.log("Client already existed. New Client was not added to that visit..")
            return [[], existingClient, true]
        }


        const nameLastnameValidation = isNameLastnameValid(client.name, client.lastname, t)

        if (nameLastnameValidation.length > 0) errors = errors.concat(nameLastnameValidation)


        if (errors.length === 0) {

            const token = localStorage.getItem('access_token')

            if (token) {

                // Here we also modify client, before creating him. 
                // We want to set his default visit value (in Client) to visit price 
                let modifedClient = { ...client }
                modifedClient.latestVisitPrice = parseFloat(visit.price)

                setIsLoading(true)

                const response = await API_CreateNewClientByDashboard(token, modifedClient)

                let response_detail = (response && response.response_json) ? response.response_json.detail : null;

                if (response && response.isOk) {
                    newClient = response.new_item
                }
                else if (response && response.error_message) {
                    errors.push(response.error_message)
                }

                // We need this here because this stores message "Maximum number of clients exceeded ... "
                if (response && response_detail) {
                    errors.push(t("validationErrors.limit_error.for_clients", response_detail))
                }

                if (errors) {
                    setIsLoading(false)
                }
            }

        }

        // We return errors if any and also we return that client id 
        // If we added client successfuly then we can assign a visit for him 

        return [errors, newClient, false]

    }

    async function visitRequestController(e, action) {
        // This is created so we can make validation for both cases: Add and Edit 
        // action can be eiter "Add" or "Edit"

        e.preventDefault()

        let errors = []
        let newClient;
        let newClient_response;

        setIsSuccess(false);

        // Try to add new client before adding visit so we can get his id also 
        if (addClientWithVisit && type === 'Add') {
            newClient_response = await addClientBefore(e)


            if (newClient_response && newClient_response[0].length > 0) {
                errors.push(...newClient_response[0])
            }

            if (newClient_response && newClient_response[1]) {
                newClient = { ...newClient_response[1] }
            }
        }

        if (token && currentUser && currentUser.id && errors.length === 0) {
            let response;

            const visitWithNewClient = { ...visit }
            if (addClientWithVisit) visitWithNewClient.visit_client_id = newClient.id

            setIsLoading(true)

            if (action === "Add") response = await API_CreateNewVisit(token, visitWithNewClient)
            else if (action === "Edit") response = await API_EditVisit(token, visit, visitToEditId)
            else response = await API_CreateNewVisit(token, visitWithNewClient)


            if (response && response.isOk) {
                console.log("Success")

                setErrors([])
                setIsSuccess(true);


                // Remove Success box after 2 seconds 
                setTimeout(() => {
                    setIsSuccess(false);
                }, 2000); // 2 sec

                // Update state of clients by adding this new one to the state 
                // Without this, we will get errors because visits will refresh without refreshing clients at first 
                // We can also refresh all clients but its not efficient.. 

                // Important because this is a bit sick 
                // If we added new client correctly and visit, we need to update two states,
                // First is where we store all clients in array of objects
                // Seconds we have to update ClientsAsDisctionary where we store Objects with key as client id and value as client rest data
                if (addClientWithVisit && type === 'Add') {

                    // If client is actually new, meaning he did not exists in our db by name and last name 
                    if (!newClient_response[2]) {
                        // Create a new array with the updated client
                        const updatedDashboardClients = [...sharedClients, newClient];

                        // Set the state with the new array
                        setSharedClients(updatedDashboardClients);
                    }


                    // Clearing state of client
                    setClient({
                        name: "",
                        lastname: "",
                        latestVisitPrice: 0,
                        dashboard_id: dashboardId,
                        client_owner: currentUser.id
                    })
                }

                // Here we append / edit visits depending on popup type
                if (type === "Edit") {
                    // Edit existing visit in state
                    setSharedVisits(prevVisits => {
                        // Find the index of the visit to be updated
                        const index = prevVisits.findIndex(visit => visit.id === response.new_item.id);

                        if (index !== -1) {
                            // Replace the existing visit with the updated visit
                            const updatedVisits = [...prevVisits];
                            updatedVisits[index] = response.new_item;
                            return updatedVisits;
                        }

                        // If the visit is not found, return the current state
                        return prevVisits;
                    });
                }

                else if (type === "Add") {
                    setSharedVisits(prevVisits => [...prevVisits, response.new_item]);
                }

            }
            else if (response && response.error_message) {
                console.log("Error", response.error_message)
                errors.push(response.error_message)

                let res = response.response_json;

                if (res && res.detail && res.detail.includes('Limit_Error')) {
                    errors.push(t("validationErrors.limit_error.for_visits", "Another visit cannot be added because the limit of visits for the month has been reached. Go to Settings to check your dashboard limits.") + " (Limit_Error)")
                }
                if (res && res.description) {
                    errors.push(t("validationErrors.invalid_description", "The description can only contain numbers, letters and the symbols !@#$%&. Additionally, the description cannot exceed 512 characters.") + " (cv_cd01)")
                }
                if (res && res.start_minute) {
                    errors.push(t("validationErrors.invalid_start_minute", "Visit start minute must be 0, 15, 30, or 45."))
                }
                if (res && res.visit_date) {
                    errors.push(t("validationErrors.invalid_visit_date", "Visit date cannot be lower than 2010-01-01"))
                }
                if (res && res.price) {
                    errors.push(t("validationErrors.invalid_visit_price", "Wrong price format. It can only be number with optional two decimal points."))
                }
            }

            setIsLoading(false)
        }

        if (errors) {
            setErrors(errors)
        }

    }

    function handleClosePopup() {
        handleClose()
        setVisitToEdit(null)
    }


    // Finds single client by id and returns object of found client
    function findClientById(clientId) {
        if (sharedClients && Object.keys(sharedClients).length > 0) {
            return sharedClients.find(client => client.id === clientId);
        }
        else {
            return -1
        }

    }

    // When we change client in select box
    function handleClientChange(e) {
        let clientId = parseInt(e.target.value)
        let selectedClient = findClientById(clientId)

        // If we found selectedClient
        if (selectedClient !== -1) {
            setVisit({ ...visit, visit_client_id: selectedClient.id, price: selectedClient.latestVisitPrice })
        }
    }

    // When we click latest added client 
    function handleLatestClientAddedPickUp() {

        if (latestAddedClient && latestAddedClient.id && latestAddedClient.latestVisitPrice && visit.visit_client_id !== latestAddedClient.id) {
            setAddClientWithVisit(false);
            setVisit({ ...visit, visit_client_id: latestAddedClient.id, price: latestAddedClient.latestVisitPrice })
        }
    }


    function handlePriceChange(newPrice) {

        if (parseFloat(newPrice) > 10000) {
            setVisit({ ...visit, price: 10000 })
        }
        else {
            setVisit({ ...visit, price: newPrice })
        }
    }



    // Generate a list of guest names for select options.
    function fillGuestNames() {
        // Returns an array of objects in the format: [{ value: number, label: string }, ...].

        let guestNamesList = []

        // Add dashboard owner to the list
        // guestNamesList.push({
        //     value: currentDashboardOwnerData.id,
        //     label: currentDashboardOwnerData.username === currentUser.username ? `${t("Common.you", "You")}: ${capitalizeFirstLetter(currentDashboardOwnerData.username)}` : capitalizeFirstLetter(currentDashboardOwnerData.username)
        // });

        // Add "All" option
        // guestNamesList.push({
        //     value: -1,
        //     label: "All"
        // });

        // Concatenate the list of guests to the existing list
        guestNamesList = guestNamesList.concat(dashboardGuests.map(guest => ({
            value: guest.id,
            label: guest.username === currentUser.username ? `${t("Common.you", "You")}: ${capitalizeFirstLetter(guest.username)}` : capitalizeFirstLetter(guest.username)
        })));

        return guestNamesList

    }


    // Set default visit hour to 8:00 at start and if any change update that 
    const getTimeFromVisit = () => {

        if (visit.start_hour === 0) {
            return dayjs().set('hour', 8).set('minute', 0)
        }
        else {
            return dayjs().hour(visit.start_hour).minute(visit.start_minute);
        }

    };

    // Handle changes in TimePicker and update visit time.
    const handleTimeChange = (newValue) => {
        // We need this check, because user can actually delete time and here will be passed 'undefined' or 'null'
        if (newValue) {
            setVisit({
                ...visit,
                start_hour: newValue.hour(),
                start_minute: newValue.minute()
            });
        }
    };


    // Used in Timepicker to lock some hours! So user cannot add visit at 3 am 
    function shouldDisableTime(value, viewType) {
        // Check if the view type is for hours
        if (viewType === 'hours') {
            // Extract the hour from the value
            const hour = value.hour();
            // Disable hours from 23 to 1
            return hour >= 24 || hour < 1;
        }
        return false;
    }


    // A little handler so when we Click on Assing to service we will display the list and all 
    // But when we click again, we want to hide it and reset both states (second state that holds all price list information)
    // Also we want to reset all changes that selecting tag did, so we will restore all data to DEFAULT (starting values )
    function handleAssignToService(val) {

        if (val === true) {
            setAssignService(true)

            setLocalStorageItem("latest_assign_to_service", "ON")

            // Assign to locked default price - list
            // If exists and only in "add" mode
            if (defaultPriceList && type === "Add") {
                setSelectedPriceList(defaultPriceList)
            }
        }
        else {

            if (type === "Add") {
                setAssignService(false)
                setSelectedPriceList(null)
                resetVisitToDefault()

                setLocalStorageItem("latest_assign_to_service", "OFF")
            }
            else if (type === "Edit") {
                setAssignService(false)
                setSelectedPriceList(null)

                if (visit.visit_assigned_to_price_list_id) {
                    removeServiceAssignInEdit()
                }
            }

        }
    }


    function resetVisitToDefault() {
        setVisit({
            // DATE "2023-12-24"
            visit_date: (type === 'Add') ? formatCalendarDateForInput(calendarDate) : visitToEdit.visit_date,

            // INT Will get current hour + 1 (so if it 12 i will return 13)
            start_hour: (type === 'Add') ? 8 : visitToEdit.start_hour,

            // INT only allowed [0, 15, 30, 45]
            start_minute: (type === 'Add') ? 0 : visitToEdit.start_minute,

            // INT Default length of visit 
            length: (type === 'Add') ? 60 : visitToEdit.length,

            // INT of user id 
            visit_assigned_worker_id: (type === 'Add') ? currentUser.id : visitToEdit.visit_assigned_worker_id,

            // Finally int (When goes to db )
            visit_client_id: (type === 'Add') ? (sortedClients.length > 0) && sortedClients[0].id : visitToEdit.visit_client_id,

            // STRING optional
            description: (type === 'Add') ? "" : visitToEdit.description,

            // Default price (Float or Int possible)
            price: (type === 'Add') ? 100 : (!visitToEdit.price || visitToEdit.price === " ") ? 0 : visitToEdit.price,

            // Accent color in #xxxxxx (hex)
            visit_accent_color: (type === 'Add') ? visit_colors[0].color : visitToEdit.visit_accent_color,

            // Bool (is Visit finished)
            isFinished: (type === 'Add') ? false : visitToEdit.isFinished,

            // Bool (is Visit checked by owner)
            isCheckedByOwner: (type === 'Add') ? false : visitToEdit.isCheckedByOwner,

            // Owner of visit (so who creates the visit, what user id)
            visit_owner_id: (type === 'Add') ? currentUser.id : visitToEdit.visit_owner_id,

            // dashboard id 
            visit_belong_to_dashboard: dashboardId,

            // INT id to price list (that we connect to or null)
            visit_assigned_to_price_list_id: (type === 'Add') ? null : visitToEdit.visit_assigned_to_price_list_id

        })
    }

    // if we are on edit mode, we want to allow user to delete connection visit <-> service right 
    // he might decide that he does not want any connection with any price list 
    function removeServiceAssignInEdit() {
        setVisit((prev) => {
            return { ...prev, visit_assigned_to_price_list_id: null }
        });

        setSelectedPriceList(null)
    }



    return (


        <PopupLayout closePopup={handleClosePopup} popupTitle={(type === 'Add') ? t("Calendar.add_visit", "Add visit") : t("Calendar.edit_visit", "Edit visit")}>
            <WrapperFlexCol as_form={true} style={{ gap: "0.25rem", padding: "0" }} on_submit={(type === 'Add') ? (e) => visitRequestController(e, "Add") : (e) => visitRequestController(e, "Edit")}>

                {(sharedClients.length === 0 && !addClientWithVisit) && (
                    <DangerousWarrningContainer message={t("Calendar.warning_container", "You don't have any client added to your database.")} />
                )}

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={(type === 'Add') ? t("Calendar.success.new_visit", "New visit added") + "!" : t("Calendar.success.visit_updated", "Visit updated!") + "!"} />
                }

                {(sharedClients) ?
                    <>

                        {/* Select color  */}
                        <WrapperFlexRow style={{ padding: "0", gap: "1rem", alignItems: "center", justifyContent: "space-between" }}>


                            <DashboardColorPicker
                                colors={visit_colors}
                                selectedColor={visit.visit_accent_color}
                                onColorChange={(color) => setVisit({ ...visit, visit_accent_color: color })}
                            />

                            <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", width: "unset" }}>
                                <p className='SmallText text-gray text_no_wrap'> {t("Calendar.assing_service", "Assign to service")} </p>
                                <ToggleButton
                                    style={{ alignItems: "flex-end", height: "unset" }}
                                    onChange={() => handleAssignToService(!assignService)}
                                    isChecked={assignService}
                                    id={"AssingToService"}
                                />

                            </WrapperFlexRow>


                        </WrapperFlexRow>


                        {/* Assign to price list  */}
                        {((assignService === true && !selectedPriceListTag)) && <PriceListSelect setSelectedPriceList={setSelectedPriceList} selectedPriceListTag={selectedPriceListTag} />}


                        {(selectedPriceListTag) &&
                            <WrapperFlexRow style={{ padding: "0 0.5rem", marginTop: "0.75rem" }} className={"BoxStyle inputStyles"}>
                                <CloseIcon onClick={removeServiceAssignInEdit} className={classes.RemoveServiceAssingIcon} />
                                <WrapperFlexRow style={{ padding: "0.5rem", justifyContent: "space-between", gap: "0.25rem", minWidth: "unset", overflowX: "hidden" }} className={"NormalBox"}>
                                    <p className={"DefaultText text_light_bold text_overflow_hidden"}> {selectedPriceListTag.name} </p>
                                    <PriceListTag bg={selectedPriceListTag.color} text={selectedPriceListTag.tag} />
                                </WrapperFlexRow>

                            </WrapperFlexRow>
                        }


                        {/* Select Date  */}
                        <LabelLeftIconThreeBoldsTexts LeftIcon={CalendarIcon} LabelText={(type === 'Add') ? t("Common.select", "Select visit") : t("Common.edit", "Edit visit")} LabelBold1={t("Calendar.label.visit_date", "date")} LabelBold2={t("Calendar.label.visit_time", "visit time")} LabelBold3={t("Calendar.label.visit_length", "visit length")} LabelAfterBold={t("Calendar.visity", "")} />


                        {/* Select Time (MUI Time picker ) */}
                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", flexWrap: "wrap", width: "unset" }}>

                            <SingleInput
                                style={{ flex: "1" }}
                                value={(visit.visit_date)}
                                onChange={(e) => setVisit({ ...visit, visit_date: e.target.value })}
                                label="Visit date"
                                type="date"
                                disabled={false}
                                min={"1900-01-01"}
                            />

                            <div className={classes.MuTimeWrapper}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            value={getTimeFromVisit()}
                                            onChange={handleTimeChange}
                                            className={muStyles.mu_timeHourClock}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}
                                            minutesStep={15}
                                            ampm={false} // 24-hour format
                                            shouldDisableTime={shouldDisableTime}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>


                            {/* Select visit length */}
                            <MySelect
                                className="SelectBox inputHeight"
                                name="visit-length"
                                value={visit.length}
                                onChange={(e) => setVisit({ ...visit, length: parseInt(e.target.value) })}
                                options={timeIntervals}
                                style={{ flex: "1" }}
                            />


                        </WrapperFlexRow>

                        <WrapperFlexCol style={{ padding: "0", gap: "0.25rem", marginTop: "1rem", alignItems: "flex-start" }}>


                            <LabelLeftIconTwoBoldsTexts style={{ marginTop: "0", width: "unset" }} LeftIcon={SelectClientIcon} LabelText={(type === 'Add') ? t("Common.select", "Select visit") : t("Common.edit", "Edit visit")} LabelBold1={t("Calendar.label.visit_client", "client")} LabelBold2={t("Calendar.label.visit_price", "price")} LabelAfterBold={t("Calendar.visity", "")} />

                            {(type === 'Add') &&
                                <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", width: "unset" }}>
                                    <p className='SmallText text-gray'> {t("Calendar.add_client_with_visit", "Add new client with visit")} </p>
                                    <ToggleButton
                                        style={{ alignItems: "flex-end", height: "unset" }}
                                        onChange={() => setAddClientWithVisit(!addClientWithVisit)}
                                        isChecked={addClientWithVisit}
                                        id={"AddWithVisit"}
                                    />

                                </WrapperFlexRow>
                            }

                            {/* Latest added client */}
                            {
                                (latestAddedClient && latestAddedClient.name && latestAddedClient.lastname) &&
                                <p className={'text_light_bold ' + classes.AddVisitBtn + " " + classes.AddVisitBtnAddClient + ` ${visit.visit_client_id === latestAddedClient.id ? classes.disabled : ""}`}
                                    onClick={handleLatestClientAddedPickUp}
                                >
                                    <LatestIcon className={"SVG_STROKE svg_medium"}
                                    />
                                    {latestAddedClient.name + " " + latestAddedClient.lastname}
                                </p>
                            }

                        </WrapperFlexCol>


                        {/* Add client (Name and Lastname) with new visit  */}
                        <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", flexWrap: "wrap" }}>

                            {(addClientWithVisit && type === 'Add') &&
                                <WrapperFlexRow style={{ padding: "0", gap: "0.25rem" }}>
                                    <SingleInput
                                        style={{ flex: "1" }}
                                        value={client.name}
                                        onChange={(e) => setClient({ ...client, name: e.target.value })}
                                        label="given-name"
                                        placeholder={t("Clients.placeholder.name", "Name")}
                                    />

                                    <SingleInput
                                        style={{ flex: "1" }}
                                        value={client.lastname}
                                        onChange={(e) => setClient({ ...client, lastname: e.target.value })}
                                        label="family-name"
                                        placeholder={t("Clients.placeholder.lastname", "Last name")}
                                    />
                                </WrapperFlexRow>
                            }


                            <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", flexWrap: "wrap" }}>

                                {/* Client  */}
                                {/* We dont want to show client (select box) when we click on option to add new client with visit  */}
                                {
                                    (!addClientWithVisit) &&

                                    <MySelect
                                        className={`SelectBox`}
                                        name="visit_client"
                                        value={visit.visit_client_id}
                                        onChange={handleClientChange}
                                        options={clientsList}
                                        style={{ flex: "1" }}
                                        disabled={addClientWithVisit}
                                    />
                                }


                                {/* Price  */}
                                <CurrencyInput
                                    className={``}
                                    value={(visit.price)}
                                    onChange={(e) => handlePriceChange(e.target.value)}
                                    label={t("Calendar.label_visit_price", "Price")}
                                    style={{ flex: "1" }}
                                />
                            </WrapperFlexRow>

                        </WrapperFlexRow>


                        <WrapperFlexCol style={{ width: "100%", padding: "0", gap: "0.25rem" }}>
                            <LabelLeftSingleIcon LeftIcon={AssignUserIcon} LabelText={t("Common.assign", "Assign")} LabelBold={t("ControlPanel.usera", "user")} />

                            {/* Assign user  */}
                            <MySelect
                                className="SelectBox"
                                name="visit_assigned_worker"
                                value={visit.visit_assigned_worker_id}
                                onChange={(e) => setVisit({ ...visit, visit_assigned_worker_id: e.target.value })}
                                options={guestNamesList}
                                style={{ width: "100%" }}
                            />
                        </WrapperFlexCol>


                        <LabelLeftSingleIcon LeftIcon={NotesIcon} LabelText={(type === 'Add') ? t("Common.enter", "Enter visit's") : t("Common.edit", "Edit visit's")} LabelBold={t("Calendar.label_visit_description", "description")} LabelAfterBold={t("Calendar.visity", "")} />

                        <TextFieldInput
                            label={t("Calendar.label_visit_description_placeholder", "Description")}
                            rows={1}
                            value={visit.description}
                            onChange={(e) => setVisit({ ...visit, description: e.target.value })}
                        />

                        <TwoActionButtonsWrapper
                            loading={isLoading}
                            onCancel={handleClosePopup}
                            onSave={(type === 'Add') ? (e) => visitRequestController(e, "Add") : (e) => visitRequestController(e, "Edit")}
                            isSaveDisabled={(sharedClients.length === 0 && !addClientWithVisit)}
                            cancelLabel={t("Common.cancel", "Cancel")}
                            saveLabel={(type === 'Add') ? t("Common.add", "Add") : t("Common.button.saveChanges", "Save changes")}
                        />
                    </>

                    :
                    <LoadingMessage message={t("loadingMessages.calendar_gathering_and_calculating_data", "Gathering and calculating data..")} />
                }
            </WrapperFlexCol>
        </PopupLayout>

    )
}


// Delete visit popup
export function DeleteVisitPopup({ visitToDelete, setVisitToDelete, setShowDeleteVisitPopup, token, outside_deletionSuccess = "", whichVisit = "" }) {

    const { t } = useTranslation();
    const { setSharedVisits } = useSharedDashboardContext()

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function deleteVisit() {
        let errors = [];

        if (visitToDelete && visitToDelete.id && token) {
            setIsLoading(true)
            const response = await API_DeleteVisit(token, visitToDelete.id)

            if (response && response.isOk) {
                // Client deleted successfuly 
                setIsSuccess(true)

                // Remove the deleted visit from the state
                setSharedVisits(prevVisits => prevVisits.filter(visit => visit.id !== visitToDelete.id));

                if (outside_deletionSuccess !== "") {
                    outside_deletionSuccess(whichVisit)
                }


            }
            else if (response && response.isOk === false && response.error_message) {
                // Error while deleting client
                errors.push(response.error_message)
            }

            setIsLoading(false)
        }

        if (!isSuccess) {
            setErrors(errors);
        }
    }

    function closePopup() {
        // This is needed, so we restart state of visit to delete here
        setVisitToDelete(null)
        setShowDeleteVisitPopup(false)
    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("Calendar.delete_visit", "Delete visit")}>

            <WrapperFlexCol as_form={true} style={{ padding: "0" }} on_submit={deleteVisit}>

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Calendar.delete_visit_success", "Visit deleted successfully!")} />
                }

                {(errors.length === 0 && isSuccess === false) &&
                    <DangerousWarrningContainer message={
                        t("Calendar.delete_visit_text",
                            `Are you sure you want to delete the visit for ${visitToDelete.visit_client.name + " " + visitToDelete.visit_client.lastname} on date ${visitToDelete.visit_date} that is set between ${visitToDelete.visitTimeAsString}`
                            ,
                            {
                                visitToDelete: {
                                    visit_client_detail: visitToDelete.visit_client.name + " " + visitToDelete.visit_client.lastname, // Client Name + Lastname
                                    visit_date: visitToDelete.visit_date, // Visit date
                                    visitTimeAsString: formatTimeRange(visitToDelete.start_hour, visitToDelete.start_minute, visitToDelete.length), // Visit time
                                },
                            }

                        )
                        + "?"} />
                }


                <TwoActionButtonsDangerWrapper
                    onCancel={closePopup}
                    onSave={deleteVisit}
                    loading={isLoading}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                />

            </WrapperFlexCol>

        </PopupLayout>
    )
}