import React from 'react'
import classes from "./NavbarMobile.module.scss"

import { ReactComponent as Calendar } from "../../../static/n_icons/Calendar.svg"
import { ReactComponent as Notes } from "../../../static/n_icons/Notes.svg"
import { ReactComponent as Clients } from "../../../static/n_icons/Users_v2.svg"
import { ReactComponent as Settings } from "../../../static/n_icons/Setting.svg"
import { ReactComponent as Home } from "../../../static/n_icons/Home.svg"
import { useDashboardContext } from '../../../context/DashboardContext'

function NavbarMobile({ currentDashboardMenu, dashboardHash }) {

    const menuItems = [
        { name: 'Home', icon: Home },
        { name: 'Clients', icon: Clients },
        { name: 'Calendar', icon: Calendar },
        { name: 'Notes', icon: Notes },
        { name: 'Settings', icon: Settings }
    ];

    const { moveToPathInsideDashboard } = useDashboardContext()



    return (
        <div className={classes.NavbarMobile}>

            <div className={classes.NavbarMobileWrapper}>

                {menuItems.map((item, idx) => (
                    <NavbarOption
                        key={idx}
                        Icon={item.icon}
                        currentDashboardMenu={item.name}
                        isActive={currentDashboardMenu.toLowerCase() === item.name.toLowerCase()}
                        moveToPathInsideDashboard={moveToPathInsideDashboard}
                        dashboardHash={dashboardHash}
                    />
                ))}

                {/* Settings  */}
                {/* <div className={classes.IconWrapper}>
                    <Settings className={"SVG_STROKE svg_default"} />
                    <p className='DefaultText text-bold'> Settings </p>
                </div> */}



            </div>


        </div>
    )
}

function NavbarOption({ Icon, currentDashboardMenu, isActive, moveToPathInsideDashboard, dashboardHash }) {

    let urlPath = `/dashboard/${dashboardHash}/${currentDashboardMenu.toLowerCase()}`

    return (
        <div className={`${classes.IconWrapper} ${isActive ? classes.active : ''}`} onClick={() => { moveToPathInsideDashboard(urlPath) }}>
            <Icon className={"SVG_STROKE"} />
            <p className='SmallText'> {currentDashboardMenu} </p>
        </div>
    );
}

export default NavbarMobile