// React
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import classes from "./Navbar.module.scss";

// Icons / SVG
import { ReactComponent as MenuOpen } from "../../../static/n_icons/MenuOpen.svg"
import { ReactComponent as MenuClose } from "../../../static/n_icons/MenuClose.svg"
import { ReactComponent as Exit } from "../../../static/icons/Exit.svg"
import { ReactComponent as Logo } from "../../../static/n_icons/Logo.svg"

// Context
import { useUserContext } from '../../../context/UserAuth';
import { useDashboardContext } from '../../../context/DashboardContext';
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext';

// Public files
import paths from "../../../data/paths.json"


// Wrappers
import { WrapperFlexCol } from '../../../components/Elements/Wrappers';
import { PriceListTag } from '../Pages/PriceList';
import { translateDashboardStatus, translateUserPermissions } from '../../../translations/translation_helpers';


// This navbar is made for /dashboard only

function Navbar({ toggleMenu, showMenu }) {


    // Stores path to avatar images in public folder 
    // "/user_avatars/"
    const path_to_avatars = paths.path_to_avatar_images;


    const { t } = useTranslation();


    const { currentUser } = useUserContext();
    const { moveToPathInsideDashboard, dashboardHash, currentDashboardPermissions } = useDashboardContext();
    const { sharedCurrentDashboard } = useSharedDashboardContext()


    const navigate = useNavigate();
    const location = useLocation();



    function handleNavigation(route) {

        // Change current page with "/route" 
        navigate(route);
    };


    function handleNavigationWithinDashboard(newPath) {

        // Move to newPath only if we are not already on that path. We dont need another rerender 
        if (location.pathname !== newPath) {
            moveToPathInsideDashboard(newPath)
        }

    }

    return (
        <div className={classes.Navbar}>


            <div className={classes.NavbarWrapper}>


                <div className={classes.NavbarLeft} >

                    {/* Burger  */}
                    <div className={classes.NavbarLogo} onClick={() => { handleNavigationWithinDashboard(`/dashboard/${dashboardHash}/home`) }}>
                        <Logo />
                    </div>

                    <div className={classes.NavbarCurrentTable}>


                        <div className={classes.NavbarBurger} onClick={toggleMenu}>
                            {(showMenu) ? <MenuClose /> : <MenuOpen />}
                        </div>


                        {(sharedCurrentDashboard && Object.keys(sharedCurrentDashboard).length > 0 && sharedCurrentDashboard.name)
                            ?
                            <WrapperFlexCol style={{ padding: "0", gap: "0", alignItems: "flex-start", justifyContent: "center" }}>
                                <p className='SmallText text_no_wrap text-bold'> {sharedCurrentDashboard.name}</p>
                                <PriceListTag bg={"var(--primary_gradient)"} text={translateDashboardStatus(sharedCurrentDashboard.dashboard_status, t)} />
                            </WrapperFlexCol>
                            :
                            ""}
                    </div>


                </div>

                <div className={classes.NavbarRight}>


                    <div className={classes.NavbarRightContainer}>

                        <div className={classes.NavbarActionContainer + " " + classes.NavbarActionUser} >
                            {(currentUser && currentUser.username) &&
                                <p className='SmallText text-bold text-capitalize' style={{ textAlign: "right" }}> {currentUser.username} <br />
                                    <span className='SmallerText text-gray text-capitalize text_light text_no_wrap' style={{ display: "inline-block" }}>
                                        {/* {(sharedCurrentDashboard.dashboard_owner === currentUser.id) ? t("Common.as_owner", "as owner") : t("Common.as_guest", "as guest")} */}
                                        {t("Common.as", "as")}
                                        {" " + translateUserPermissions(currentDashboardPermissions, t)}
                                    </span>
                                </p>
                            }


                            {/* <User /> */}
                            <img className={"UserImage"} src={path_to_avatars + currentUser.avatar} alt="" />
                        </div>




                        <div onClick={() => { handleNavigation("/dashboards") }} className={classes.NavbarActionContainer}>
                            <Exit />
                        </div>

                    </div>


                </div>

            </div>


        </div>
    )
}

export default Navbar