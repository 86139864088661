// React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Styles
import classes from "../styles/styles_pages/Dashboards.module.scss"

// Public files 
import dashboard_colors from "../data/dashboard_colors.json"

// Layouts
import LayoutHero from './Entrance/LayoutHero/LayoutHero'
import PopupLayout from '../pages/Dashboard/Layout/PopupLayout'

// Apis
import { API_AcceptInvite, API_DeclineInvite, API_DeleteGuestFromDashboard, API_GetDashboardInvitesByUser, API_GetSharedDashboardsByUser } from '../api/API_dashboard_guests_and_invitations'
import { API_CreateNewDashboard, API_GetDashboardByOwner } from '../api/API_dashboards'

// Context
import { useUserContext } from '../context/UserAuth'

// Helpers
import { convertDateTimeToDate } from '../helper/dateFunctions'
import { isDashboardDescriptionValid, isDashboardNameValid } from '../utils/data_validators/dashboard_create_new'
import { translateDashboardStatus } from '../translations/translation_helpers'
import { Dashboard_Limit_Per_User } from '../helper/_dashboards'

// Small element wrappers / Wrappers 
import { DashboardColorPicker, DataBoxWrapper, LoadingAnimationFourDots, TwoActionButtonsDangerWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ButtonNeutralToLightPrimary, ButtonPrimaryFill } from '../components/Elements/Buttons'
import { ButtonSave } from '../components/Elements/FormButtons'
import { DangerousWarrningContainer, ErrorContainer, LoadingMessage, SuccessContainer } from '../components/Elements/FormMessageBoxes'
import { LabelLeftSingleIcon } from '../components/Elements/FormLabels'
import { SingleInput, TextFieldInput } from '../components/Elements/FormInputs'

// Icons / SVG
import { ReactComponent as ColorBucketIcon } from "../static/n_icons/ColorPalette.svg"
import { ReactComponent as UserDetailIcon } from "../static/n_icons/FavDocument.svg"
import { ReactComponent as GoIcon } from "../static/n_icons/Goin.svg"
import { ReactComponent as DeleteIcon } from "../static/n_icons/Delete.svg"
import { ReactComponent as DescriptionIcon } from "../static/n_icons/Settings_Description.svg"







// Starting Component
function Dashboards() {

    const { currentUser } = useUserContext()
    const navigate = useNavigate();

    const token = localStorage.getItem("access_token")

    // Our Dashboards (user)
    const [refreshDashboards, setRefreshDashboards] = useState(false);


    // Shared dashboards 
    const [refreshSharedDashboards, setRefreshSharedDashboards] = useState(false);


    // Popups
    const [showCreateDashboardPopup, setShowCreateDashboardPopup] = useState(false)

    // Popup Deleting Guest dashboard 
    const [showDeleteDashboardGuestPopup, setShowDeleteDashboardGuestPopup] = useState(false)
    const [dashboardToDelete, setDashboardToDelete] = useState({})


    function handleMoveToDashboard(dashboard_hash_id) {
        navigate("/dashboard/" + dashboard_hash_id + "/home")
    }

    function handleCloseCreateDashboardPopup() {
        setShowCreateDashboardPopup(false)
    }


    function handleRefreshDashboards() {
        setRefreshDashboards(!refreshDashboards)
    }

    function handleRefreshDashboardShared() {
        setRefreshSharedDashboards(!refreshSharedDashboards)
    }


    // When Guest wants to delete him self from dashboard
    function handleGuestToDelete(dashboard_shared) {
        setDashboardToDelete(dashboard_shared)
        setShowDeleteDashboardGuestPopup(true)
    }

    return (
        <LayoutHero>

            <div className={classes.Dashboards}>

                {(showCreateDashboardPopup) && <CreateDashboardPopup handleRefreshDashboards={handleRefreshDashboards} currentUser={currentUser} token={token} closePopup={handleCloseCreateDashboardPopup} />}


                {/* Popup of deleting dashboard guest  */}
                {(showDeleteDashboardGuestPopup) && <DeleteGuestPopup handleRefreshDashboardShared={handleRefreshDashboardShared} setShowDeleteDashboardGuestPopup={setShowDeleteDashboardGuestPopup} dashboardToDelete={dashboardToDelete} currentUser={currentUser} token={token} />}

                <div className="container is-max-desktop">

                    <div className={classes.DashboardsWrapper}>

                        {/* Here are ready to use invitation boxes, do this later  */}
                        <RenderInvitations currentUser={currentUser} token={token} handleRefreshDashboardShared={handleRefreshDashboardShared} />

                        {/* Your dashboards  */}
                        <RenderOurDashboards setShowCreateDashboardPopup={setShowCreateDashboardPopup} handleMoveToDashboard={handleMoveToDashboard} token={token} currentUser={currentUser} refreshDashboards={refreshDashboards} />


                        {/* Shared dashboards  */}
                        <RenderSharedDashboards handleGuestToDelete={handleGuestToDelete} handleMoveToDashboard={handleMoveToDashboard} token={token} currentUser={currentUser} refreshSharedDashboards={refreshSharedDashboards} />



                    </div>

                </div>
            </div>

        </LayoutHero>
    )
}

// Renders All Our Dashboards
function RenderOurDashboards({ setShowCreateDashboardPopup, handleMoveToDashboard, token, currentUser, refreshDashboards }) {

    const [dashboards, setDashboards] = useState([])
    const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);

    const { t } = useTranslation();

    function handleCreateNewDashboard() {
        if (currentUser && currentUser.id && token) {
            setShowCreateDashboardPopup(true)
        }
        else {
            window.location.href = '/login'
        }


    }

    useEffect(() => {
        loadDashboardsByOwner()


    }, [refreshDashboards])

    // TO DO: Implement some better error messages here
    async function loadDashboardsByOwner() {
        if (token && currentUser.username && currentUser.id) {
            const response = await API_GetDashboardByOwner(token);

            if (response && response.isOk && response.items) {
                setDashboards(response.items)
                setIsDashboardLoaded(true)
            }
            else {
                console.log("There was an error while loading dashboards!")
                setIsDashboardLoaded(false);
                setDashboards([])
            }
        }
        // When user is not logged in and server is off
        else {
            setIsDashboardLoaded(true)
        }
    }

    return (
        <WrapperFlexCol style={{ padding: "0" }}>

            <WrapperFlexRow style={{ flexWrap: "wrap-reverse", padding: "0", justifyContent: "space-between" }}>


                <WrapperFlexCol style={{ padding: "0", gap: "0", width: "max-content" }}>
                    <h2 className="BigText text-gray text-bold"> {t("Dashboards.Your_dashboards", "Your dashboards")} </h2>
                    <p className='SmallText text_gray_on_layout'> {dashboards.length} {'/'} {Dashboard_Limit_Per_User} </p>
                </WrapperFlexCol>


                <ButtonPrimaryFill text={t("Dashboards.button.createNew", " + Create new ")} onClick={handleCreateNewDashboard} />
            </WrapperFlexRow>

            {/* <hr className={'myHr'} /> */}

            <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", gap: "1rem", marginTop: "0.5rem" }}>

                {(dashboards && dashboards.length > 0 && isDashboardLoaded) ?

                    dashboards.map((dashboard, idx) => {
                        return (
                            <SingleDashboard isSingleDashboard={dashboards.length === 1} key={`dashboard-${idx}`} dashboard={dashboard} handleMoveToDashboard={handleMoveToDashboard} />
                        )
                    })
                    :
                    // If we didint loaded dashboards yet
                    (!isDashboardLoaded) ?
                        <DataBoxWrapper className={`DataBox_style HideScrollbar ${classes.DataBoxWrapper}`} style={{ alignItems: "center", justifyContent: "center", minHeight: "200px", minWidht: "unset" }}>
                            {/* <DBErrorIcon className={classes.SadIcon} /> */}
                            <p className='SmallText text-gray'> {t("Dashboards.loading.your_dashboards", "Hold tight! We are loading your dashboards..")} </p>
                            <LoadingAnimationFourDots />
                        </DataBoxWrapper>
                        :

                        // If we are here, we loaded dashboards but we have none!
                        <DataBoxWrapper className={`DataBox_style HideScrollbar ${classes.DataBoxWrapper}`} style={{ alignItems: "center", justifyContent: "center", minHeight: "200px", minWidht: "unset" }}>
                            {/* <DBErrorIcon className={classes.SadIcon} /> */}
                            <p className='SmallText text-gray'> {t("Dashboards.zero.your_dashboards", "You don't have any dashboard that you own.")}  </p>
                            <p className='SmallText text-gray'> {t("Dashboards.zero.add_one", "Maybe you should add one?")}  </p>
                        </DataBoxWrapper>
                }
            </WrapperFlexRow>


        </WrapperFlexCol>
    )
}

// Renders All shared dashboards
function RenderSharedDashboards({ handleMoveToDashboard, token, currentUser, refreshSharedDashboards, handleGuestToDelete }) {

    const [sharedDashboards, setSharedDashboards] = useState([])
    const [isSharedDashbordsLoaded, setIsSharedDashboardsLoaded] = useState(false)

    const { t } = useTranslation();

    useEffect(() => {
        // loadDashboardInvitations()
        loadDashboardShared()
    }, [refreshSharedDashboards])

    async function loadDashboardShared() {
        if (token && currentUser.username && currentUser.id) {
            const response = await API_GetSharedDashboardsByUser(token);

            if (response && response.isOk && response.items) {
                setSharedDashboards(response.items)
                setIsSharedDashboardsLoaded(true)
            }
            else {
                console.log("There was an error while loading dashboards!")
                setIsSharedDashboardsLoaded(false);
                setSharedDashboards([])
            }
        }
        // When user is not logged in and server is off
        else {
            setIsSharedDashboardsLoaded(true)
        }
    }


    return (
        <WrapperFlexCol style={{ padding: "0" }}>

            <WrapperFlexRow style={{ flexWrap: "wrap-reverse", padding: "0" }}>
                <h2 className="BigText text-gray text-bold"> {t("Dashboards.Shared_dashboards", "Shared dashboards")} </h2>
            </WrapperFlexRow>

            {/* <hr className={'myHr'} /> */}

            <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", gap: "1rem", marginTop: "0.5rem" }}>
                {(sharedDashboards && sharedDashboards.length > 0 && isSharedDashbordsLoaded) ?

                    sharedDashboards.map((sharedDashboard, idx) => {
                        return (
                            <SingleDashboard isSingleDashboard={sharedDashboards.length === 1} key={`dashboard-${idx}`} dashboard={sharedDashboard.dashboard} handleMoveToDashboard={handleMoveToDashboard} handleGuestToDelete={handleGuestToDelete} />
                        )
                    })
                    :
                    // If we didint loaded dashboards yet
                    (!isSharedDashbordsLoaded) ?
                        <DataBoxWrapper className={`DataBox_style HideScrollbar ${classes.DataBoxWrapper}`} style={{ alignItems: "center", justifyContent: "center", minHeight: "200px", minWidht: "unset" }}>
                            <p className='SmallText text-gray'> {t("Dashboards.loading.your_dashboards", "Hold tight! We are loading your dashboards..")} </p>
                            <LoadingAnimationFourDots />
                        </DataBoxWrapper>
                        :

                        // If we are here, we loaded dashboards but we have none!
                        <DataBoxWrapper className={`DataBox_style HideScrollbar ${classes.DataBoxWrapper}`} style={{ alignItems: "center", justifyContent: "center", minHeight: "200px", minWidht: "unset" }}>
                            <p className='SmallText text-gray'>
                                {t("Dashboards.zero_sharedDashboards_first", "You do not have any shared dashboard, that's totally okay!")}
                            </p>
                            <p className='SmallText text-gray'>
                                {t("Dashboards.zero_sharedDashboards_second", "When someone add's you to his dashboard, you will see it here. ")}
                            </p>
                        </DataBoxWrapper>
                }
            </WrapperFlexRow>


        </WrapperFlexCol>
    )
}

// Single box with dashboard info (For OurDashboards and SharedDashboards)
function SingleDashboard({ dashboard, handleMoveToDashboard, handleGuestToDelete, isSingleDashboard = false }) {

    // HandleGuestToDelete is function passed from parent, so we can delete ourselfs from dashboard if we are not the owner 
    const { t } = useTranslation();

    let dashboard_status_translated = translateDashboardStatus(dashboard.dashboard_status, t)


    return (
        <DataBoxWrapper className={`DataBox_style HideScrollbar ${isSingleDashboard && 'SingleDashboard'}`} style={{ minHeight: "unset", flex: "1", minWidht: "unset" }}>

            {/* Dashboard name and status  */}
            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>
                <h2 className="BigText text-bold"> {dashboard.name} </h2>

                <p className={'BoxUserTitle'}> {dashboard_status_translated} </p>

            </WrapperFlexRow >

            {/* Dashboard description */}
            <WrapperFlexCol className={"HideScrollbar"} style={{ padding: "0", marginTop: "1rem", gap: "0", maxHeight: "4rem" }}>
                <p className='SmallText text-gray'> {t("Dashboards.card.description", "Description")} </p>
                <h2 style={{ maxHeight: "2.5rem", overflowY: "scroll" }} className="HideScrollbar DefaultText text-bold"> {dashboard.description} </h2>

            </WrapperFlexCol>

            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between", alignItems: "flex-end" }}>
                <p className={"SmallText text-gray text_no_wrap"}>
                    {/* {t("Dashboards.card.since", "Since:")}  */}
                    {convertDateTimeToDate(dashboard.dashboard_creation_date)}
                </p>
                <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", gap: "0.5rem" }}>

                    {/* Show Delete icon if we are at shared dashboard  */}
                    {handleGuestToDelete && <ButtonNeutralToDanger text={<DeleteIcon />} onClick={() => handleGuestToDelete(dashboard)} />}

                    <ButtonNeutralToLightPrimary noMargin={true} style={{ minWidth: "60px" }} onClick={() => handleMoveToDashboard(dashboard.hash_id)} text={<GoIcon />} />
                </WrapperFlexRow>
            </WrapperFlexRow>

        </DataBoxWrapper>
    )
}






// Renders All invitations to dashboards
function RenderInvitations({ currentUser, token, handleRefreshDashboardShared }) {

    const [dashboardInvites, setDashboardInvites] = useState([])
    const [isDashboardInvitesLoaded, setIsDashboardInvitesLoaded] = useState(false)

    const [refreshInvitations, setRefreshInvitations] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        loadDashboardInvitations()
    }, [refreshInvitations])

    async function loadDashboardInvitations() {
        if (token && currentUser.username && currentUser.id) {
            const response = await API_GetDashboardInvitesByUser(token);

            if (response && response.isOk && response.items) {
                setDashboardInvites(response.items)
                setIsDashboardInvitesLoaded(true)
            }
            else {
                console.log("There was an error while loading dashboards!")
                setIsDashboardInvitesLoaded(false);
                setDashboardInvites([])
            }
        }
        // When user is not logged in and server is off
        else {
            setIsDashboardInvitesLoaded(true)
        }
    }

    function handleRefreshInvitations() {
        setRefreshInvitations(!refreshInvitations)
    }

    return (
        <>
            {(dashboardInvites.length > 0 && isDashboardInvitesLoaded) &&
                <>
                    <p className='SmallText text-bold' style={{ marginBottom: "-3rem" }}> {t("Dashboards.pending_invitations.text", "You have some pending invitations to dashboards!")} </p>
                    <WrapperFlexCol style={{ maxHeight: "300px", padding: "0", overflowY: "auto", backgroundColor: "transparent", borderRadius: "16px" }}>

                        {(dashboardInvites.map((invite, idx) => {
                            return (
                                <>
                                    <Invitation handleRefreshInvitations={handleRefreshInvitations} handleRefreshDashboardShared={handleRefreshDashboardShared} token={token} invite={invite} key={`${invite.id}-${idx}`} />
                                </>
                            )
                        }
                        ))}


                    </WrapperFlexCol>
                </>
            }
        </>
    )
}

// Single Invitation With 'Accept' or 'Decline' 
function Invitation({ invite, token, handleRefreshDashboardShared, handleRefreshInvitations }) {

    const [isAcceptSuccess, setIsAcceptSuccess] = useState(false)
    const [isDeclineSuccess, setIsDeclineSuccess] = useState(false)
    const [errors, setErrors] = useState([])

    const { t } = useTranslation();

    async function acceptInvitation() {
        setIsAcceptSuccess(false)
        let errors = []

        const invite_data = {
            "invitation_owner_id": invite.invitation_owner.id,
            "dashboard_id": invite.dashboard.id,
            "invitation_receiver_id": invite.invitation_receiver.id
        }

        const response = await API_AcceptInvite(token, invite_data)

        if (response && response.isOk) {
            // Accepted correctly 
            handleRefreshDashboardShared()
            setIsAcceptSuccess(true)
            handleRefreshInvitations()
        }
        else if (response && response.error_message) {
            console.log(response.error_message)
            errors.push(response.error_message)
        }
        else {
            console.log("OTHER ERROR")
        }

        if (errors.length > 0) {
            setErrors(errors)
        }
    }

    async function declineInvitation() {
        setIsAcceptSuccess(false)
        let errors = []

        const invite_data = {
            "invitation_owner_id": invite.invitation_owner.id,
            "dashboard_id": invite.dashboard.id,
            "invitation_receiver_id": invite.invitation_receiver.id
        }

        const response = await API_DeclineInvite(token, invite_data)

        if (response && response.isOk) {
            // Accepted correctly 
            handleRefreshDashboardShared()
            setIsDeclineSuccess(true)
            handleRefreshInvitations()
        }
        else if (response && response.error_message) {
            console.log(response.error_message)
            errors.push(response.error_message)
            // Most likely this invite doesnt exists 
        }
        else {
            console.log("OTHER ERROR")
        }

        if (errors.length > 0) {
            setErrors(errors)
        }
    }

    return (

        <WrapperFlexCol className={"FlexWrapOnTablet BoxBg borderRadius"} style={{ boxShadow: "unset" }}>
            {
                ((!isAcceptSuccess || !isDeclineSuccess) && errors.length > 0) &&
                <ErrorContainer errors={errors} />
            }

            <WrapperFlexRow style={{ padding: "0" }}>

                <p style={{ overflow: "hidden" }} className='DefaultText'>
                    {t("Common.user", "User")} <b> {invite.invitation_owner.username} </b> {t("Dashboards.invitation.text", "invited you to his dashboard")} <b> {invite.dashboard.name}.</b>
                </p>

                <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", flex: "1" }}>
                    <ButtonNeutralToDanger text={t("Common.reject", "Reject")} onClick={declineInvitation} />
                    <ButtonPrimaryFill text={t("Common.accept", "Accept")} onClick={acceptInvitation} />
                </WrapperFlexRow>
            </WrapperFlexRow>
        </WrapperFlexCol>

    )
}







// Delete Guest Popup, fires when delete button is clicked at dashboardShared box 
export function DeleteGuestPopup({ handleRefreshDashboardShared, token, currentUser, dashboardToDelete, setShowDeleteDashboardGuestPopup }) {

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    async function deleteGuest() {

        setIsLoading(true)
        setIsSuccess(false)
        setErrors([])
        let errors = []

        if (currentUser && currentUser.id && dashboardToDelete && dashboardToDelete.id && token) {

            const response = await API_DeleteGuestFromDashboard(token, dashboardToDelete.id, currentUser.id)

            if (response && response.isOk) {
                // Success Dashboard deleted
                setIsSuccess(true)
                handleRefreshDashboardShared()
            }
            else if (response && response.error_message) {
                // Error while adding an invite 
                errors.push(response.error_message)
            }
            else {
                errors.push(t("validationErrors.dashboard_guest_not_found", "Something went wrong. Try again later!"))
            }
        }
        else {
            // Dashboard guest not found, we might be logged out also 
            errors.push(t("validationErrors.dashboard_guest_not_found", "Something went wrong. Try again later!"))
        }

        if (errors.length > 0) {
            setErrors(errors)
        }

        setIsLoading(false)


    }

    return (
        <PopupLayout popupTitle={t("Dashboards.delete_shared_dashboard.title", "Delete dashboard")} closePopup={() => setShowDeleteDashboardGuestPopup(false)} isHeroLayout={true}>
            <WrapperFlexCol style={{ padding: "0" }}>

                {(isLoading) &&
                    <LoadingMessage message={t("Dashboards.delete_shared_dashboard.loading_text", "Deleting dashboard..")} />
                }

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Dashboards.delete_shared_dashboard.success", "Dashboard deleted!")} />
                }

                {(!isSuccess) &&
                    <DangerousWarrningContainer message={t("Dashboards.delete_shared_dashboard_message", { dashboardName: dashboardToDelete.name }, "This action will remove you from the dashboard: '{{dashboardName}}'. Any clients or visits you added will be reassigned to the dashboard owner.")} />
                }

                <TwoActionButtonsDangerWrapper
                    onCancel={() => setShowDeleteDashboardGuestPopup(false)}
                    onSave={deleteGuest}
                    cancelLabel={t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete_shared_dashboard", "Delete dashboard")}
                    isSuccess={isSuccess}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )
}

// Create New dashboard Popup
function CreateDashboardPopup({ handleRefreshDashboards, closePopup, token, currentUser }) {

    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);

    const { t } = useTranslation();

    const [newDashboard, setNewDashboard] = useState({
        name: "",
        color: dashboard_colors[0].color,
        description: ""
    })

    const colors = dashboard_colors;

    async function handleCreateNewDashboard(e) {

        e.preventDefault();

        let errors = [];
        setIsSuccess(false);

        // No point of color validation or owner_id, because it is hard to change in front-end
        // This will be cought in backend

        if (!newDashboard.name || !newDashboard.description) {
            errors.push(t("validationErrors.enter_all_required_fields", "Enter values to all required fields!"))
        }

        const nameValidation = isDashboardNameValid(newDashboard.name, t)
        const descriptionValidation = isDashboardDescriptionValid(newDashboard.description, t)

        if (nameValidation.length > 0) errors = errors.concat(nameValidation)
        if (descriptionValidation.length > 0) errors = errors.concat(descriptionValidation)

        if (token && currentUser.id && currentUser.username && errors.length === 0) {

            const response = await API_CreateNewDashboard(token, newDashboard)

            if (response && response.isOk) {
                // Clearing state and all 
                setErrors([])
                setIsSuccess(true);


                handleRefreshDashboards()

                setNewDashboard({
                    name: "",
                    color: dashboard_colors[0].color,
                    description: ""
                })

                setTimeout(() => {
                    closePopup()
                }, 1500)
            }
            // If We reached the limit of dashboards
            else if (response.isOk === false && response.response_json.error.includes("dc_01")) {
                errors.push(t("Dashboards.Dashboard_limits", `You cannot add another dashboard because the limit of dashboards for your account has been reached.`) + ` (${Dashboard_Limit_Per_User})`)
            }
            else if (response.isOk === false && response.validation_errors) {
                // TODO: Add error translations from backend!
                errors.push(...response.validation_errors)
            }
        }
        else if (!token || Object.keys(currentUser) === 0) {
            errors.push(t("validationErrors.login_before_creating_dashboard", "You need to login before creating new dashboard!"))
        }


        setErrors(errors)

    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("Dashboards.title.create_new_dashboard", "Create new dashboard")} isHeroLayout={true}>
            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleCreateNewDashboard}>

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {/* Success message  */}
                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Dashboards.container.success", "New dashboard created!")} />
                }

                <LabelLeftSingleIcon style={{ marginTop: "0" }} LeftIcon={ColorBucketIcon} LabelText={t("Dashboards.label.pick_color.text", "Pick dashboard")} LabelBold={t("Dashboards.label.pick_color.bold", "color")} />
                <DashboardColorPicker
                    colors={colors}
                    selectedColor={newDashboard.color}
                    onColorChange={(color) => setNewDashboard({ ...newDashboard, color })}
                />

                {/* Dashboard name  */}
                <LabelLeftSingleIcon LeftIcon={UserDetailIcon} LabelText={t("Dashboards.label.enter_dashboard_name.text", "Add dashboard")} LabelBold={t("Dashboards.label.enter_dashboard_name.bold", "Add dashboard")} />
                <SingleInput
                    style={{ width: "100%" }}
                    value={newDashboard.name}
                    onChange={(e) => setNewDashboard({ ...newDashboard, name: e.target.value })}
                    label="Dashboard name"
                    placeholder={t("Dashboards.label.enter_dashboard_name.placeholder", "Dashboard name")}
                />

                {/* Dashboard Description  */}
                <LabelLeftSingleIcon LeftIcon={DescriptionIcon} LabelText={t("Dashboards.label.enter_dashboard_description.text", "Add dashboard")} LabelBold={t("Dashboards.label.enter_dashboard_description.bold", "description")} />
                <TextFieldInput
                    label={t("Dashboards.label.enter_dashboard_description.placeholder", "Dashboard description")}
                    multiline
                    rows={4}
                    value={newDashboard.description}
                    onChange={(e) => setNewDashboard({ ...newDashboard, description: e.target.value })}
                />


                {/* Actions like save and cancel  */}
                <TwoActionButtonsWrapper
                    onCancel={closePopup}
                    onSave={handleCreateNewDashboard}
                    cancelLabel={t("Common.cancel", "Cancel")}
                    saveLabel={t("Common.create_dashboard", "Create table")}
                    isSaveDisabled={!newDashboard.name || !newDashboard.description || (isSuccess && errors.length === 0)}
                />


            </WrapperFlexCol>
        </PopupLayout>
    )
}




export default Dashboards